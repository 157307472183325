import * as React from 'react'

import { ISelectRowsFunction, TNewlySelected } from '../../../../Interfaces'
import Row from './Row'

interface IItemRowProps<T> {
    item: T
    itemIdField: keyof T
    selectAction: ISelectRowsFunction<T>
    selectedNewItems: TNewlySelected<T>
    virtualizedListRowStyle: React.CSSProperties
    renderHr: boolean
    rowItemComponentInstance: JSX.Element
    multiselect: boolean
}

export default class ItemRow<T> extends React.Component<IItemRowProps<T>> {
    static readonly defaultProps = {
        renderHr: false,
    }

    handleClick = (e: React.MouseEvent): void => {
        const { item, selectAction } = this.props
        selectAction(item, e)
    }

    render(): React.ReactNode {
        const {
            item,
            virtualizedListRowStyle,
            selectedNewItems,
            itemIdField,
            renderHr,
            rowItemComponentInstance,
            multiselect,
        } = this.props

        const rowIsSelected = selectedNewItems.has(item[itemIdField])
        const itemId = (item as any)?.DisplayId as number

        return (
            <Row
                checkboxSelectable={multiselect}
                renderHr={renderHr}
                rowItemComponentInstance={rowItemComponentInstance}
                rowSelected={rowIsSelected}
                selectAction={this.handleClick}
                virtualizedListRowStyle={virtualizedListRowStyle}
                testId={`VALUE_PICKER_SELECTION_ROW_${itemId}`}
            />
        )
    }
}
