import { memo, useEffect } from 'react'
import styled from '@emotion/styled'
import { IDataSourceAction } from '../../../../data-source'
import { IListViewIconAction } from '../../../interfaces/TDisplayAction'
import { IPermission } from 'interfaces/IActionPermission'
import { TListIconData } from '../../../interfaces/IList'
import EListViewActionFunctionalityType from '../../../Types/EListViewActionFunctionalityType'
import IListViewAction from '../../../interfaces/IListViewAction'
import RowAction from './RowAction'
import TFunctionalityAction from '../../../interfaces/TFunctionalityAction'
import IListActionConfirmationDialogModalProps from '../../../interfaces/IListActionConfirmationDialogModalProps'
import { ROW_HEIGHT } from '../../../Constants/RowConstants'
import { compact, difference, isEmpty } from 'lodash'
import { MenuWithItems } from '../../../../generic-components'
import { translate } from '../../../../localization'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { selectMaximumRowActions } from '../../../'
import { setMaximumRowActionsAction } from '../../../State/ConfigurableListActions'
import { times } from 'lodash-es'

type Props = {
    actionConfigurations: IDataSourceAction[]
    listId: string
    iconData?: TListIconData
    iconsDisabled: boolean
    itemId: string | number
    onRowActionClick: (
        actionType: EListViewActionFunctionalityType,
        callbackProps: IDataSourceAction | string | IListActionConfirmationDialogModalProps
    ) => void
    permissions?: IPermission[]
    rowActions: IListViewAction<IListViewIconAction, TFunctionalityAction>[]
    firstRow: boolean
    className?: string
    renderPlaceholder?: boolean
}

const ACTION_BUTTON_WIDTH = 35

const getCallbackProps = (Functionality: TFunctionalityAction, actionConfigurations: IDataSourceAction[]): any => {
    const actionConfiguration = actionConfigurations.find((conf) => conf.Id === Functionality.DataSourceActionId)

    switch (Functionality.FunctionalityType) {
        case EListViewActionFunctionalityType.ListModal:
        case EListViewActionFunctionalityType.ConfigurableModal: {
            return Functionality
        }

        case EListViewActionFunctionalityType.Download:
        case EListViewActionFunctionalityType.Immediate: {
            if (!actionConfiguration) {
                return null
            }

            return actionConfiguration
        }

        case EListViewActionFunctionalityType.CustomModal: {
            if (!actionConfiguration) {
                return null
            }

            const { FormId } = Functionality

            return { formId: FormId, dataSourceId: actionConfiguration.DataSourceId }
        }

        case EListViewActionFunctionalityType.Confirmation: {
            if (!actionConfiguration) {
                return null
            }

            return {
                actionConfiguration,
                confirmationDialogTexts: { ...Functionality },
            }
        }

        default:
            return Functionality
    }
}

const IconContainer = styled.div<{
    firstRow: boolean
    minWidth: number
    placeholderContainer?: boolean
    noActions?: boolean
}>`
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0;
    position: sticky;
    right: 0;
    padding-left: 5px;
    padding-right: 5px;
    background: ${({ theme }) => theme.colors.neutralsWhite100};
    height: ${ROW_HEIGHT - 2}px;
    align-items: center;
    gap: ${({ placeholderContainer }) => (placeholderContainer ? '13px' : null)};
    box-shadow: ${({ theme, noActions }) => !noActions && `-1px 0 0 0 ${theme.colors.neutralsGrey30}`};
    min-width: ${({ minWidth }) => (minWidth >= 42 ? minWidth : 42)}px;
`

const PlaceholderIcon = styled.div`
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.neutralsGrey30};
`

const OverflowMenu = ({
    overflowActions,
    disabled,
    onRowActionClick,
    permissions,
    actionConfigurations,
}: Partial<Props> & {
    overflowActions: IListViewAction<IListViewIconAction, TFunctionalityAction>[]
    disabled: boolean
}): JSX.Element => {
    const menuItems = compact(
        overflowActions.map(({ Display, Functionality }) => {
            const permission: IPermission | undefined = permissions?.find(
                (currentPermission) => currentPermission.Toiminto === Functionality.PermissionId
            )

            const callbackProps = getCallbackProps(Functionality, actionConfigurations || [])

            if (!permission || !callbackProps) {
                return
            }

            const handleClick = () => {
                onRowActionClick?.(Functionality.FunctionalityType, callbackProps)
            }

            const disabledReason = permission.SyyKieltoon ?? null
            const tooltip = disabledReason ? `${translate('actionDisabled')}: ${disabledReason}` : null

            return {
                tooltip,
                label: Display.Tooltip,
                icon: Display.Icon,
                isDisabled: !permission.OnkoSallittu || disabled,
                onClick: handleClick,
            }
        })
    )

    return (
        <MenuWithItems menuItemsData={menuItems}>
            {(onMenuOpen) => (
                <RowAction
                    icon="more_horiz"
                    onClick={(actionType, callbackProps, event) => {
                        onMenuOpen(event)
                    }}
                    tooltip={translate('configurableList.moreActions')}
                    tooltipLeaveDelay={10}
                    disabled={disabled}
                    callbackProps={null}
                    actionType={EListViewActionFunctionalityType.ShowOverflow}
                    itemId={EListViewActionFunctionalityType.ShowOverflow}
                />
            )}
        </MenuWithItems>
    )
}

/**
 * Listan rivien lopussa näytettävät ikonit
 */
const RowActions = ({
    actionConfigurations,
    permissions,
    iconData = {},
    iconsDisabled,
    itemId,
    onRowActionClick,
    rowActions,
    className,
    listId,
    firstRow,
    renderPlaceholder,
}: Props) => {
    const dispatch = useDispatch()
    const maximumRowActions = useSelector((state: RootState) => selectMaximumRowActions(state, listId))

    // @@TODO These actions will be made configurable, after which
    // iconData will not be necessary.
    const oldActions =
        Object.keys(iconData).length > 0
            ? (permissions || []).map((permissionObject) => {
                  const { SyyKieltoon, OnkoSallittu } = permissionObject
                  const dataForIcon = iconData[permissionObject.Toiminto]
                  if (!dataForIcon) {
                      return null
                  }

                  const { icon, actionFunc, tooltip } = dataForIcon
                  const enabled = !iconsDisabled && OnkoSallittu
                  const displayedTooltip = enabled ? tooltip : SyyKieltoon
                  const handleClick = () => actionFunc(itemId)

                  return (
                      <RowAction
                          disabled={!enabled}
                          icon={icon}
                          itemId={itemId}
                          key={icon}
                          onClick={handleClick}
                          tooltip={displayedTooltip || ''}
                          actionType={EListViewActionFunctionalityType.Confirmation}
                          callbackProps={null}
                      />
                  )
              })
            : null

    const shownActions = rowActions.filter((action) => action.Display.IsPrimaryAction)
    const overflowActions = difference(rowActions, shownActions)

    const isCondenced = !isEmpty(overflowActions)

    const configurableActions = permissions
        ? shownActions.map(({ Display, Functionality, Id, Order }) => {
              const permission = permissions.find(
                  (currentPermission) => currentPermission.Toiminto === Functionality.PermissionId
              )

              const callbackProps = getCallbackProps(Functionality, actionConfigurations)

              if (!permission || !callbackProps) {
                  return null
              }

              return (
                  <RowAction
                      key={`${Id}-${itemId}-${Order}`}
                      actionType={Functionality.FunctionalityType}
                      callbackProps={callbackProps}
                      disabled={iconsDisabled}
                      icon={Display.Icon}
                      itemId={itemId}
                      onClick={onRowActionClick}
                      permission={permission}
                      tooltip={Display.Tooltip}
                  />
              )
          })
        : null

    // keep track of the row with the highest action count for equalizing container widths
    const actionsCount = compact(configurableActions).length + (isCondenced ? 1 : 0)

    useEffect(() => {
        if (actionsCount > maximumRowActions) {
            dispatch(setMaximumRowActionsAction(actionsCount, listId))
        }
    }, [actionsCount, dispatch, listId, maximumRowActions])

    const minWidth = maximumRowActions * ACTION_BUTTON_WIDTH

    if (renderPlaceholder) {
        return (
            <IconContainer className={className} firstRow={firstRow} minWidth={minWidth} placeholderContainer>
                {times(actionsCount, (index) => (
                    <PlaceholderIcon key={`placeholder-${index}`} />
                ))}
            </IconContainer>
        )
    }

    return (
        <IconContainer
            className={className}
            firstRow={firstRow}
            minWidth={minWidth}
            noActions={isEmpty(configurableActions)}
            onClick={(e) => e.stopPropagation()}
        >
            {configurableActions}
            {oldActions}
            {isCondenced && (
                <OverflowMenu
                    overflowActions={overflowActions}
                    disabled={iconsDisabled}
                    onRowActionClick={onRowActionClick}
                    actionConfigurations={actionConfigurations}
                    permissions={permissions}
                />
            )}
        </IconContainer>
    )
}

export default memo(RowActions)
