import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { selectEventTypeCategories } from '../State/EmployeeCalendarSelectors'
import EventTypeCategoryLegend from './EventTypeCategoryLegend'
import { JSX, useEffect } from 'react'
import { getEventTypeCategories } from '../State/EmployeeCalendarThunks'
import { isEmpty } from 'lodash-es'
import { EEventTypeCategory, IIdentifier } from '../../event/Types/IEvent'
import { translate } from '../../localization'

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    grid-auto-flow: column;
    margin-left: 32px;
    margin-bottom: 10px;
    margin-right: 16px;
`

// Combines NonReservingEventGroup and ReservingEventGroup to a single EventGroup for displaying in the legend
const combineEventGroups = (categories: IIdentifier[]) => {
    return categories
        .filter(({ Id }) => Id !== EEventTypeCategory.NonReservingEventGroup)
        .map((category) => {
            if (category.Id !== EEventTypeCategory.ReservingEventGroup) {
                return category
            }

            return {
                Id: category.Id,
                Name: translate('event_group'),
            }
        })
}

const EventTypeCategories = (): JSX.Element => {
    const eventTypeCategories = useSelector(selectEventTypeCategories)
    const dispatch = useDispatch()

    useEffect(() => {
        if (isEmpty(eventTypeCategories)) {
            dispatch(getEventTypeCategories())
        }
    }, [dispatch, eventTypeCategories])

    return (
        <Container>
            {combineEventGroups(eventTypeCategories).map((category) => (
                <EventTypeCategoryLegend key={category.Id} eventTypeCategory={category} />
            ))}
        </Container>
    )
}

export default EventTypeCategories
