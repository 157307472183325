import * as React from 'react'

import { ISelectRowsFunction, TSelectedValues } from '../../../../Interfaces'
import Row from './Row'

interface IValueRowProps<T> {
    item: T
    itemIdField: keyof T
    selectAction: ISelectRowsFunction<T>
    virtualizedListRowStyle: React.CSSProperties
    renderHr: boolean
    selectedValueItems: TSelectedValues<T>
    rowItemComponentInstance: JSX.Element
}

export default class ValueRow<T> extends React.Component<IValueRowProps<T>> {
    static readonly defaultProps = {
        renderHr: false,
    }

    handleClick = (): void => {
        const { item, selectAction } = this.props
        selectAction(item)
    }

    render(): React.ReactNode {
        const { item, virtualizedListRowStyle, renderHr, selectedValueItems, itemIdField, rowItemComponentInstance } =
            this.props

        const rowIsSelected = selectedValueItems.has(item[itemIdField])

        const _item = item as any
        const itemId = _item?.DisplayId as number

        return (
            <Row
                checkboxSelectable
                renderHr={renderHr}
                rowItemComponentInstance={rowItemComponentInstance}
                rowSelected={rowIsSelected}
                selectAction={this.handleClick}
                virtualizedListRowStyle={virtualizedListRowStyle}
                testId={`VALUE_PICKER_SELECTION_ROW_${itemId}`}
            />
        )
    }
}
