import { connect } from 'react-redux'
import * as React from 'react'
import { useEffect } from 'react'
import styled from '@emotion/styled'
import { RouteComponentProps } from 'react-router-dom'
import { RootState } from 'typesafe-actions'

import { IPageData } from '../../../page'
import ApplicationPage from './ApplicationPage'
import { applicationInitialised as applicationInitialisedThunk } from 'action-creators/InitialisingActions'
import { FormViewModalsManager } from '../../../form-view'
import ILoginKayttaja from 'interfaces/ILoginKayttaja'
import LoadingIndicatorGlobal from 'components/molecules/LoadingIndicatorGlobal'
import NotificationHandler from 'components/molecules/NotificationHandler'
import { ListModalsManager } from '../../../configurable-list-modal'
import AsetuksetContext from 'context/AsetuksetContext'
import TyylitContext from 'context/TyylitContext'
import LoginUserContext from 'context/LoginUserContext'
import { selectAreNavigationItemsSet } from '../../../navigation'
import { selectIsAsyncOperationIsReady } from '../../../async-operation'
import useJwt from './useJwt'
import { useValuesFromParams } from '../../../generic-utilities/Hooks/useValuesFromParams'

/**
 * Props passed from Routes.tsx
 */
export interface IRouteApplicationProps {
    isPageRenderedByItself?: boolean
    isPageWithoutUserInitialization?: boolean
}

interface IOwnProps extends RouteComponentProps, IRouteApplicationProps {
    children: (pageData: IPageData | null) => React.ReactElement
    pageId: string | null
}

interface IDispatchProps {
    applicationInitialised: () => void
}

interface IStateProps {
    asetukset: RootState['asetukset']
    isLoadingInitialData: boolean
    loginUser: ILoginKayttaja | null
    areNavigationItemsSet: boolean
    tyylit: Map<string, string>
}

interface IApplicationProps extends IOwnProps, IDispatchProps, IStateProps {}

const MainContainer = styled.div`
    height: 100%;
`

export const ApplicationUnconnected: React.FC<IApplicationProps> = ({
    applicationInitialised,
    loginUser,
    asetukset,
    tyylit,
    isLoadingInitialData,
    areNavigationItemsSet,
    children,
    match,
    pageId,
    location,
    isPageRenderedByItself = false,
    isPageWithoutUserInitialization = false,
}) => {
    const { isGettingJwt } = useJwt(isPageRenderedByItself)

    useValuesFromParams(location)

    useEffect(() => {
        if (isPageWithoutUserInitialization || isGettingJwt) {
            return
        }

        applicationInitialised()
    }, [applicationInitialised, isPageWithoutUserInitialization, isGettingJwt])

    if (isPageRenderedByItself) {
        const pageData = null
        return children(pageData)
    }

    const isReadyToRender = () => {
        // don't render before we've downloaded everything essential from the server
        if (isLoadingInitialData) {
            return false
        }

        const loginUserSet = loginUser !== null
        const asetuksetSet = asetukset.size > 0
        const stylesSet = tyylit.size > 0

        return asetuksetSet && loginUserSet && areNavigationItemsSet && stylesSet
    }

    if (!isReadyToRender()) {
        return <LoadingIndicatorGlobal isLoading />
    }

    return (
        <LoginUserContext.Provider value={loginUser}>
            <TyylitContext.Provider value={tyylit}>
                <AsetuksetContext.Provider value={asetukset}>
                    <MainContainer>
                        <ApplicationPage pageId={pageId} routerMatch={match}>
                            {children}
                        </ApplicationPage>

                        <NotificationHandler />
                        <LoadingIndicatorGlobal />

                        <FormViewModalsManager />
                        <ListModalsManager />
                    </MainContainer>
                </AsetuksetContext.Provider>
            </TyylitContext.Provider>
        </LoginUserContext.Provider>
    )
}

const mapStateToProps = (state: RootState): IStateProps => {
    const { asetukset, loginUser, tyylit } = state

    return {
        asetukset,
        isLoadingInitialData: !selectIsAsyncOperationIsReady(state, 'legacy.ApplicationInitialized'),
        loginUser,
        areNavigationItemsSet: selectAreNavigationItemsSet(state),
        tyylit,
    }
}

const mapDispatchToProps = {
    applicationInitialised: applicationInitialisedThunk,
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationUnconnected)
