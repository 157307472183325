import styled from '@emotion/styled'

import { useDispatch, useSelector } from 'react-redux'
import { selectSelectedEvents } from '../State/EmployeeCalendarSelectors'
import { Chip, Icon } from '../../generic-components'
import { translate } from '../../localization'
import { setSelectedEvents } from '../State/EmployeeCalendarReducer'
import ActionButton from './ActionButton'
import { CancelEventAction, DeleteEventAction, EditEventAction, getPermission } from '../Utils/eventActions'
import { compact, isEmpty } from 'lodash-es'
import { TFunctionalityAction } from '../../view-engine'
import { initEventAction } from '../State/EmployeeCalendarThunks'
import { IEmployeeDataSourceItem } from '../../messaging/Types/IEmployeeDataSourceItem'
import { Fade } from '@mui/material'

type Props = {
    employee?: IEmployeeDataSourceItem
}

const Container = styled.div`
    display: flex;
    align-items: center;
    margin-right: 24px;
    gap: 16px;
`

const ActionsContainer = styled.div`
    display: flex;
    gap: 8px;
`

const MassEditControls = ({ employee }: Props): JSX.Element | null => {
    const dispatch = useDispatch()

    const selectedEvents = useSelector(selectSelectedEvents)

    if (isEmpty(selectedEvents)) {
        return null
    }

    const clearSelection = () => {
        dispatch(setSelectedEvents([]))
    }

    const initAction = (action: TFunctionalityAction) => {
        dispatch(initEventAction(action, selectedEvents, employee))
    }

    const canBeCanceled = selectedEvents.every((event) => getPermission(event, CancelEventAction)?.OnkoSallittu)
    const canBeDeleted = selectedEvents.every((event) => getPermission(event, DeleteEventAction)?.OnkoSallittu)

    const cancelAction = canBeCanceled
        ? {
              icon: 'cancel',
              tooltip: 'Peru vuoro',
              onClick: () => initAction(CancelEventAction),
          }
        : undefined

    const deleteAction = canBeDeleted
        ? {
              icon: 'delete',
              tooltip: 'remove-button',
              onClick: () => initAction(DeleteEventAction),
          }
        : undefined

    const actions = compact([
        {
            icon: 'edit',
            tooltip: 'muokkaa',
            onClick: () => initAction(EditEventAction),
        },
        cancelAction,
        deleteAction,
    ])

    return (
        <Fade in appear>
            <Container>
                <Chip
                    label={`${selectedEvents.length} ${translate('selected')}`}
                    onEndIconClick={clearSelection}
                    showDeleteIcon
                />

                <ActionsContainer>
                    {actions.map(({ icon, tooltip, onClick }) => (
                        <ActionButton key={icon} size="small" onClick={onClick}>
                            <Icon tooltip={translate(tooltip)}>{icon}</Icon>
                        </ActionButton>
                    ))}
                </ActionsContainer>
            </Container>
        </Fade>
    )
}

export default MassEditControls
