import * as React from 'react'
import styled from '@emotion/styled'

import { ISelectRowsFunction, TNewlySelected, TSelectedValues } from '../../../../Interfaces'
import Row from './Row'
import { TranslatedTypography } from '../../../../../../generic-components'

interface IMassSelectionRowProps<T> {
    virtualizedListRowStyle: React.CSSProperties
    selectAllAction: ISelectRowsFunction<boolean>
    selectedNewItems: TNewlySelected<T>
    items: T[]
    selectedValueItems: TSelectedValues<T>
    itemIdField: keyof T
}

const RowTextTypography = styled(TranslatedTypography)`
    color: ${({ theme }) => theme.componentExtensions.inputs.textInputDefault};
    font-size: 14px;
`
const RowTextComponent = () => <RowTextTypography variant="bodyXS">valitse-kaikki</RowTextTypography>

export default class MassSelectionRow<T> extends React.Component<IMassSelectionRowProps<T>> {
    rowIsSelected(): boolean {
        const { selectedNewItems, items, selectedValueItems, itemIdField } = this.props

        const visibleSelectedItemCount = items.filter((item) => {
            const id = item[itemIdField]

            return selectedNewItems.has(id) || selectedValueItems.has(id)
        }).length

        const rowSelected = visibleSelectedItemCount === items.length && items.length > 0
        return rowSelected
    }

    toggleAll = (): void => {
        const rowCurrentlySelected = this.rowIsSelected()
        this.props.selectAllAction(!rowCurrentlySelected)
    }

    render(): React.ReactNode {
        const { virtualizedListRowStyle, items } = this.props

        const rowIsSelected = this.rowIsSelected()
        const disabled = items.length === 0

        return (
            <Row
                checkboxSelectable
                disabled={disabled}
                renderHr
                rowItemComponentInstance={<RowTextComponent />}
                rowSelected={rowIsSelected}
                selectAction={this.toggleAll}
                virtualizedListRowStyle={virtualizedListRowStyle}
                testId="VALUE_PICKER_SELECT_ALL"
            />
        )
    }
}
