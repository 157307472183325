import styled from '@emotion/styled'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { ChangeEvent, FocusEvent, MouseEvent, ReactNode } from 'react'
import ReadOnlyText from '../../ReadOnlyText'
import { translate, Translation } from '../../../localization'
import { useAppTheme } from '../../../theme/'
import RequiredIndicator from '../../RequiredIndicator'
import { getTestId } from '../../../test'

interface ITextStyleProps {
    width?: string
    height?: string
}

export interface ITextInputProps {
    value: string | null
    multiline?: boolean
    disabled?: boolean
    onChange?: (input: string | null) => void
    onBlur?: (e: FocusEvent) => void
    onClick?: (e: MouseEvent) => void
    label?: string
    placeholder?: string
    type?: 'number' | 'text' | 'time' | 'password'
    overrideStyle?: ITextStyleProps
    errors?: string
    endAdorment?: string | ReactNode
    isRequiredField?: boolean
    isReadOnly?: boolean
    name?: string
    autoFocus?: boolean
    resizable?: boolean
    maxRows?: number
    valueInputId?: string
    AppendComponent?: ReactNode
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 100%;
`

const LabelContainer = styled.div`
    margin-bottom: 5px;
`
const ErrorContainer = styled.div`
    margin-top: 4px;
    max-width: 100%;
`

const TextFieldContainer = styled.div<{ appendComponent: boolean }>`
    ${({ appendComponent }) =>
        appendComponent &&
        `
        display: flex;
        align-items: center;
    `}
`

const StyledTextField = styled(TextField, { shouldForwardProp: (propName) => propName !== 'isReadOnly' })<{
    resizable?: boolean
    isReadOnly?: boolean
}>`
    .MuiOutlinedInput-input {
        padding-left: 12px;
    }

    .MuiOutlinedInput-multiline {
        padding: 12px 0 12px 0;
    }

    .MuiOutlinedInput-root {
        transition: background-color ${({ theme }) => theme.tokens.transitionQuick};
        background-color: ${({ theme }) => theme.componentExtensions.inputs.bgInputDefault};
        border-radius: ${({ theme }) => theme.tokens.radiusDefault};

        fieldset {
            border: 1px solid ${({ theme }) => theme.componentExtensions.inputs.borderInputDefault};
        }

        :hover {
            background-color: ${({ theme }) => theme.componentExtensions.inputs.bgInputDropdownHover};
        }

        &.Mui-focused {
            background-color: ${({ theme }) => theme.componentExtensions.inputs.bgInputDefault};
        }

        &.Mui-focused fieldset {
            border: 2px solid ${({ theme }) => theme.componentExtensions.inputs.borderInputFocus};
        }

        &.Mui-disabled fieldset {
            border-color: ${({ theme, isReadOnly }) =>
                !isReadOnly
                    ? theme.componentExtensions.inputs.borderInputDisabled
                    : theme.componentExtensions.inputs.borderInputDefault};

            background-color: ${({ theme }) => theme.componentExtensions.inputs.bgInputDisabled};
        }

        &.Mui-error fieldset {
            border: 2px solid ${({ theme }) => theme.componentExtensions.inputs.borderInputError};
        }
    }

    .MuiInputBase-input.Mui-disabled {
        z-index: 1;

        ${({ isReadOnly, theme }) =>
            isReadOnly &&
            `
             color: ${theme.componentExtensions.text.primary};
            -webkit-text-fill-color: ${theme.componentExtensions.text.primary};
        `}
    }

    textarea {
        ${({ resizable }) => resizable && 'resize: vertical'};

        &::placeholder {
            opacity: 1;
        }
    }

    input {
        &::placeholder {
            opacity: 1;
        }
    }
`

const TextInput = ({
    name,
    value,
    disabled,
    onChange,
    onClick,
    onBlur,
    label,
    multiline,
    placeholder,
    type,
    overrideStyle,
    errors,
    endAdorment,
    isRequiredField,
    isReadOnly,
    autoFocus,
    resizable,
    maxRows = 10,
    AppendComponent,
}: ITextInputProps): JSX.Element => {
    const { colors, typographyExtensions, componentExtensions } = useAppTheme()

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        onChange && onChange(event.target.value)
    }

    const hasValue = Boolean(value)

    const labelContent = label || ''

    const _height = overrideStyle?.height ?? (multiline ? 'auto' : '40px')

    const testId = getTestId(['VALUE_PICKER'], label)

    return (
        <Container>
            {label && (
                <LabelContainer>
                    <ReadOnlyText
                        translate={Translation.has(labelContent)}
                        usage="dropdown_label"
                        rawColor={
                            !disabled ? componentExtensions.text.primary : componentExtensions.text.disabledDarker
                        }
                    >
                        {labelContent}
                    </ReadOnlyText>
                    {isRequiredField && <RequiredIndicator />}
                </LabelContainer>
            )}

            <TextFieldContainer appendComponent={AppendComponent !== undefined}>
                <StyledTextField
                    data-testid={testId}
                    name={name}
                    value={value}
                    disabled={disabled || isReadOnly}
                    isReadOnly={isReadOnly}
                    placeholder={
                        Translation.has(placeholder) ? translate(placeholder) : placeholder || translate('write')
                    }
                    multiline={multiline}
                    minRows={multiline ? 4 : undefined}
                    maxRows={multiline ? maxRows : undefined}
                    style={{ width: overrideStyle?.width ?? 300, height: _height }}
                    onChange={handleChange}
                    onClick={onClick}
                    onBlur={onBlur}
                    type={type}
                    autoFocus={autoFocus}
                    resizable={resizable}
                    error={!!errors}
                    InputProps={{
                        endAdornment: endAdorment ? (
                            <InputAdornment position="end">{endAdorment}</InputAdornment>
                        ) : null,
                        style: {
                            ...typographyExtensions.dropdown_selection,
                            height: _height,
                            color: hasValue ? colors.neutralsGrey100 : colors.neutralsGrey60,
                        },
                    }}
                />

                {AppendComponent}
            </TextFieldContainer>

            {errors && (
                <ErrorContainer>
                    <ReadOnlyText
                        usage="bodyS"
                        rawColor={componentExtensions.inputs.colorTextInputHelperError}
                        textOverflow="ellipsis"
                    >
                        {errors || ''}
                    </ReadOnlyText>
                </ErrorContainer>
            )}
        </Container>
    )
}

export default TextInput
