import { RootState } from 'typesafe-actions'

import IListViewModel from '../interfaces/IListViewModel'
import {
    getTestDataSourceActionName,
    getTestDataSourceDependentReducerMap,
    getTestDataSourceDependentReducerState,
    getTestDataSourceId,
    getTestDataSourcePropertyId,
    getTestDataSourcePropertyName,
} from '../../data-source'
import IListDataReducerCombined from '../interfaces/IListDataReducerCombined'
import { IConfigurableListState } from '../interfaces/IListState'
import {
    getTestValuePickerCombinedReducerState,
    getTestValuePickerId,
    valuePickerInitialValuesReducer,
    valuePickerReducer,
} from '../../value-picker'
import configurableListReducer from '../State/ConfigurableListReducer'
import { MODAL_INITIAL_STATE, modalReducer } from '../../modal'
import EListViewActionFunctionalityType from '../Types/EListViewActionFunctionalityType'
import { getAsyncOperationReducerMap, getAsyncOperationReducerState } from '../../async-operation'
import { getEventsListTestConfiguration } from './ActualListsTestData/EventsListTestConfiguration'
import { getTestNavigationReducerMap, getTestNavigationStoreState } from '../../navigation'

export const getTestListId = (): string => 'TestList'

export const getTestConfigurationForActualList = (
    listId: string,
    configuration?: Partial<IListViewModel>
): IListViewModel => {
    switch (listId) {
        case 'VuorotJaVapaatList':
            return getEventsListTestConfiguration(configuration)
        default:
            throw new Error('No configuration for the given list')
    }
}

export const getTestListConfiguration = (configuration?: Partial<IListViewModel>): IListViewModel => ({
    Id: getTestListId(),
    DisplayName: 'Value picker test list',
    DataSourceId: getTestDataSourceId(),
    StaticActions: [
        {
            Display: {
                ButtonText: 'Static action',
            },
            Functionality: {
                FunctionalityType: EListViewActionFunctionalityType.Immediate,
                DataSourceActionId: getTestDataSourceActionName(),
                PermissionId: 'TestPermissionId',
            },
        },
    ],
    FilterItselfDataRequestParameterName: null,
    BulkActions: [],
    RowActions: [
        {
            Display: { Icon: 'get_app', Tooltip: 'Lataa tiedostot' },
            Functionality: {
                DataSourceActionId: 'TransferBatches_GetFiles',
                FunctionalityType: EListViewActionFunctionalityType.Download,
                PermissionId: 'GET_TRANSFER_BATCH_FILES',
            },
        },
    ],
    Columns: [
        {
            Id: `${getTestValuePickerListId}_TestProperty`,
            Sortable: false,
            Header: 'Test Property',
            ListViewId: getTestListId(),
            ViewComponentId: 'ListElementText',
            HeaderComponent: null,
            Visible: true,
            Width: 15,
            Order: 1,
            ParameterMapping: [
                {
                    Id: 1,
                    DataSourceProperty: getTestDataSourcePropertyName(),
                    DataSourcePropertyId: getTestDataSourcePropertyId(),
                    ViewComponentParameter: 'displayValue',
                    ViewComponentParameterId: 'NotUsed',
                },
            ],
            StaticParameters: null,
            SortByParameter: '',
            Tooltip: null,
        },
    ],
    FilterIds: [getTestValuePickerId()],
    IsSelectable: true,
    AutoFetchData: true,
    AllowColumnManagement: true,
    RowSelectable: false,
    AdditionalDataFetchParameters: {
        GroupBy: [
            {
                GroupByProperties: [],
                CustomFunctions: [
                    `SingleOrCount(TestLabel,${getTestDataSourcePropertyId()},${getTestDataSourcePropertyName()})`,
                ],
                WindowFunctions: [
                    {
                        TargetProperty: 'Id',
                        Function: 'Count',
                        Label: 'Items',
                    },
                ],
                ReplaceListData: false,
            },
        ],
    },
    ...configuration,
})

export const getTestListState = (configuration?: Partial<IListViewModel>): IConfigurableListState => ({
    configuration: getTestListConfiguration(configuration),
    selected: new Set(),
    opened: new Set(),
    maximumRowActions: 0,
    defaultOpened: new Set(),
    filtersHeight: 0,
    columnWidths: {},
})

export const getTestValuePickerListId = (): string => 'TestValuePickerList'

export const getTestValuePickerListConfiguration = (): IListViewModel => ({
    ...getTestListConfiguration(),
    Id: getTestValuePickerListId(),
    DisplayName: 'Value picker test list',
    FilterIds: [],
})

export const getTestValuePickerListState = (): IConfigurableListState => ({
    configuration: getTestValuePickerListConfiguration(),
    selected: new Set(),
    opened: new Set(),
    maximumRowActions: 0,
    defaultOpened: new Set(),
    filtersHeight: 0,
    columnWidths: {},
})

export const getTestListDataCombinedReducerState = (): IListDataReducerCombined => ({
    listData: {
        [getTestListId()]: getTestListState(),
        [getTestValuePickerListId()]: getTestValuePickerListState(),
    },
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getConfigurableListDependentReducerMap = () => ({
    valuePicker: valuePickerReducer,
    valuePickerInitialValues: valuePickerInitialValuesReducer,
    listData: configurableListReducer,
    modal: modalReducer,
    ...getAsyncOperationReducerMap(),
    ...getTestDataSourceDependentReducerMap(),
    ...getTestNavigationReducerMap(),
})

export const getConfigurableListDependentReducerState = (): Partial<RootState> => ({
    ...getTestValuePickerCombinedReducerState(),
    ...getTestListDataCombinedReducerState(),
    ...getAsyncOperationReducerState(),
    ...getTestDataSourceDependentReducerState(),
    ...getTestNavigationStoreState(),
    valuePickerInitialValues: {},
    modal: MODAL_INITIAL_STATE,
})
