import { IThunkBaseAction } from '../../generic-state'

import TFunctionalityAction from '../Types/Actions/TFunctionalityAction'
import EActionFunctionalityType from '../Types/Actions/EActionFunctionalityType'
import { openFormViewConfigurableModalThunk, openFormViewCustomModalThunk } from '../../form-view'
import { IDataSourceItem } from '../../data-source-types'
import { downloadDataToFileThunk, executeDataItemActionThunk, selectDataSourceActionById } from '../../data-source'
import { displayErrorToaster, displayWarningToaster } from '../../notifications'
import { openListModalThunk } from '../../configurable-list'
import { getActionPermission } from '../Utilities/PermissionUtilities'
import { Translation } from '../../localization'
import { getLogger } from '../../log'
import moment from 'moment'
import { get, set } from 'lodash-es'

const Log = getLogger('view-engine.ViewEngineThunks')

interface IHandleViewEngineActionThunkParams {
    items: IDataSourceItem[]
    functionalityAction: TFunctionalityAction
    dataSourceId: string | null
    additionalRequestData?: any
    onSubmitCallback?: () => void
    overrideDataSourceId?: boolean
}

export const handleViewEngineActionThunk =
    ({
        items: originalItems,
        functionalityAction,
        dataSourceId,
        additionalRequestData,
        onSubmitCallback,
        overrideDataSourceId,
    }: IHandleViewEngineActionThunkParams): IThunkBaseAction =>
    async (dispatch, getState) => {
        if (dataSourceId === null) {
            Log.error('DataSourceId was null')
            return
        }

        const state = getState()

        const itemsForWhichTheActionIsAllowed = originalItems.filter(
            (item) => getActionPermission(item, functionalityAction)?.OnkoSallittu
        )

        if (itemsForWhichTheActionIsAllowed.length === 0) {
            Log.error('No items for which the action is permitted. Action not run.')
            return
        }

        if (itemsForWhichTheActionIsAllowed.length !== originalItems.length) {
            const message = Translation.translateKey('view-engine.ViewEngineThunks.ItemsFilteredOutMessage', {
                itemsIncludedCount: itemsForWhichTheActionIsAllowed.length,
                totalCount: originalItems.length,
            })

            dispatch(displayWarningToaster(message))
        }

        const itemIds = itemsForWhichTheActionIsAllowed.map((item) => item.Id)

        try {
            switch (functionalityAction.FunctionalityType) {
                case EActionFunctionalityType.Download: {
                    const actionId = functionalityAction.DataSourceActionId

                    await dispatch(downloadDataToFileThunk(dataSourceId, itemIds, actionId))
                    break
                }
                case EActionFunctionalityType.ConfigurableModal: {
                    const {
                        AdditionalPropertyMapping,
                        FormId,
                        IgnoreInitialValueFields,
                        AdditionalInitialValueSourceProperties,
                        DataSourceActionId,
                    } = functionalityAction

                    const additionalRequestProps = {} as Record<string, any>
                    // TODO: should have a strategy to map the additional props when there are multiple items?
                    if (AdditionalPropertyMapping && itemsForWhichTheActionIsAllowed.length === 1) {
                        AdditionalPropertyMapping.forEach((mapping) =>
                            set(
                                additionalRequestProps,
                                mapping.Key,
                                get(itemsForWhichTheActionIsAllowed[0], mapping.PropertyPath)
                            )
                        )
                    }

                    const actionConfiguration = selectDataSourceActionById(state, dataSourceId, DataSourceActionId)

                    const dataPropertyParameters = actionConfiguration?.DataPropertyParameters ?? []

                    dataPropertyParameters.forEach((parameter) => {
                        set(
                            additionalRequestProps,
                            parameter.Key,
                            itemsForWhichTheActionIsAllowed.map((x) => get(x, parameter.PropertyPath))
                        )
                    })

                    const additionalInitialValues = {} as Record<string, any>

                    if (AdditionalInitialValueSourceProperties) {
                        AdditionalInitialValueSourceProperties.forEach((mapping) => {
                            const values = itemsForWhichTheActionIsAllowed
                                .map((x) => {
                                    const itemValueFromPropertyPath = get(x, mapping.PropertyPath)

                                    if (itemValueFromPropertyPath) {
                                        return itemValueFromPropertyPath
                                    }

                                    const itemValueFromKeyPath = get(x, mapping.key)

                                    return itemValueFromKeyPath
                                })
                                .map((x) => {
                                    if (mapping.format === 'toDate') {
                                        const value = moment(x).format('YYYY-MM-DDT00:00:00+02:00')

                                        return value
                                    } else {
                                        return x
                                    }
                                })

                            const nonUndefinedValues = values.filter((x) => x !== undefined)

                            set(
                                additionalInitialValues,
                                mapping.key,
                                mapping.liftToArray
                                    ? nonUndefinedValues
                                    : mapping.reduceToSingleValue
                                    ? nonUndefinedValues[0]
                                    : nonUndefinedValues.length === 1
                                    ? nonUndefinedValues[0]
                                    : nonUndefinedValues
                            )
                        })
                    }

                    const _itemId = functionalityAction.IgnoreOriginalItemIds ? [] : itemIds

                    await dispatch(
                        openFormViewConfigurableModalThunk(
                            FormId,
                            dataSourceId,
                            _itemId,
                            additionalRequestProps,
                            IgnoreInitialValueFields,
                            additionalInitialValues,
                            onSubmitCallback,
                            overrideDataSourceId
                        )
                    )
                    break
                }

                case EActionFunctionalityType.CustomModal: {
                    await dispatch(openFormViewCustomModalThunk(functionalityAction.FormId, dataSourceId, itemIds))
                    break
                }

                case EActionFunctionalityType.Immediate:
                case EActionFunctionalityType.Confirmation: {
                    const actionId = functionalityAction.DataSourceActionId
                    const actionRequestParameters = additionalRequestData ?? {}

                    // TODO: should there be its own handling for a single item using `executeSingleDataItemActionThunk`?
                    // That would fetch only the item changed by default. Not needed for calendars though, so leaving
                    // it out now.
                    await dispatch(
                        executeDataItemActionThunk(dataSourceId, itemIds, actionId, {
                            additionalRequestProps: actionRequestParameters,
                        })
                    )

                    onSubmitCallback?.()

                    break
                }

                case EActionFunctionalityType.ListModal: {
                    const {
                        ListId,
                        HiddenValuePickerIds,
                        ModalTitle,
                        DataSourceActionId,
                        SelectedItemsOutputProperty,
                        OnRowClickItemOutProperty,
                    } = functionalityAction

                    const onListRowSelect = async (clickedRowItem: IDataSourceItem) => {
                        const actionParameters = {
                            [OnRowClickItemOutProperty]: clickedRowItem.Id,
                        }

                        try {
                            await dispatch(
                                executeDataItemActionThunk(dataSourceId, itemIds, DataSourceActionId, {
                                    actionParameters,
                                })
                            )
                        } catch (error) {
                            dispatch(displayErrorToaster(error))
                        }
                    }

                    await dispatch(
                        openListModalThunk({
                            listIdForModal: ListId,
                            selectedItemIds: itemIds,
                            hiddenValuePickerIds: HiddenValuePickerIds,
                            modalTitle: ModalTitle,
                            onListRowSelect,
                            additionalDataSourceDataRequestFiltersParameters: SelectedItemsOutputProperty
                                ? {
                                      [SelectedItemsOutputProperty]: itemIds,
                                  }
                                : null,
                        })
                    )
                    break
                }

                default:
                    Log.error('No handling implemented for action type $0', functionalityAction.FunctionalityType)
                    break
            }
        } catch (error) {
            dispatch(displayErrorToaster(error))
        }
    }
