import * as React from 'react'
import { useLayoutEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { Icon, Tooltip } from '../../generic-components'

type Props = {
    icon?: string | null
    children: React.ReactNode
    className?: string
    tooltip?: string | null
}

//TODO IF WE FALLBACK TO ICONS, CHENGE 0 TO iconSize
const IconPlaceholder = styled.div`
    width: ${0};
`

const FieldItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 18px 32px 4px;
    width: 100%;
`

const IconContainer = styled.div`
    margin-right: 7px;
`

const InfoIconContainer = styled.div<{ height: number }>`
    display: flex;
    margin-left: 5px;
    align-items: flex-start;
    position: relative;
    height: ${({ height }) => height}px;

    & .info-icon {
        position: absolute;
        top: ${({ height }) => (height < 80 && height > 50 ? 'calc(50% - 10px)' : 'calc(25% - 10px)')};
    }
`

const InfoIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 32px;
`

const FieldItemContentContainer = styled.div`
    min-height: 49px;
    display: flex;
    flex-direction: row;
    width: 100%;
`

const ChildContainer = styled.div`
    display: flex;
    flex-direction: column;
`

/**
 * You can use this in forms when there are fields for which you need to display
 * an icon. The component simply renders the icon or an empty space for it if the
 * icon isn't given, and then next to it renders the actual content given with
 * children property next
 */
const FieldContainer = ({ tooltip, children }: Props): JSX.Element => {
    const contentRef = useRef<HTMLDivElement>(null)
    const [contentHeight, setContentHeight] = useState<number>(0)

    useLayoutEffect(() => {
        if (!contentRef.current) {
            return
        }

        const { height } = contentRef.current.getBoundingClientRect()
        setContentHeight(height)
    }, [])

    const iconSpace = <IconPlaceholder />

    const infoIcon = tooltip && (
        <InfoIconContainer height={contentHeight}>
            <Tooltip title={tooltip} placement="bottom-start">
                <InfoIconWrapper className="info-icon">
                    <Icon size={24} colorTheme="primary">
                        info_outlined
                    </Icon>
                </InfoIconWrapper>
            </Tooltip>
        </InfoIconContainer>
    )

    return (
        <FieldItemContainer>
            <IconContainer>{iconSpace}</IconContainer>
            <FieldItemContentContainer ref={contentRef}>
                <ChildContainer>{children}</ChildContainer>
                {infoIcon}
            </FieldItemContentContainer>
        </FieldItemContainer>
    )
}

export default FieldContainer
