import * as React from 'react'
import { JSX, memo, useContext } from 'react'
import styled from '@emotion/styled'

import { selectLoadingFieldName, TDataSourceId } from '../../../../data-source'
import { IConfigurableListColumnAdditionalParameter } from '../../../interfaces/IList'
import { ILoadingItemsData } from 'interfaces/actions/ILoadingAction'
import IViewComponentParameterToDataSourceProperty from '../../../interfaces/IViewComponentParameterToDataSourceProperty'
import ConfigurableListComponentRegistry from '../../../ConfigurableListComponentRegistry'
import { get } from 'lodash-es'

interface IRowElementProps {
    componentDisabled: boolean
    dataSourceId: TDataSourceId
    item: any
    itemId: string | number
    propsData: IViewComponentParameterToDataSourceProperty[]
    staticPropsData: null | IConfigurableListColumnAdditionalParameter[]
    viewComponentId: string
    width: number
    offset?: number
}

const Container = styled.div<{ width: number; offset?: number }>`
    display: inline-flex;
    width: ${({ width }: { width: number }) => `${width}px`};
    min-width: ${({ width }) => `${width}px`};
    /* Max width is used by some children */
    max-width: ${({ width }) => `${width}px`};
    white-space: nowrap;
    overflow: hidden;
    margin-left: 4px;
    margin-right: 4px;
    padding-left: ${({ offset }) => offset && `${offset}px`};
`

/**
 * Listan rivin yksittäisen sarakkeen elementin.
 * Renderöi listan metadatassa määritetyn komponentin ja antaa
 * sille metadatassa määritetyt propsit rivin itemiltä.
 */
const RowElement = ({
    item,
    viewComponentId,
    propsData,
    staticPropsData,
    componentDisabled,
    width,
    itemId,
    dataSourceId,
    offset,
}: IRowElementProps): JSX.Element | null => {
    const { getComponent } = useContext(ConfigurableListComponentRegistry)

    const formStaticProps = (staticParameters: null | IConfigurableListColumnAdditionalParameter[]) => {
        if (staticParameters === null) {
            return {}
        }

        const staticProps: any = {}
        staticParameters.forEach((staticParameter) => {
            const { Name, Value } = staticParameter

            staticProps[Name] = Value
        })

        return staticProps
    }

    const formRequiredProps = (parameterMappingData: IViewComponentParameterToDataSourceProperty[]) => {
        const pakollisetPropsit = parameterMappingData.reduce((accumulatedProps: any, parameterData) => {
            const { ViewComponentParameter: nameOfProp, DataSourceProperty: nameOrPathOfDataFieldInItem } =
                parameterData

            // By using _.get here, we support nested property paths and not solely
            // top-level properties.
            accumulatedProps[nameOfProp] = get(item, nameOrPathOfDataFieldInItem)
            return accumulatedProps
        }, {})

        return pakollisetPropsit
    }

    const formLoadingInfo = (): ILoadingItemsData => {
        const loadingKey = selectLoadingFieldName(dataSourceId)
        return {
            loadingFieldName: loadingKey,
            loadingItems: {
                itemId,
            },
        }
    }

    const Component = getComponent(viewComponentId)

    if (!Component) {
        return null
    }

    const requiredProps = formRequiredProps(propsData)
    const staticProps = formStaticProps(staticPropsData)
    const loadingData = formLoadingInfo()
    return (
        <Container width={width} offset={offset}>
            <Component
                {...requiredProps}
                {...staticProps}
                dataForLoading={loadingData}
                disabled={componentDisabled}
                enabled={!componentDisabled}
            />
        </Container>
    )
}

export default memo(RowElement)
