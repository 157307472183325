import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, CancelToken, CancelTokenSource } from 'axios'
import qs from 'qs'
import uuidV4 from 'uuid/v4'
import { get } from 'lodash-es'

import EHttpStatus from './Constants/EHTTPStatus'
import URL from 'common/URL'
import SERVER_ERROR_NAME from './Constants/ServerErrorName'
// Circular dependency so we import this one directly. I'm not sure if this can
// be solved otherwise, because the webapi function obviously needs rest-pi module,
// and we need to use the logout function here. This is a rare occurrense though
// so it's not too big of a problem like this.
import { logout as logoutQuery } from '../login/WebApi/LoginWebApi'
import { deleteJwt, getJwt } from '../authentication'
import { getIsLegacyEnvironment } from '../legacy-environment-adapter'

const BASE_URL = URL.api('')

// eslint-disable-next-line no-shadow
export enum ERequestMethod {
    DELETE = 'DELETE',
    GET = 'GET',
    HEAD = 'HEAD',
    OPTIONS = 'OPTIONS',
    PATCH = 'PATCH',
    POST = 'POST',
    PUT = 'PUT',
}

export interface IRequestAdditionalConfig extends Omit<AxiosRequestConfig, 'method' | 'url' | 'data'> {}

interface IMakeRequestConfig<TRequestData> extends IRequestAdditionalConfig {
    method: ERequestMethod
    url: string
    data?: TRequestData
}

const baseUrlOverride = process.env.BASE_URL_OVERRIDE

const defaultOptions: AxiosRequestConfig = {
    baseURL: baseUrlOverride || BASE_URL,
    withCredentials: !baseUrlOverride,
    headers: {
        common: {
            Authorization: 'Forms',
        },
    },
    paramsSerializer: (params) => qs.stringify(params),
}

export const createCancelToken = (): CancelTokenSource => {
    return axios.CancelToken.source()
}

// Assign our own default options
Object.assign(axios.defaults, defaultOptions)

const makeRequest = async <TResponseData, TRequestData = unknown>(
    config: IMakeRequestConfig<TRequestData>
): Promise<TResponseData> => {
    const axiosResponse = await makeAxiosRequest<TResponseData, TRequestData>(config)

    return axiosResponse.data
}

export const makeAxiosRequest = async <TResponseData, TRequestData = unknown>(
    config: IMakeRequestConfig<TRequestData>
): Promise<AxiosResponse<TResponseData>> => {
    const configWithUuidParam = { ...config, params: { ...config.params, RequestId: uuidV4() } }

    try {
        const jwt = getJwt()

        if (jwt) {
            configWithUuidParam.headers = { common: { Authorization: `Bearer ${jwt}` } }
        }

        const response = await axios.request<TResponseData>(configWithUuidParam)

        return response
    } catch (e) {
        const error: AxiosError = e
        const status: number | undefined = get(error, 'response.status')
        error.name = SERVER_ERROR_NAME

        if (status === EHttpStatus.Unauthorized || status === EHttpStatus.Forbidden) {
            if (!getIsLegacyEnvironment()) {
                await logoutQuery() // Once we fully use jwt, we don't need this at all.

                if (getJwt()) {
                    deleteJwt()
                }

                URL.goto(URL.oldClient(''))
            } else {
                URL.goto(URL.oldClient('logout.aspx'))
            }
        }

        throw e
    }
}

export default makeRequest

/**
 * Cancellation and error handling template function for making REST API requests
 *
 * @param execute The function which does the actual operation. Run inside a `try` block.
 * @param handleError Error handling logic. Executed inside the `catch` block when the
 *                    error was not an Axios cancellation error.
 * @param after Run after all non-cancelled requests in the `finally` block.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const makeRequestTemplate = ({
    execute,
    handleError,
    after,
}: {
    execute: (cancelToken: CancelToken) => Promise<void>
    handleError?: (error?: AxiosError) => void
    after?: () => void
}) => {
    const source = axios.CancelToken.source()
    const cleanup = () => {
        source.cancel('Cancelled due to component unmounting or new request')
    }

    const handleRequest = async () => {
        let requestWasCancelled = false

        try {
            await execute(source.token)
        } catch (error) {
            if (axios.isCancel(error)) {
                requestWasCancelled = true
            } else {
                if (handleError) {
                    handleError(error)
                } else {
                    throw error
                }
            }
        } finally {
            if (!requestWasCancelled) {
                after && after()
            }
        }
    }

    const promise = handleRequest()

    return { cleanup, promise }
}
