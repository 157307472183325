import moment from 'moment'
import { ISummaryLabel } from '../../generic-components/SummaryLabel/SummaryLabels'
import { formatForDisplay } from '../../dates'
import { RootState } from 'typesafe-actions'
import { IAsyncData } from '../Types/IAsyncData'
import { IEmployeeDataSourceItem } from '../Types/IEmployeeDataSourceItem'
import IMessage from '../Types/IMessage'
import IMessageThread, { EMessageType, IEmployeeIdentifier } from '../Types/IMessageThread'
import { IEvent } from '../../event'
import { INewCommentForm, INewMessageForm } from '../Types/IMessagingState'
import { TDataSourceItemId } from '../../data-source-types'
import { IMessagingDataSourceItem } from '../Types/IMessagingDataSourceItem'
import { selectValuePickerValue } from '../../value-picker'
import { isEmpty, size } from 'lodash-es'
import { isSelectedIdsEmployees, isSelectedIdsEvents, isSelectedIdsMessages } from '../Utils/messageUtils'
import { compact } from 'lodash'

const EMPLOYEE_VALUEPICKER = 'FormView_ValuePicker_Tyontekija_No_Dependencies'

const selectEmployeeName = (state: RootState): string | undefined => {
    const selectedIds = state.messaging.selectedIds
    if (selectedIds && selectedIds.length > 0) {
        if (isSelectedIdsEmployees(selectedIds[0])) {
            return `${selectedIds[0].Etunimi} ${selectedIds[0].Sukunimi}`
        }
        if (isSelectedIdsMessages(selectedIds[0])) {
            return `${selectedIds[0].EmployeeName}`
        }
    }
    return undefined
}

export const selectViewedMessageThread = (state: RootState): IMessageThread | undefined => {
    return state.messaging.viewedMessageThread
}

export const selectShowMessageThread = (state: RootState): boolean => {
    return state.messaging.showMessageThread
}

export const selectShowNewMessageForm = (state: RootState): boolean => {
    return state.messaging.showNewMessageForm
}

export const selectShowNewCommentForm = (state: RootState): boolean => {
    return state.messaging.showNewCommentForm
}

export const selectReplyMessageValue = (state: RootState): string => {
    return state.messaging.replyMessageValue
}

export const selectMessageThreadsMessages = (state: RootState): IAsyncData<IMessage[]> => {
    return state.messaging.threadMessages
}

export const selectMessageThreadsBasedOnSelectedTab = (state: RootState): IAsyncData<IMessageThread[]> => {
    if (state.messaging.selectedTab === 'messaging.tabAll' || state.messaging.selectedTab === undefined) {
        return state.messaging.messageThreads
    }
    const selectedMessageType =
        state.messaging.selectedTab === 'messaging.tabMobileMessages'
            ? EMessageType.MobileMessage
            : EMessageType.InfoMessage
    const data = state.messaging.messageThreads.data.filter((thread) => thread.Type === selectedMessageType)
    return { ...state.messaging.messageThreads, data }
}

export const selectReplyToThread = (state: RootState): IAsyncData<TDataSourceItemId[]> => {
    return state.messaging.replyToThread
}

const getDistictDefiningKey = (item: any) => {
    return isSelectedIdsEmployees(item)
        ? item.Id
        : isSelectedIdsMessages(item)
        ? item.EmployeeId
        : isSelectedIdsEvents(item)
        ? item.Id
        : 'unknown'
}

export const selectIsGroupMessaging = (state: RootState): boolean => {
    if (state.messaging.selectedIds) {
        if (state.messaging.selectedIds.length > 1) {
            return true
        }
    }
    return false
}

export const selectIsGroupUniqueMessaging = (state: RootState): boolean => {
    if (state.messaging.selectedIds) {
        const distinct = state.messaging.selectedIds
        const arrayUniqueByKey = [...new Map(distinct.map((item) => [getDistictDefiningKey(item), item])).values()]
        if (arrayUniqueByKey.length > 1) {
            return true
        }
    }
    return false
}

/**
 * selects title for messaging modal based on state
 * @param state
 * @returns
 */
export const selectMessagingModalTitle = (state: RootState): string => {
    if (state.messaging.viewedMessageThread?.Title !== undefined && state.messaging.showMessageThread) {
        return state.messaging.viewedMessageThread.Title
    }

    if (selectIsGroupMessaging(state) || selectShowNewMessageForm(state) || isNoEmployeeSelectedFromRow(state)) {
        return 'messaging.newMessage'
    }

    return 'messaging.messages'
}

/**
 * Select employee name depending on combination for employee selected to message for from row actions.
 * does not generate name when selectedItems array contains more than one item.
 * @param state
 * @returns string (Surname Lastname)
 */
export const selectSelectedEmployeeName = (state: RootState): string | undefined => {
    if (selectIsGroupMessaging(state)) {
        return undefined
    }
    return selectEmployeeName(state)
}

export const isNoEmployeeSelectedFromRow = (state: RootState): boolean => {
    return selectEmployeeName(state) === undefined
}

export const selectSelectedItemIds = (state: RootState): TDataSourceItemId[] => {
    return state.messaging.selectedIds?.map((item) => item.Id) ?? []
}

export const selectSelectedEventIds = (state: RootState): TDataSourceItemId[] => {
    return state.messaging.selectedEvents.map(({ Id }) => Id)
}

export const selectDropDownEmployees = (state: RootState): any => {
    return selectValuePickerValue(state, EMPLOYEE_VALUEPICKER)
}

export const selectSelectedEmployeeIds = (state: RootState): TDataSourceItemId[] => {
    const employeeIds = state.messaging.selectedIds?.map((item) => {
        return isSelectedIdsEmployees(item)
            ? item.Id
            : isSelectedIdsMessages(item)
            ? item.EmployeeId
            : // item is now IEvent thanks to typescript magic
              item.Id || ''
    })

    return compact(employeeIds || [])
}

export const selectSelectedThreadIds = (state: RootState): TDataSourceItemId[] | null => {
    const threadIds =
        state.messaging.selectedIds?.map((item) => (isSelectedIdsMessages(item) ? item.ThreadId : -999)) || []
    const filteredThreadIds = threadIds.filter((i) => i !== -999).filter(Boolean)
    return filteredThreadIds.length !== 0 ? filteredThreadIds : null
}

export const selectSelectedItems = (
    state: RootState
): IEmployeeDataSourceItem[] | IMessagingDataSourceItem[] | IEvent[] | undefined => {
    return state.messaging.selectedIds
}

export const selectSelectedEvents = (state: RootState): IEvent[] => {
    return state.messaging.selectedEvents
}

export const selectSummaryLabelsForSelectedIds = (state: RootState): ISummaryLabel[] => {
    if (!isEmpty(state.messaging.selectedEvents)) {
        if (isSelectedIdsEvents(state.messaging.selectedEvents[0])) {
            const startTime = moment(state.messaging.selectedEvents[0].StartTime).format('HH:mm')
            const endTime = moment(state.messaging.selectedEvents[0].EndTime).format('HH:mm')

            return [
                {
                    Icon: 'date_range',
                    Text: formatForDisplay(state.messaging.selectedEvents[0].StartTime as string),
                },
                {
                    Icon: 'access_time',
                    Text: `${startTime} - ${endTime}`,
                },
                {
                    Icon: 'place',
                    Text: state.messaging.selectedEvents[0].WorkUnit?.Name ?? '',
                },
                {
                    Icon: 'account_circle',
                    Text: state.messaging.selectedEvents[0].Employee?.Name ?? 'Ei työntekijää',
                },
            ]
        }
    }

    if (state.messaging.selectedIds && isSelectedIdsEmployees(state.messaging.selectedIds[0])) {
        return [
            {
                Icon: 'account_circle',
                Text: selectEmployeeName(state) || '',
            },
        ]
    }

    return []
}

export const selectCommentThreads = (state: RootState): IAsyncData<IMessageThread[]> => {
    return state.messaging.commentThreads
}

export const selectNewMessageForm = (state: RootState): INewMessageForm => {
    return state.messaging.newMessageForm
}

export const selectNewCommentForm = (state: RootState): INewCommentForm => {
    return state.messaging.newCommentForm
}

export const selectNewMessageFormMessage = (state: RootState): string | undefined => {
    return state.messaging.newMessageForm.message
}

export const selectNewMessageFormTitle = (state: RootState): string | undefined => {
    return state.messaging.newMessageForm.title
}

export const selectNewMessageFormMessageType = (state: RootState): EMessageType | undefined => {
    return state.messaging.newMessageForm.messageType
}

export const selectNewMessageFormSendEmail = (state: RootState): boolean => {
    return state.messaging.newMessageForm.sendEmail
}

export const selectSelectedTab = (state: RootState): string | undefined => {
    return state.messaging.selectedTab
}

export const selectNewCommentFormMessage = (state: RootState): string | undefined => {
    return state.messaging.newCommentForm.message
}

export const selectNewCommentFormIsVisibleForEmployee = (state: RootState): boolean => {
    return state.messaging.newCommentForm.isVisibleForEmployee
}

export const selectNewCommentFormIsVisibleForExternalUser = (state: RootState): boolean => {
    return state.messaging.newCommentForm.isVisibleForExternalUser
}

export const selectNewCommentFormIsLoading = (state: RootState): boolean => {
    return state.messaging.newCommentForm.isPosting
}

export const selectSelectedThread = ({ messaging }: RootState): IMessageThread | undefined => {
    const multipleIdsSelected = size(messaging.selectedIds) > 1

    if (messaging.messageThreads.isFetching || multipleIdsSelected) {
        return undefined
    }

    const threadId = messaging.selectedIds?.[0]?.Id
    return messaging.messageThreads.data.find(({ Id }) => Id === threadId)
}

export const selectIsThreadInitialized = ({ messaging }: RootState): boolean => messaging.threadInitialized

export const selectMessageRecipients = ({ messaging }: RootState): IEmployeeIdentifier[] => messaging.selectedRecipients
