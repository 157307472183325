import { lazy } from 'react'
import EValuePickerType from '../../types/EValuePickerType'

const SkillPicker = lazy(() => import('../../../value-picker-components/components/SkillPicker'))
const TextPicker = lazy(() => import('../../../value-picker-components/components/TextPicker'))
const TogglePicker = lazy(() => import('../../../value-picker-components/components/TogglePicker'))
const FilePicker = lazy(() => import('../../../value-picker-components/components/FilePicker'))
const SingleDayPicker = lazy(() => import('../../../value-picker-components/components/SingleDayPicker'))
const DynamicDataPicker = lazy(() => import('../../../value-picker-components/components/DynamicDataPicker'))
const ExternalIdentifierValuePicker = lazy(
    () => import('../../../value-picker-components/components/ExternalIdentifierValuePicker')
)
const NumberPicker = lazy(() => import('../../../value-picker-components/components/NumberPicker'))
const LinkPicker = lazy(() => import('../../../value-picker-components/components/LinkPicker'))
const TextSearch = lazy(() => import('../../../value-picker-components/components/TextSearchPicker/TextSearchPicker'))

export const valuePickerMap = {
    [EValuePickerType.Hidden]: null,
    [EValuePickerType.DynamicData]: DynamicDataPicker,
    [EValuePickerType.FilePicker]: FilePicker,
    [EValuePickerType.ExternalIdentifierValuePicker]: ExternalIdentifierValuePicker,
    [EValuePickerType.NumberPicker]: NumberPicker,
    [EValuePickerType.SingleDayPicker]: SingleDayPicker,
    [EValuePickerType.SkillPicker]: SkillPicker,
    [EValuePickerType.TextPicker]: TextPicker,
    [EValuePickerType.Toggle]: TogglePicker,
    [EValuePickerType.LinkPicker]: LinkPicker,
    [EValuePickerType.TextSearch]: TextSearch,
}

export type ValuePickerKey = keyof typeof valuePickerMap
