import { lazy } from 'react'
import PageDateRangePicker from './components/PageDateRangePicker'
import PageTimeRangePicker from './components/PageTimeRangePicker'
import ConfigurableListValuePicker from './components/ConfigurableListValuePicker'
import ValuePickerWithOptions, { ValuePickerWithOptionsWithoutStore } from '../value-picker-with-options'
import TextSearchPicker from './components/TextSearchPicker'
import TransactionPricePicker from './components/TransactionComponents/TransactionPricePicker'
import TransactionSalaryPicker from './components/TransactionComponents/TransactionSalaryPicker'
import CheckboxPicker from './components/CheckboxPicker'
import MultiDayPicker from './components/MultiDayPicker'
import TimePicker from './components/TimePicker'
import TimeRangePicker from './components/TimeRangePicker'
import DayPickerWithRangeAndWeekdays from './components/DayPickerWithRangeAndWeekDays'
import RepetitiveEventPicker from './components/RepetitiveEventPicker'
import ColorPicker from './components/ColorPicker'
import DropdownV3Picker from './components/DropdownV3Picker'
import DateRangeV2Picker from './components/DateRangeV2Picker'
import RadioButtonListValuePicker from './components/RadioButtonListValuePicker'

const SubPricePicker = lazy(() => import('./components/SubPricePicker'))

const AdditionalServicesPicker = lazy(() => import('./components/AdditionalServicesPicker/AdditionalServicesPicker'))
const EventReservationRestrictionsPicker = lazy(() => import('./components/EventReservationRestrictionsPicker'))
const ExternalIdentifierValuePicker = lazy(() => import('./components/ExternalIdentifierValuePicker'))

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getValuePickerComponents = () => ({
    PageDateRangePicker,
    PageTimeRangePicker,
    ConfigurableListValuePicker,
    ValuePickerWithOptions,
    ValuePickerWithOptionsWithoutStore,
    TextSearchPicker,
    AdditionalServicesPicker,
    CheckboxPicker,
    TimePicker,
    TimeRangePicker,
    DateRangeV2Picker,
    MultiDayPicker,
    DayPickerWithRangeAndWeekdays,
    TransactionPricePicker,
    TransactionSalaryPicker,
    RepetitiveEventPicker,
    ColorPicker,
    DropdownV3Picker,
    EventReservationRestrictionRulePicker: EventReservationRestrictionsPicker,
    ExternalIdentifierValuePicker,
    RadioButtonListValuePicker,
    SubPricePicker,
})
