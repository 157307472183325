import * as React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import moment, { Moment } from 'moment'
import { RootState } from 'typesafe-actions'

import DateRange from 'components/molecules/DateRange'
import { IPageDateRangePickerOwnProps, selectValuePickerValue, setValuePickerValueAction } from '../../../value-picker'
import { isNullableDateTimeRange, isNullableMoment } from '../../../generic-utilities'

export interface IPageDateRangePickerProps extends IPageDateRangePickerOwnProps {}

const PageDateRangePicker = ({ datePickerTestId, valuePickerId }: IPageDateRangePickerOwnProps): JSX.Element => {
    const dispatch = useDispatch()

    const pickerValue = useSelector((state: RootState) => selectValuePickerValue(state, valuePickerId))
    const { start: startDate, end: endDate } = isNullableDateTimeRange(pickerValue)
        ? pickerValue
        : {
              start: null,
              end: null,
          }

    const setValuePickerValue = (value: any) => dispatch(setValuePickerValueAction(value, valuePickerId))

    const handleEndDateChange = (newDate: Moment) => {
        if (!isNullableMoment(newDate)) {
            return
        }

        const formattedDate = moment.isMoment(newDate) ? newDate.endOf('day') : newDate

        setValuePickerValue({
            start: startDate,
            end: formattedDate,
        })
    }

    const handleStartDateChange = (newDate: Moment) => {
        if (!isNullableMoment(newDate)) {
            return
        }

        const formattedDate = moment.isMoment(newDate) ? newDate.startOf('day') : newDate

        setValuePickerValue({
            start: formattedDate,
            end: endDate,
        })
    }

    return (
        <DateRange
            endLabelText="end-date-short"
            endTime={endDate}
            noValidation
            onEndDateChange={handleEndDateChange}
            onStartDateChange={handleStartDateChange}
            startLabelText="start-date-short"
            startTime={startDate}
            dateRangeTestId={datePickerTestId}
        />
    )
}

export default PageDateRangePicker
