import { Checkbox } from '../../generic-components'
import { IValuePickerCommonOwnProps } from '../../value-picker'
import { getTestId } from '../../test'

import styled from '@emotion/styled'

type Props = IValuePickerCommonOwnProps<boolean> & {
    onChange: () => void
}

const Container = styled.div`
    display: flex;
    height: 40px;
`

const CheckboxPicker = ({ required, label, value, onChange, valuePickerId }: Props): JSX.Element => {
    const labelContent = required ? `${label ?? ''} *` : label ?? ''
    const testId = getTestId(['VALUE_PICKER'], valuePickerId)

    return (
        <Container data-testid={testId}>
            <Checkbox value={value} onClick={onChange} id={`checkbox-${label}`} label={labelContent} noPadding />
        </Container>
    )
}

export default CheckboxPicker
