import * as React from 'react'
import { JSX, useRef, useState } from 'react'
import MuiDialog from '@material-ui/core/Dialog'
import { useDispatch } from 'react-redux'

import { closeModalAction } from '../State/ModalActions'
import { FORM_HALF_WIDTH } from '../../form-view/Components/FormView/FormView'
import MovableDialog from '../../generic-components/MovableDialog'
import { getJSONItemFromLocalStorage } from '../../generic-utilities/StorageUtils'

interface IRenderButtons {
    (extraButtons?: React.ReactNode): React.ReactNode
}

interface IContentRenderer {
    (renderProps: { renderButtons: IRenderButtons }): React.ReactNode
}

export interface IModalBaseOwnProps {
    children: React.ReactNode | IContentRenderer
    /**
     * Function to close the modal. If not given, will close the modal
     * by its ID.
     */
    handleClose?: () => void
    /**
     * Callback run immediately when the modal has closed.
     */
    onClose?: () => void
    modalId: string
    isClosedOnEscapeDown?: boolean
}

const Modal = ({ children, handleClose, modalId, onClose }: IModalBaseOwnProps): JSX.Element => {
    // TODO: disable scrolling when open (conditional under props)
    const dispatch = useDispatch()
    const dialogRef = useRef<HTMLDivElement | null>(null)

    const initialPosition =
        getJSONItemFromLocalStorage<Record<string, { top: number; left: number }>>('dialogPosition')?.[modalId]

    const [position, setPosition] = useState<{ top?: number; left?: number }>(
        initialPosition || { top: 10, left: undefined }
    )

    const handleModalClose = () => {
        if (handleClose) {
            handleClose()
        } else {
            dispatch(closeModalAction(modalId))
        }

        onClose && onClose()
    }

    return (
        <MuiDialog
            transitionDuration={200}
            PaperProps={{
                style: {
                    position: 'absolute',
                    left: position.left,
                    top: position.top,
                    right: 10,
                    margin: 0,
                    maxWidth: FORM_HALF_WIDTH,
                    width: '100%',
                },
                ref: dialogRef,
            }}
            BackdropProps={{ style: { backgroundColor: 'transparent' } }}
            onClose={handleModalClose}
            open
        >
            <MovableDialog dialog={dialogRef.current} storageId={modalId} onPositionChange={setPosition}>
                {children}
            </MovableDialog>
        </MuiDialog>
    )
}

export default Modal
