import { TValuePickerConfiguration } from '../types/TValuePickerConfiguration'
import EValuePickerType from '../types/EValuePickerType'
import suunnittelujaksoValuePickers from './SuunnittelujaksoValuePickers'
import workforceSchedulingValuePickers from './WorkforceSchedulingValuePickers'
import tyontekijaAlueValuePickers from './TyontekijaAlueValuePickers'
import { alueValuePickerConfiguration } from './AlueValuePickerConfiguration'
import { maintenanceViewValuePickerConfigurations } from './MaintenanceViewValuePickerConfigurations'

const valuePickerConfigurations: TValuePickerConfiguration[] = [
    {
        ValuePickerId: 'role_picker',
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Kayttaja/Role/Identifier',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'RoleIds',
        Text: {
            FetchError: 'Roolien haku epäonnistui',
            Label: 'Roolit',
            MultipleSelected: 'roolia',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { InternalRoles: true },
        },
    },
    {
        ValuePickerId: 'role_filter',
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Kayttaja/Role/Identifier',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'RoleIds',
        Text: {
            FetchError: 'Roolien haku epäonnistui',
            Label: 'Roolit',
            MultipleSelected: 'roolia',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        ValuePickerId: 'section_filter',
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Kayttaja/RolePermissionSection/Identifier',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'Sections',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Osiot',
            MultipleSelected: 'osiota',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        ValuePickerId: 'external_role_picker',
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Kayttaja/Role/Identifier',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'RoleIds',
        Text: {
            FetchError: 'Roolien haku epäonnistui',
            Label: 'Roolit',
            MultipleSelected: 'roolia',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { InternalRoles: false },
        },
    },
    {
        ValuePickerId: 'area_picker',
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Alue/Identifier',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'alueIdt',
        Text: {
            FetchError: 'Alueiden haku epäonnistui',
            Label: 'Alueet',
            MultipleSelected: 'aluetta',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        ValuePickerId: 'area_picker_DependsOnAreaPicker',
        DependsOn: [{ ValuePickerId: 'area_picker', ParameterName: 'Ids' }],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Alue/Identifier',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'Ids',
        Text: {
            FetchError: 'Alueiden haku epäonnistui',
            Label: 'Alueet',
            MultipleSelected: 'aluetta',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        ValuePickerId: 'area_filter',
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Alue/Identifier',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'alueIdt',
        Text: {
            FetchError: 'Alueiden haku epäonnistui',
            Label: 'Alueet',
            MultipleSelected: 'aluetta',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        ValuePickerId: 'suitable_employee_area_filter',
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Alue/Identifier',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'OverridingAreaIds',
        Text: {
            FetchError: 'Alueiden haku epäonnistui',
            Label: 'Laajenna hakua alueille',
            MultipleSelected: 'aluetta',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyoaikatulkinta/TemplateSaanto/Tyyppi/Identifier',
        ValuePickerId: 'TemplateSaanto',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'TemplateSaantoId',
        Text: {
            FetchError: 'Sääntötyyppien haku epäonnistui',
            Label: 'Sääntötyyppi',
            MultipleSelected: 'sääntötyyppiä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyoaikatulkinta/MeterType/Identifier',
        ValuePickerId: 'MeterTypeIdentifier',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'MeterTypeId',
        Text: {
            FetchError: 'Laskurityyppien haku epäonnistui',
            Label: 'Laskurityyppi',
            MultipleSelected: 'laskurityyppiä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        ValuePickerType: EValuePickerType.RepetitiveEvent,
        DependsOn: [],
        DataRequestParameterName: 'EventRecurrenceParameters',
        ValuePickerId: 'RepetitiveEvent',
        DefaultValues: null,
    },
    {
        ValuePickerType: EValuePickerType.DynamicData,
        DependsOn: [],
        DataRequestParameterName: 'DynamicData',
        ValuePickerId: 'DynamicDataEmployee',
        ObjectType: 1,
        DefaultValues: null,
    },
    {
        ValuePickerType: EValuePickerType.DynamicData,
        DependsOn: [],
        DataRequestParameterName: 'DynamicData',
        ValuePickerId: 'DynamicDataEmployeeContract',
        ObjectType: 3,
        DefaultValues: null,
    },
    {
        ValuePickerType: EValuePickerType.DynamicData,
        DependsOn: [],
        DataRequestParameterName: 'DynamicData',
        ValuePickerId: 'DynamicDataWorkUnit',
        ObjectType: 2,
        DefaultValues: null,
    },
    {
        ValuePickerType: EValuePickerType.DynamicData,
        DependsOn: [],
        DataRequestParameterName: 'DynamicData',
        ValuePickerId: 'DynamicDataCustomer',
        ObjectType: 4,
        DefaultValues: null,
    },
    {
        ValuePickerType: EValuePickerType.DynamicData,
        DependsOn: [],
        DataRequestParameterName: 'DynamicData',
        ValuePickerId: 'DynamicDataPayGroup',
        ObjectType: 5,
        DefaultValues: null,
    },
    {
        ValuePickerType: EValuePickerType.DynamicData,
        DependsOn: [],
        DataRequestParameterName: 'DynamicData',
        ValuePickerId: 'DynamicDataUser',
        ObjectType: 6,
        DefaultValues: null,
    },
    {
        ValuePickerType: EValuePickerType.DynamicData,
        DependsOn: [],
        DataRequestParameterName: 'DynamicData',
        ValuePickerId: 'DynamicDataArea',
        ObjectType: 7,
        DefaultValues: null,
    },
    {
        ValuePickerType: EValuePickerType.DynamicData,
        DependsOn: [],
        DataRequestParameterName: 'DynamicData',
        ValuePickerId: 'DynamicDataCostCode',
        ObjectType: 8,
        DefaultValues: null,
    },
    {
        ValuePickerType: EValuePickerType.DynamicData,
        DependsOn: [],
        DataRequestParameterName: 'DynamicData',
        ValuePickerId: 'DynamicDataCompany',
        ObjectType: 9,
        DefaultValues: null,
    },
    {
        ValuePickerType: EValuePickerType.TextSearch,
        DependsOn: [],
        DataRequestParameterName: 'Vapaatekstihaku',
        DefaultValues: null,
        ValuePickerId: 'TextSearchValuePicker',
        Label: 'Hae',
    },
    {
        ValuePickerType: EValuePickerType.TextSearch,
        DependsOn: [],
        DataRequestParameterName: 'Vapaatekstihaku',
        DefaultValues: null,
        ValuePickerId: 'StaffingTextSearch',
        Label: 'Hae (ID, lisätiedot)',
    },
    {
        ValuePickerType: EValuePickerType.TextSearch,
        DependsOn: [],
        DataRequestParameterName: 'WorkUnitName',
        DefaultValues: null,
        ValuePickerId: 'WorkUnitName',
        Label: 'Hae (työkohteen nimi)',
    },
    {
        ValuePickerType: EValuePickerType.TextSearch,
        DependsOn: [],
        DataRequestParameterName: 'EmployeeStaffingInfoTextSearch',
        DefaultValues: null,
        ValuePickerId: 'SuitableEmployeeTextSearchValuePicker',
        Label: 'Hae',
    },
    {
        ValuePickerType: EValuePickerType.TimeRangePicker,
        DependsOn: [],
        DefaultValues: null,
        DataRequestParameterName: 'TimeRangeOutput',
        ValuePickerId: 'TimeRangePicker',
    },
    {
        ValuePickerType: EValuePickerType.TimeRangePicker,
        DependsOn: [],
        DefaultValues: null,
        DataRequestParameterName: 'TimeRangeOutput',
        ValuePickerId: 'TimeRangePickerWithout24h',
        valuePickerComponentProperties: {
            over24hHidden: true,
        },
    },
    {
        ValuePickerType: EValuePickerType.DayPickerWithRangeAndWeekdays,
        DependsOn: [],
        DefaultValues: null,
        DataRequestParameterName: 'DayPickerWithRangeAndWeekdaysOutput',
        ValuePickerId: 'DayPickerWithRangeAndWeekdays',
    },
    {
        ValuePickerType: EValuePickerType.TimePicker,
        DependsOn: [],
        DefaultValues: null,
        DataRequestParameterName: 'TimeOutput',
        ValuePickerId: 'TimePicker',
    },
    {
        ValuePickerType: EValuePickerType.FilePicker,
        DependsOn: [],
        DataRequestParameterName: 'File',
        ValuePickerId: 'FilePicker',
    },
    {
        ValuePickerType: EValuePickerType.FilePicker,
        DependsOn: [],
        DataRequestParameterName: 'File',
        ValuePickerId: 'ImageFilePicker',
        DisplayType: 'image',
    },
    {
        ValuePickerType: EValuePickerType.SkillPicker,
        DependsOn: [],
        DataRequestParameterName: 'Skills',
        ValuePickerId: 'SkillPicker',
    },
    {
        ValuePickerType: EValuePickerType.ExternalIdentifierValuePicker,
        DependsOn: [],
        DataRequestParameterName: 'ExternalIdentifiers',
        ValuePickerId: 'ExternalIdentifierValuePicker',
    },
    {
        ValuePickerType: EValuePickerType.TransactionPricePicker,
        DataRequestParameterName: 'TransactionPrice',
        DependsOn: [],
        Label: 'Laskutushinta',
        DefaultValues: null,
        ValuePickerId: 'TransactionPrice',
        dependencyConfiguration: {},
    },
    {
        ValuePickerType: EValuePickerType.TransactionSalaryPicker,
        DataRequestParameterName: 'TransactionSalary',
        ValuePickerId: 'TransactionSalary',
        DependsOn: [],
        DefaultValues: null,
        dependencyConfiguration: {},
    },
    {
        ValuePickerType: EValuePickerType.ColorPicker,
        DataRequestParameterName: 'ColorPicker',
        ValuePickerId: 'ColorPicker',
        DependsOn: [],
        DefaultValues: null,
    },
    {
        ValuePickerType: EValuePickerType.AdditionalServicesPicker,
        DependsOn: [],
        DefaultValues: null,
        DataRequestParameterName: 'AdditionalServices',
        ValuePickerId: 'AdditionalServices',
        Label: 'Lisätiedot',
    },
    {
        ValuePickerType: EValuePickerType.TextPicker,
        DependsOn: [],
        DataRequestParameterName: 'TextOutput',
        DefaultValues: null,
        ValuePickerId: 'TextPicker',
    },
    {
        ValuePickerType: EValuePickerType.TextPicker,
        DependsOn: [],
        DataRequestParameterName: 'TextOutput',
        DefaultValues: null,
        Multiline: true,
        ValuePickerId: 'MultilineTextPicker',
        valuePickerComponentProperties: {
            multiline: true,
        },
    },
    {
        ValuePickerType: EValuePickerType.CheckboxPicker,
        DependsOn: [],
        DataRequestParameterName: 'BooleanOutput',
        DefaultValues: false,
        ValuePickerId: 'CheckboxPicker',
    },
    {
        ValuePickerType: EValuePickerType.CheckboxPicker,
        DependsOn: [],
        DataRequestParameterName: 'BooleanOutput',
        DefaultValues: null,
        ValuePickerId: 'NullableCheckboxPicker',
    },
    {
        ValuePickerType: EValuePickerType.NumberPicker,
        DependsOn: [],
        DataRequestParameterName: 'NumberOutput',
        DefaultValues: null,
        ValuePickerId: 'NumberPicker',
    },
    {
        ValuePickerType: EValuePickerType.SingleDayPicker,
        DependsOn: [],
        DataRequestParameterName: 'SingleDayPicker',
        DefaultValues: null,
        ValuePickerId: 'SingleDayPicker',
    },
    {
        ValuePickerType: EValuePickerType.SingleDayPicker,
        DependsOn: [],
        DataRequestParameterName: 'ContractEndDate',
        DefaultValues: null,
        ValuePickerId: 'SingleDayPicker_ContractEndDate',
        TrueValueSideEffects: [
            {
                key: 'ContractTerminationReason',
                value: 'require',
            },
        ],
        FalseValueSideEffects: [
            {
                key: 'ContractTerminationReason',
                value: 'hide',
            },
        ],
    },
    {
        ValuePickerType: EValuePickerType.MultiDayPicker,
        DependsOn: [],
        DataRequestParameterName: 'MultiDayPicker',
        DefaultValues: null,
        ValuePickerId: 'MultiDayPicker',
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'TogglePicker',
        DefaultValues: false,
        ValuePickerId: 'TogglePicker',
        Label: 'TogglePicker',
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'TogglePicker',
        DefaultValues: true,
        ValuePickerId: 'TogglePickerDefaultTrue',
        Label: 'TogglePicker',
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'AddContractInformation',
        DefaultValues: true,
        ValuePickerId: 'AddContractInformation_TogglePicker',
        Label: 'Lisää sopimustiedot',
        TrueValueSideEffects: [
            {
                key: 'AddContractInformation_Required',
                value: 'require',
            },
        ],
        FalseValueSideEffects: [
            {
                key: 'AddContractInformation',
                value: 'hide',
            },
        ],
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'HasTrialPeriod',
        DefaultValues: false,
        ValuePickerId: 'HasTrialPeriod_TogglePicker',
        Label: 'Koeaika',
        TrueValueSideEffects: [
            {
                key: 'TrialPeriodEndDate',
                value: 'require',
            },
        ],
        FalseValueSideEffects: [
            {
                key: 'TrialPeriodEndDate',
                value: 'hide',
            },
        ],
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'IsExternalUser',
        DefaultValues: false,
        ValuePickerId: 'IsExternalUser_TogglePicker',
        Label: 'Ulkoinen käyttäjä',
        TrueValueSideEffects: [
            {
                key: 'InternalRolePicker',
                value: 'hide',
            },
            {
                key: 'ExternalRolePicker',
                value: 'require',
            },
            {
                key: 'OnlyForAzureADSignIn',
                value: 'hide',
            },
            {
                key: 'ExternalUserFields',
                value: 'show',
            },
        ],
        FalseValueSideEffects: [
            {
                key: 'ExternalRolePicker',
                value: 'hide',
            },
            {
                key: 'InternalRolePicker',
                value: 'require',
            },
            {
                key: 'ExternalUserFields',
                value: 'hide',
            },
        ],
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'IsBICManual',
        DefaultValues: false,
        ValuePickerId: 'IsBICManual',
        Label: 'Aseta BIC käsin',
        TrueValueSideEffects: [
            {
                key: 'ManualBIC',
                value: 'require',
            },
        ],
        FalseValueSideEffects: [
            {
                key: 'ManualBIC',
                value: 'hide',
            },
        ],
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'IsManualOffering',
        DefaultValues: false,
        ValuePickerId: 'IsManualOffering',
        Icon: { enabled: 'lock', disabled: 'no_encryption' },
        Label: 'Manuaalinen tarjoaminen',
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'IsManualOffering',
        DefaultValues: false,
        ValuePickerId: 'IsManualOffering_DefaultTrue',
        Icon: { enabled: 'lock', disabled: 'no_encryption' },
        Label: 'Manuaalinen tarjoaminen',
        DefaultValues: true,
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'IsOralConfirmation',
        DefaultValues: false,
        ValuePickerId: 'IsOralConfirmation',
        Label: 'Vahvistettu suullisesti',
        TrueValueSideEffects: [
            {
                key: 'OralConfirmation',
                value: 'show',
            },
            {
                key: 'NormalConfirmation',
                value: 'hide',
            },
        ],
        FalseValueSideEffects: [
            {
                key: 'OralConfirmation',
                value: 'hide',
            },
            {
                key: 'NormalConfirmation',
                value: 'show',
            },
        ],
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'RepetitiveEventToggle',
        DefaultValues: false,
        ValuePickerId: 'RepetitiveEventToggle',
        Label: 'Toistuva tapahtuma',
        TrueValueSideEffects: [
            {
                key: 'Paivat',
                value: 'hide',
            },
            {
                key: 'RepetitiveEvent',
                value: 'require',
            },
        ],
        FalseValueSideEffects: [],
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'CheckPoolFilter',
        DefaultValues: true,
        ValuePickerId: 'CheckPoolFilter',
        Label: 'Vain pooliin kuuluvat',
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'RequireSkills',
        DefaultValues: true,
        ValuePickerId: 'RequireSkills',
        Label: 'Vaadi erikoistaito',
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'CheckJobTitleSuitability',
        DefaultValues: true,
        ValuePickerId: 'CheckJobTitleSuitability',
        Label: 'Tarkista ammattinimike',
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'PublishedTimesDifferFromActual',
        DefaultValues: false,
        ValuePickerId: 'PublishedTimesDifferFromActual',
        Label: 'Toteutuneet ajat poikkeavat julkaistuista',
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'GetDeletedEventsAsWell',
        DefaultValues: false,
        ValuePickerId: 'IncludeDeletedEvents',
        Label: 'Näytä myös poistetut',
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'IsFlexitimeEnabled',
        DefaultValues: false,
        ValuePickerId: 'IsFlexitimeEnabledToggle',
        Label: 'Liukuva työaika käytössä',
        TrueValueSideEffects: [
            {
                key: 'WorktimeTransactionValue',
                value: 'require',
            },
        ],
        FalseValueSideEffects: [
            {
                key: 'WorktimeTransactionValue',
                value: 'hide',
            },
        ],
    },
    {
        DependsOn: [],
        ValuePickerId: 'InboundTransferTypeIds_HiddenValuePicker',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: 'TransferTypeIds',
        DefaultValues: ['IncomingEmployeeTransfer'],
    },
    {
        DependsOn: [],
        ValuePickerId: 'TransferHasAutomaticTrigger_HiddenValuePicker',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: 'HasAutomaticTrigger',
        DefaultValues: false,
    },
    {
        DependsOn: [],
        ValuePickerId: 'alkuPvmVali',
        ValuePickerType: EValuePickerType.DateRange,
        DataRequestParameterName: 'AikavaliAlkuaika',
        DefaultValues: {
            StartFromCurrentDay: -1,
            StartFromCurrentDayUnit: 'year',
            EndFromCurrentDay: 0,
            EndFromCurrentDayUnit: 'year',
        },
    },
    {
        DependsOn: [],
        ValuePickerId: 'dateRange_transferBatches',
        ValuePickerType: EValuePickerType.DateRange,
        DataRequestParameterName: 'OverlapsDateTimeRange',
        DefaultValues: {
            StartFromCurrentDay: -1,
            StartFromCurrentDayUnit: 'month',
            EndFromCurrentDay: null,
            EndFromCurrentDayUnit: null,
        },
    },
    {
        DependsOn: [],
        ValuePickerId: 'OverlapsDateRange',
        ValuePickerType: EValuePickerType.DateRange,
        DataRequestParameterName: 'OverlapsDateRange',
        DefaultValues: {
            StartFromCurrentDay: 0,
            StartFromCurrentDayUnit: null,
            EndFromCurrentDay: null,
            EndFromCurrentDayUnit: null,
        },
    },
    {
        DependsOn: [],
        ValuePickerId: 'DateRange',
        ValuePickerType: EValuePickerType.DateRange,
        DataRequestParameterName: 'DateRange',
        DefaultValues: {
            StartFromCurrentDay: null,
            StartFromCurrentDayUnit: null,
            EndFromCurrentDay: null,
            EndFromCurrentDayUnit: null,
        },
    },
    alueValuePickerConfiguration,
    {
        DependsOn: [
            { ValuePickerId: 'area', ParameterName: 'alueIdt' },
            { ValuePickerId: 'organization', ParameterName: 'organisaatioAsiakasIdt' },
        ],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/Identifier',
        ValuePickerId: 'workunit',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'asiakasIdt',
        Text: {
            FetchError: 'Työkohteiden haku epäonnistui',
            Label: 'Työkohteet',
            MultipleSelected: 'työkohdetta',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 400,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [
            { ValuePickerId: 'area', ParameterName: 'alueIdt' },
            { ValuePickerId: 'organization', ParameterName: 'organisaatioAsiakasIdt' },
        ],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/Identifier',
        ValuePickerId: 'customerEventsAllowed',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'asiakasIdt',
        Text: {
            FetchError: 'Työkohteiden haku epäonnistui',
            Label: 'Työkohteet',
            MultipleSelected: 'työkohdetta',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 400,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [
            { ValuePickerId: 'area', ParameterName: 'alueIdt' },
            { ValuePickerId: 'organization', ParameterName: 'organisaatioAsiakasIdt' },
        ],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/Identifier',
        ValuePickerId: 'customer',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'asiakasIdt',
        Text: {
            FetchError: 'Työkohteiden haku epäonnistui',
            Label: 'Työkohteet',
            MultipleSelected: 'työkohdetta',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 400,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [
            { ValuePickerId: 'area', ParameterName: 'alueIdt' },
            { ValuePickerId: 'organization', ParameterName: 'organisaatioAsiakasIdt' },
            { ValuePickerId: 'WorkUnitGroup_Filter', ParameterName: 'WorkUnitGroupIds' },
            { ValuePickerId: 'WorkUnitGroupTopLevel_Filter', ParameterName: 'WorkUnitGroupIds' },
        ],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/Identifier',
        ValuePickerId: 'workUnit_filter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'WorkUnitIds',
        Text: {
            FetchError: 'Työkohteiden haku epäonnistui',
            Label: 'Työkohteet',
            MultipleSelected: 'työkohdetta',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 400,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/Identifier',
        ValuePickerId: 'workUnit_filter_no_dependencies',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'WorkUnitIds',
        Text: {
            FetchError: 'Työkohteiden haku epäonnistui',
            Label: 'Työkohteet',
            MultipleSelected: 'työkohdetta',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 400,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/Identifier',
        ValuePickerId: 'organization',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'organisaatioAsiakasIdt',
        Text: {
            FetchError: 'Organisaatioiden haku epäonnistui',
            Label: 'Organisaatiot',
            MultipleSelected: 'organisaatiota',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 400,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [
            { ValuePickerId: 'area', ParameterName: 'alueIdt' },
            { ValuePickerId: 'organizationHasChildren', ParameterName: 'organisaatioAsiakasIdt' },
            { ValuePickerId: 'WorkUnitGroup_Filter', ParameterName: 'WorkUnitGroupIds' },
        ],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/Identifier',
        ValuePickerId: 'customerEventsAllowed',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'asiakasIdt',
        Text: {
            FetchError: 'Työkohteiden haku epäonnistui',
            Label: 'Työkohteet',
            MultipleSelected: 'työkohdetta',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 400,
        ExtraRows: 'Omit',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { GetOnlyWorkUnitsWhereEventsAreAllowed: true },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/Identifier',
        ValuePickerId: 'organizationHasChildren',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'organisaatioAsiakasIdt',
        Text: {
            FetchError: 'Organisaatioiden haku epäonnistui',
            Label: 'Organisaatiot',
            MultipleSelected: 'organisaatiota',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 400,
        ExtraRows: 'Omit',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { GetOnlyWorkUnitsWhichHaveChildren: true },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/WorkUnitIndustry/Identifier',
        ValuePickerId: 'WorkUnitIndustry',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'WorkUnitIndustryIds',
        Text: {
            FetchError: 'Työkohteiden toimialojen haku epäonnistui',
            Label: 'Työkohteiden toimialat',
            MultipleSelected: 'työkohteen toimialaa',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/WorkUnitIndustry/Identifier',
        ValuePickerId: 'WorkUnitIndustry_Picker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'WorkUnitIndustryIds',
        Text: {
            FetchError: 'Työkohteiden toimialojen haku epäonnistui',
            Label: 'Työkohteiden toimialat',
            MultipleSelected: 'työkohteen toimialaa',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [{ ValuePickerId: 'area', ParameterName: 'alueIdt' }],
        ItemOptionLabelFields: ['DisplayId', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Identifier',
        ValuePickerId: 'employee',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'tyontekijaIdt',
        Text: {
            FetchError: 'Työntekijöiden haku epäonnistui',
            Label: 'Työntekijät',
            MultipleSelected: 'työntekijää',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 400,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tapahtuma/ProcessingRuleType/Identifier',
        ValuePickerId: 'ProcessingRuleType',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'ProcessingRuleTypeIds',
        Text: {
            FetchError: 'Käsittelysääntötyyppien haku epäonnistui',
            Label: 'Käsittelysääntötyypit',
            MultipleSelected: 'käsittelysääntötyyppiä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: 'v2/Tapahtuma/WorkTimeTransactionTarget/Identifier',
        ValuePickerId: 'WorkTimeTransactionTarget_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'WorkTimeTransactionTargetIds',
        Text: {
            FetchError: 'Työaikalaskentakohteiden haku epäonnistui',
            Label: 'Työaikalaskentakohde',
            MultipleSelected: 'työaikalaskentakohdetta',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: 'v2/Tapahtuma/WorkTimeTransactionValueType/Identifier',
        ValuePickerId: 'WorkTimeTransactionValueTypeId_ValuePicker',
        ValuePickerType: EValuePickerType.RadioButtonListValuePicker,
        DataRequestParameterName: 'WorkTimeTransactionValueTypeId',
        Text: {
            FetchError: 'Työaikalaskentakohteiden arvotyyppien haku epäonnistui',
            Label: 'Työaikalaskentakohteen arvotyyppi',
            MultipleSelected: 'työaikalaskentakohteen arvotyyppiä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['DisplayId', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Identifier',
        ValuePickerId: 'contract_employee',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'tyontekijaIdt',
        Text: {
            FetchError: 'Työntekijöiden haku epäonnistui',
            Label: 'Työntekijät',
            MultipleSelected: 'työntekijää',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 400,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['DisplayId', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Kayttaja/Identifier',
        ValuePickerId: 'messaging_loginid',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'LoginIds',
        Text: {
            FetchError: 'Käyttäjien haku epäonnistui',
            Label: 'Käyttäjä',
            MultipleSelected: 'käyttäjää',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 400,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['DisplayId', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Identifier',
        ValuePickerId: 'attachment_employee',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'EmployeeIds',
        Text: {
            FetchError: 'Työntekijöiden haku epäonnistui',
            Label: 'Työntekijät',
            MultipleSelected: 'työntekijää',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 400,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['DisplayId', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Identifier',
        ValuePickerId: 'employee_filter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'EmployeeIds',
        Text: {
            FetchError: 'Työntekijöiden haku epäonnistui',
            Label: 'Työntekijät',
            MultipleSelected: 'työntekijää',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 400,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [
            {
                ValuePickerId: 'pool_filter',
                ParameterName: 'PooliIdt.IdList',
                AdditionalParams: { 'PooliIdt.FilterType': 'Any' },
            },
            {
                ValuePickerId: 'area_filter',
                ParameterName: 'alueIdt',
            },
        ],
        ItemOptionLabelFields: ['DisplayId', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Identifier',
        ValuePickerId: 'employee_filter_dependsOnPoolFilter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'EmployeeIds',
        Text: {
            FetchError: 'Työntekijöiden haku epäonnistui',
            Label: 'Työntekijät',
            MultipleSelected: 'työntekijää',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 400,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['DisplayId', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Identifier',
        ValuePickerId: 'EmployeeWorkTimeTransactions_Employee',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'EmployeeIds',
        Text: {
            FetchError: 'Työntekijöiden haku epäonnistui',
            Label: 'Työntekijät',
            MultipleSelected: 'työntekijää',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 400,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ValuePickerId: 'EventReservationRestrictionRulePicker',
        ValuePickerType: EValuePickerType.EventReservationRestrictionRulePicker,
        DataRequestParameterName: 'EventReservationRestrictionRules',
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/v2/Tapahtuma/Tyyppi/Identifier',
        ValuePickerId: 'tapahtumatyyppi', // Used as filter for processable events
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'tapahtumatyyppiIdt',
        Text: {
            FetchError: 'Tapahtumatyyppien haku epäonnistui',
            Label: 'Tapahtumatyypit',
            MultipleSelected: 'tapahtumatyyppiä',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { ProcessableEventTypesOnly: true },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Palkka/Tyyppi/Identifier',
        ValuePickerId: 'wageTypes',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PalkkatyyppiIdt',
        Text: {
            FetchError: 'Palkkatyyppien haku epäonnistui',
            Label: 'Palkkatyypit',
            MultipleSelected: 'palkkatyyppiä',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { GetNoneIdentifier: true },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Palkka/Tyyppi/Identifier',
        ValuePickerId: 'PayType_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PayTypeId',
        Text: {
            FetchError: 'Palkkatyyppien haku epäonnistui',
            Label: 'Palkkatyyppi',
            MultipleSelected: 'palkkatyyppiä',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        //PL-1187
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Palkka/WageRaiseType/Identifier',
        ValuePickerId: 'WageRaiseType',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'WageRaiseTypeId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Korotustapa',
            MultipleSelected: 'valittua',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: 1,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Tyosuhde/PayGroup/Identifier',
        ValuePickerId: 'PayGroup_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PayGroupId',
        Text: {
            FetchError: 'Maksuryhmien haku epäonnistui',
            Label: 'Maksuryhmä',
            MultipleSelected: 'maksuryhmää',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Tyosuhde/PayGroup/Identifier',
        ValuePickerId: 'PayGroup_Filter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PayGroupIds',
        Text: {
            FetchError: 'Maksuryhmien haku epäonnistui',
            Label: 'Maksuryhmä',
            MultipleSelected: 'maksuryhmää',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Tyosuhde/PayGroup/Identifier',
        ValuePickerId: 'PayGroup_Filter_With_NoPaygroupSelectedValue',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PayGroupIds',
        Text: {
            FetchError: 'Maksuryhmien haku epäonnistui',
            Label: 'Maksuryhmä',
            MultipleSelected: 'maksuryhmää',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            ShowNoPayGroupSelection: true,
        },
    },
    {
        DependsOn: [],
        ValuePickerId: 'startTime',
        ValuePickerType: EValuePickerType.TimeRange,
        DataRequestParameterName: 'alkukellonaika',
        Text: {
            EndPlaceholder: 'Loppu',
            StartPlaceholder: 'Alku',
            Label: 'Alkuaika välillä',
        },
        DefaultValues: null,
    },
    {
        DependsOn: [
            {
                ValuePickerId: 'customer',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
        ],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tes/Identifier',
        ValuePickerId: 'tes',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'tesIdt',
        Text: {
            FetchError: 'Tessien haku epäonnistui',
            Label: 'TES:t',
            MultipleSelected: 'tessiä',
        },
        TextSearchProps: ['Id', 'Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tes/Identifier',
        ValuePickerId: 'cla_filter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'CollectiveAgreementIds',
        Text: {
            FetchError: 'Tessien haku epäonnistui',
            Label: 'TES:t',
            MultipleSelected: 'tessiä',
        },
        TextSearchProps: ['Id', 'Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Ammattinimike/Toimiala/Identifier',
        ValuePickerId: 'industry',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'toimialaIdt',
        Text: { FetchError: 'Toimialojen haku epäonnistui', Label: 'Toimialat', MultipleSelected: 'toimialaa' },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Ammattinimike/Toimiala/Identifier',
        ValuePickerId: 'industry_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'toimialaIdt',
        Text: { FetchError: 'Toimialojen haku epäonnistui', Label: 'Toimialat', MultipleSelected: 'toimialaa' },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/v2/Tapahtuma/Hakutyyppi/Identifier',
        ValuePickerId: 'Tapahtumahakutyyppi',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'Tapahtumahakutyyppi',
        Text: {
            FetchError: 'Tapahtumahakutyyppien haku epäonnistui',
            Label: 'Tapahtumahakutyyppi',
            MultipleSelected: 'Tapahtumahakutyyppi',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/v2/Tapahtuma/EventPreferenceFilterType/Identifier',
        ValuePickerId: 'EventPreferenceFilters',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'EventPreferenceFilters',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Käytettävyys',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [
            { ValuePickerId: 'area', ParameterName: 'alueIdt' },
            {
                ValuePickerId: 'customer',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
            { ValuePickerId: 'tes', ParameterName: 'tesIdt.IdList', AdditionalParams: { 'tesIdt.FilterType': 'Any' } },
            {
                ValuePickerId: 'industry',
                ParameterName: 'toimialaIdt',
            },
        ],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Ammattinimike/Identifier',
        ValuePickerId: 'ammattinimike',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'ammattinimikeIdt',
        Text: {
            FetchError: 'Ammattinimikkeiden haku epäonnistui',
            Label: 'Ammattinimikkeet',
            MultipleSelected: 'ammattinimikettä',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Ammattinimike/Identifier',
        ValuePickerId: 'jobTitle_filter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'JobTitleIds',
        Text: {
            FetchError: 'Ammattinimikkeiden haku epäonnistui',
            Label: 'Ammattinimikkeet',
            MultipleSelected: 'ammattinimikettä',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [
            {
                ValuePickerId: 'customer',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
        ],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/Projektinumero/Identifier',
        ValuePickerId: 'projectNumber',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'projektinumeroIdt',
        Text: {
            FetchError: 'Projektinumeroiden haku epäonnistui',
            Label: 'Projektinumerot',
            MultipleSelected: 'projektinumeroa',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [
            {
                ValuePickerId: 'FormView_ValuePicker_Asiakas_With_Link',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'All' },
            },
            {
                ValuePickerId: 'FormView_ValuePicker_Asiakas_With_Link_BaronaHybrid',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'All' },
            },
            {
                ValuePickerId: 'FormView_ValuePicker_Asiakas',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'All' },
            },
            {
                ValuePickerId: 'FormView_ValuePicker_WorkUnit',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'All' },
            },
            {
                ValuePickerId: 'WorkforceScheduling_Event_Form_WorkUnit',
                ParameterName: 'workUnitIds',
            },
        ],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/Projektinumero/Identifier',
        ValuePickerId: 'Project_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'projektinumeroIdt',
        Text: {
            FetchError: 'Projektien haku epäonnistui',
            Label: 'Projekti',
            MultipleSelected: 'projektinumeroa',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { ExplicitWorkUnitsOnly: true },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Kayttaja/EmployeeSupervisor/Identifier',
        ValuePickerId: 'employeeSupervisor_Filter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'EmployeeSupervisorLoginIds',
        Text: {
            FetchError: 'Vastuuhenkilöiden haku epäonnistui',
            Label: 'Työntekijöiden vastuuhenkilöt',
            MultipleSelected: 'työntekijöiden vastuuhenkilöä',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Kayttaja/EmployeeSupervisor/Identifier',
        ValuePickerId: 'esimies',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'EmployeeSupervisorLoginIds',
        Text: {
            FetchError: 'Vastuuhenkilöiden haku epäonnistui',
            Label: 'Työntekijöiden vastuuhenkilöt',
            MultipleSelected: 'työntekijöiden vastuuhenkilöä',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Kayttaja/Identifier',
        ValuePickerId: 'PersonInCharge_Filter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PersonsInChargeLoginIds',
        Text: {
            FetchError: 'Vastuuhenkilöiden haku epäonnistui',
            Label: 'Työkohteen vastuuhenkilöt',
            MultipleSelected: 'työkohteen vastuuhenkilöä',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { IsExternalUser: false },
        },
    },
    {
        DependsOn: [],
        IdentifierUrl: '/Kayttaja/Identifier',
        ValuePickerId: 'PersonInCharge_Filter_V3',
        Label: 'Työkohteen vastuuhenkilö',
        OptionsEndpointUrl: '/Kayttaja/Identifier',
        ValuePickerType: EValuePickerType.DropdownV3,
        DataRequestParameterName: 'PersonsInChargeLoginIds',
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Text: {
            FetchError: 'Vastuuhenkilöiden haku epäonnistui',
            Label: 'Työkohteen vastuuhenkilöt',
            MultipleSelected: 'työkohteen vastuuhenkilöä',
        },

        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { IsExternalUser: false },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Kayttaja/Identifier',
        ValuePickerId: 'PersonInCharge_Picker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PersonsInChargeLoginId',
        Text: {
            FetchError: 'Vastuuhenkilöiden haku epäonnistui',
            Label: 'Työkohteen vastuuhenkilö',
            MultipleSelected: 'työkohteen vastuuhenkilöä',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Common/Weekday/Identifier',
        ValuePickerId: 'weekday',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'viikonpaivat',
        Text: {
            FetchError: 'Viikonpäivien haku epäonnistui',
            Label: 'Viikonpäivät',
            MultipleSelected: 'viikonpäivää',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Tyosuhde/Tyosopimustyyppi/Identifier',
        ValuePickerId: 'employmentContractType',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'tyosopimustyyppiIdt',
        Text: {
            FetchError: 'Työsopimustyyppien haku epäonnistui',
            Label: 'Työsopimustyypit',
            MultipleSelected: 'työsopimustyyppiä',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { IncludeNoneIdentifier: true },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Tyosuhde/Tyosopimustyyppi/Identifier',
        ValuePickerId: 'ContractType_Filter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'ContractTypeIds',
        Text: {
            FetchError: 'Työsopimustyyppien haku epäonnistui',
            Label: 'Työsopimustyypit',
            MultipleSelected: 'työsopimustyyppiä',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { IncludeNoneIdentifier: true },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tapahtuma/Kasittelysaantostatus/Identifier',
        ValuePickerId: 'eventHandlingStatus',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'kasittelysaantostatukset',
        Text: {
            FetchError: 'Käsittelysääntöstatusten haku epäonnistui',
            Label: 'Käsittelysääntöstatukset',
            MultipleSelected: 'käsittelysääntöstatusta',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Toteuma/Lisatietostatus/Identifier',
        ValuePickerId: 'transactionRowInfoStatus',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'Lisatietostatukset',
        Text: {
            FetchError: 'Lisätietostatusten haku epäonnistui',
            Label: 'Lisätietostatukset',
            MultipleSelected: 'lisätietostatusta',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/Identifier',
        ValuePickerId: 'FormView_ValuePicker_WorkUnit',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'asiakasIdt',
        Text: {
            FetchError: 'Työkohteiden haku epäonnistui',
            Label: 'Työkohde',
            MultipleSelected: 'työkohdetta',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: null,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/Identifier',
        ValuePickerId: 'FormView_ValuePicker_WorkUnit_No_Dependencies',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'asiakasIdt',
        Text: {
            FetchError: 'Työkohteiden haku epäonnistui',
            Label: 'Työkohde',
            MultipleSelected: 'työkohdetta',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: null,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Tyosuhde/Tyosuhdetyyppi/Identifier',
        ValuePickerId: 'employmentType',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'tyosuhdetyyppiIdt',
        Text: {
            FetchError: 'Työsuhdemuotojen haku epäonnistui',
            Label: 'Työsuhdemuodot',
            MultipleSelected: 'työsuhdemuotoa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Tyosuhde/Tyosuhdetyyppi/Identifier',
        ValuePickerId: 'employmentType_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'tyosuhdetyyppiIdt',
        Text: {
            FetchError: 'Työsuhdemuotojen haku epäonnistui',
            Label: 'Työsuhdemuodot',
            MultipleSelected: 'työsuhdemuotoa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tapahtuma/Lisatietostatus/Identifier',
        ValuePickerId: 'additionalInformation',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'lisatietoStatukset',
        Text: {
            FetchError: 'Lisätietovaihtoehtojen haku epäonnistui',
            Label: 'Lisätiedot',
            MultipleSelected: 'lisätietovaihtoehtoa',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Lounas/Identifier',
        ValuePickerId: 'mealBreakFilter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'mealBreakIds',
        Text: {
            FetchError: 'Ruokataukojen haku epäonnistui',
            Label: 'Ruokatauko',
            MultipleSelected: 'ruokataukoa',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { IncludeDefaultValue: true },
        },
    },
    {
        DependsOn: [
            { ValuePickerId: 'FormView_ValuePicker_Asiakas', ParameterName: 'AsiakasIdt' },
            { ValuePickerId: 'FormView_ValuePicker_Asiakas_With_Link', ParameterName: 'AsiakasIdt' },
            { ValuePickerId: 'FormView_ValuePicker_Asiakas_With_Link_BaronaHybrid', ParameterName: 'AsiakasIdt' },
            { ValuePickerId: 'WorkforceScheduling_Event_Form_WorkUnit', ParameterName: 'AsiakasIdt' },
            { ValuePickerId: 'FormView_ValuePicker_Ammattinimike', ParameterName: 'AmmattinimikeIdt' },
            { ValuePickerId: 'WorkforceScheduling_Event_Form_JobTitle', ParameterName: 'AmmattinimikeIdt' },
            {
                ValuePickerId: 'FormView_ValuePicker_Tes',
                ParameterName: 'TesIdt.IdList',
                AdditionalParams: { 'TesIdt.FilterType': 'All' },
            },
        ],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Lounas/Identifier',
        ValuePickerId: 'MealBreak_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'mealBreakIds',
        Text: {
            FetchError: 'Ruokataukojen haku epäonnistui',
            Label: 'Ruokatauko',
            MultipleSelected: 'ruokataukoa',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: -1,
        AdditionalDataFetchParameters: {
            Filters: { IncludeDefaultValue: true },
        },
    },
    {
        DependsOn: [
            { ValuePickerId: 'FormView_ValuePicker_Asiakas_With_Link', ParameterName: 'AsiakasIdt' },
            { ValuePickerId: 'FormView_ValuePicker_Asiakas_With_Link_BaronaHybrid', ParameterName: 'AsiakasIdt' },
            { ValuePickerId: 'FormView_ValuePicker_Asiakas', ParameterName: 'AsiakasIdt' },
            { ValuePickerId: 'FormView_ValuePicker_Ammattinimike', ParameterName: 'AmmattinimikeIdt' },
            { ValuePickerId: 'WorkforceScheduling_Event_Form_JobTitle', ParameterName: 'AmmattinimikeIdt' },
            {
                ValuePickerId: 'FormView_ValuePicker_Tes',
                ParameterName: 'TesIdt.IdList',
                AdditionalParams: { 'TesIdt.FilterType': 'All' },
            },
            { ValuePickerId: 'FormView_ValuePicker_Tyontekija', ParameterName: 'TyontekijaIdt' },
            { ValuePickerId: 'FormView_ValuePicker_Active_Employees', ParameterName: 'TyontekijaIdt' },
            { ValuePickerId: 'FormView_ValuePicker_Tyontekija_With_Link', ParameterName: 'TyontekijaIdt' },
            { ValuePickerId: 'FormView_ValuePicker_Tyontekija_With_Link_BaronaHybrid', ParameterName: 'TyontekijaIdt' },
            { ValuePickerId: 'WorkforceScheduling_Event_Form_Active_Employees', ParameterName: 'TyontekijaIdt' },
        ],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Ruokaperinta/Identifier',
        ValuePickerId: 'MealPayment_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'mealPaymentIds',
        Text: {
            FetchError: 'Ruokaperintöjen haku epäonnistui',
            Label: 'Ruokaperintä',
            MultipleSelected: 'ruokaperintää',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: -1,
        AdditionalDataFetchParameters: {
            Filters: { IncludeDefaultIdentifier: true },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Ruokaperinta/Identifier',
        ValuePickerId: 'MealPayment_ValuePicker_NoDependencies',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'mealPaymentIds',
        Text: {
            FetchError: 'Ruokaperintöjen haku epäonnistui',
            Label: 'Ruokaperintä',
            MultipleSelected: 'ruokaperintää',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Palkka/SalaryCategory/PieceWork/Identifier',
        ValuePickerId: 'PieceWorkSalaryCategory_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'pieceWorkSalaryCategoryId',
        Text: {
            FetchError: 'Urakkatyön palkkalajien haku epäonnistui',
            Label: 'Urakkatyön palkkalaji',
            MultipleSelected: 'urakkatyön palkkalajia',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    // Tyovuorolistapaketti value pickers
    {
        DependsOn: [],
        ValuePickerId: 'Tyovuorolistapaketti.valuePicker.dateRange',
        ValuePickerType: EValuePickerType.DateRange,
        DataRequestParameterName: 'dateRange',
        DefaultValues: {
            StartFromCurrentDay: -1,
            StartFromCurrentDayUnit: 'year',
            EndFromCurrentDay: 0,
            EndFromCurrentDayUnit: 'year',
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Alue/Identifier',
        ValuePickerId: 'Tyovuorolistapaketti.valuePicker.area',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'alueIdt',
        Text: {
            FetchError: 'Alueiden haku epäonnistui',
            Label: 'Alueet',
            MultipleSelected: 'aluetta',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [{ ValuePickerId: 'Tyovuorolistapaketti.valuePicker.area', ParameterName: 'alueIdt' }],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/Identifier',
        ValuePickerId: 'Tyovuorolistapaketti.valuePicker.organization',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'organisaatioAsiakasIdt',
        Text: {
            FetchError: 'Organisaatioiden haku epäonnistui',
            Label: 'Organisaatiot',
            MultipleSelected: 'organisaatiota',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 400,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [{ ValuePickerId: 'Tyovuorolistapaketti.valuePicker.area', ParameterName: 'areaIds' }],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Kayttaja/PersonsInCharge/Identifier',
        ValuePickerId: 'Tyovuorolistapaketti.valuePicker.personsInCharge',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'personsInChargeLoginIds',
        Text: {
            FetchError: 'Vastuuhenkilöiden haku epäonnistui',
            Label: 'Vastuuhenkilö',
            MultipleSelected: 'vastuuhenkilöitä',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 400,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [
            { ValuePickerId: 'Tyovuorolistapaketti.valuePicker.area', ParameterName: 'alueIdt' },
            { ValuePickerId: 'Tyovuorolistapaketti.valuePicker.organization', ParameterName: 'organisaatioAsiakasIdt' },
            {
                ValuePickerId: 'Tyovuorolistapaketti.valuePicker.personsInCharge',
                ParameterName: 'PersonsInChargeLoginIds',
            },
        ],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/Search',
        ValuePickerId: 'Tyovuorolistapaketti.valuePicker.customer',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'asiakasIdt',
        Text: {
            FetchError: 'Työkohteiden haku epäonnistui',
            Label: 'Työkohteet',
            MultipleSelected: 'työkohdetta',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Ammattinimike/Toimiala/Identifier',
        ValuePickerId: 'Tyovuorolistapaketti.valuePicker.toimiala',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'ToimialaIdt',
        Text: { FetchError: 'Toimialojen haku epäonnistui', Label: 'Toimiala', MultipleSelected: 'toimialaa' },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [
            { ValuePickerId: 'Tyovuorolistapaketti.valuePicker.toimiala', ParameterName: 'ToimialaIdt' },
            { ValuePickerId: 'Tyovuorolistapaketti.valuePicker.area', ParameterName: 'alueIdt' },
        ],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Ammattinimike/Identifier',
        ValuePickerId: 'Tyovuorolistapaketti.valuePicker.ammattinimike',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'ToimialaIdt',
        Text: {
            FetchError: 'Ammattinimikkeiden haku epäonnistui',
            Label: 'Ammattinimikkeet',
            MultipleSelected: 'ammattinimikettä',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Tyosuhde/Tyosopimustyyppi/Identifier',
        ValuePickerId: 'Tyovuorolistapaketti.valuePicker.tyosopimustyyppi',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'TyosopimustyyppiIds',
        Text: {
            FetchError: 'Työsopimustyyppien haku epäonnistui',
            Label: 'Työsopimustyypit',
            MultipleSelected: 'tyyppiä',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { IncludeNoneIdentifier: true },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Tyosuhde/Tyosopimustyyppi/Identifier',
        ValuePickerId: 'ContractType_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'TyosopimustyyppiIds',
        Text: {
            FetchError: 'Työsopimustyyppien haku epäonnistui',
            Label: 'Työsopimustyyppi',
            MultipleSelected: 'työsopimustyyppiä',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Contract/AnnualLeaveAccrualStartDateSettingType/Identifier',
        ValuePickerId: 'AnnualLeaveAccrualStartDateSettingType_ValuePicker',
        ValuePickerType: EValuePickerType.RadioButtonListValuePicker,
        DataRequestParameterName: 'AnnualLeaveAccrualStartDateSettingTypeId',
        Text: {
            FetchError: 'Lomalaskennan alkupäivämäärän määräytymistavan haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: 0,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/ExperienceAccumulationType/Identifier',
        ValuePickerId: 'EmployeeExperienceAccumulationType_ValuePicker',
        ValuePickerType: EValuePickerType.RadioButtonListValuePicker,
        DataRequestParameterName: 'EmployeeExperienceAccumulationTypeId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    // Muutosloki
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/History/Identifier',
        ValuePickerId: 'Muutosloki.valuePickers.ObjectType',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'Types',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Type'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [{ ValuePickerId: 'Muutosloki.valuePickers.ObjectType', ParameterName: 'Names' }],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/History/object/Identifier',
        ValuePickerId: 'Muutosloki.valuePickers.Identity',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'Ids',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },

    // FormView
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Alue/Identifier',
        ValuePickerId: 'FormView.ValuePicker.Alue',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'alueIdt',
        Text: {
            FetchError: 'Alueiden haku epäonnistui',
            Label: 'Alue',
            MultipleSelected: 'aluetta',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/Identifier',
        ValuePickerId: 'FormView_ValuePicker_Asiakas_With_Link',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'asiakasIdt',
        Text: {
            FetchError: 'Työkohteiden haku epäonnistui',
            Label: 'Työkohde',
            MultipleSelected: 'työkohdetta',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: null,
        ExtraRows: 'Omit',
        DefaultValues: null,
        SingleValueLink: {
            LinkStart: 'https://barona.mepco.fi/BSP/customerInfo.aspx?CustomerID=',
            Tooltip: 'Avaa työkohdekortti',
        },
        AdditionalDataFetchParameters: {
            Filters: { GetOnlyWorkUnitsWhereEventsAreAllowed: true },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/Identifier',
        ValuePickerId: 'FormView_ValuePicker_Asiakas_With_Link_BaronaHybrid',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'asiakasIdt',
        Text: {
            FetchError: 'Työkohteiden haku epäonnistui',
            Label: 'Työkohde',
            MultipleSelected: 'työkohdetta',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: null,
        ExtraRows: 'Omit',
        DefaultValues: null,
        SingleValueLink: {
            LinkStart: 'https://barona.mepco.fi/BSP/customerInfo.aspx?CustomerID=',
            Tooltip: 'Avaa työkohdekortti',
        },
        AdditionalDataFetchParameters: {
            Filters: { GetOnlyWorkUnitsWhereEventsAreAllowed: true },
        },
    },
    {
        ItemOptionLabelFields: ['DisplayId', 'Nimi'],
        DependsOn: [
            {
                ValuePickerId: 'FormView_ValuePicker_Asiakas',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
            {
                ValuePickerId: 'FormView_ValuePicker_Asiakas_With_Link',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
            {
                ValuePickerId: 'FormView_ValuePicker_Asiakas_With_Link_BaronaHybrid',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
            {
                ValuePickerId: 'FormView_ValuePicker_WorkUnit',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
            {
                ValuePickerId: 'IncludeEmployeeIdentifiersForEventIds',
                ParameterName: 'IncludeEmployeeIdentifiersForEventIds',
            },
        ],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Identifier',
        ValuePickerId: 'FormView_ValuePicker_Tyontekija_With_Link',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'tyontekijaIdt',
        Text: {
            FetchError: 'Työntekijöiden haku epäonnistui',
            Label: 'Työntekijä',
            MultipleSelected: 'työntekijää',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: null,
        ExtraRows: 'Omit',
        DefaultValues: null,
        SingleValueLink: {
            LinkStart: 'https://barona.mepco.fi/BSP/employeeInfo.aspx?employeeID=',
            Tooltip: 'Avaa työntekijäkortti',
        },
    },

    {
        ItemOptionLabelFields: ['DisplayId', 'Nimi'],
        DependsOn: [
            {
                ValuePickerId: 'FormView_ValuePicker_Asiakas',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
            {
                ValuePickerId: 'FormView_ValuePicker_Asiakas_With_Link',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
            {
                ValuePickerId: 'FormView_ValuePicker_Asiakas_With_Link_BaronaHybrid',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
            {
                ValuePickerId: 'FormView_ValuePicker_WorkUnit',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
            {
                ValuePickerId: 'IncludeEmployeeIdentifiersForEventIds',
                ParameterName: 'IncludeEmployeeIdentifiersForEventIds',
            },
        ],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Identifier',
        ValuePickerId: 'FormView_ValuePicker_Tyontekija_With_Link_BaronaHybrid',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'tyontekijaIdt',
        Text: {
            FetchError: 'Työntekijöiden haku epäonnistui',
            Label: 'Työntekijä',
            MultipleSelected: 'työntekijää',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: null,
        ExtraRows: 'Omit',
        DefaultValues: null,
        SingleValueLink: {
            LinkStart: 'https://barona.mepco.fi/BSP/employeeInfo.aspx?employeeID=',
            Tooltip: 'Avaa työntekijäkortti',
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/Identifier',
        ValuePickerId: 'FormView_ValuePicker_Asiakas',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'asiakasIdt',
        Text: {
            FetchError: 'Työkohteiden haku epäonnistui',
            Label: 'Työkohde',
            MultipleSelected: 'työkohdetta',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: null,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/v2/Tapahtuma/Tyyppi/Identifier',
        ValuePickerId: 'FormView_Tapahtumatyyppi', // Used as picker for processable events
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'tapahtumatyyppiIdt',
        Text: {
            FetchError: 'Tapahtumatyyppien haku epäonnistui',
            Label: 'Tapahtumatyyppi',
            MultipleSelected: 'tapahtumatyyppiä',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { ProcessableEventTypesOnly: true },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/v2/Tapahtuma/Tyyppi/Identifier',
        ValuePickerId: 'FormView_Tapahtumatyyppi2', // Used as secondary picker for processable events. Currently only in split event form.
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'tapahtumatyyppiIdt',
        Text: {
            FetchError: 'Tapahtumatyyppien haku epäonnistui',
            Label: 'Tapahtumatyyppi',
            MultipleSelected: 'tapahtumatyyppiä',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { ProcessableEventTypesOnly: true },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/v2/Tapahtuma/Tyyppi/Identifier',
        ValuePickerId: 'Tapahtumatyyppi_Insertable', // Used as picker for insertable events
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'tapahtumatyyppiIdt',
        Text: {
            FetchError: 'Tapahtumatyyppien haku epäonnistui',
            Label: 'Tapahtumatyyppi',
            MultipleSelected: 'tapahtumatyyppiä',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { InsertableEventTypesOnly: true },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/v2/Tapahtuma/Tyyppi/Identifier',
        ValuePickerId: 'Tapahtumatyyppi_AutomaticallyToggled',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'EventTypeIds',
        Text: {
            FetchError: 'Tapahtumatyyppien haku epäonnistui',
            Label: 'Tapahtumatyyppi',
            MultipleSelected: 'tapahtumatyyppiä',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { IsToggledAutomatically: true },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/v2/Tapahtuma/Tyyppi/Identifier',
        ValuePickerId: 'FormView_Tapahtumatyyppi_Leave',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'tapahtumatyyppiIdt',
        Text: {
            FetchError: 'Poissaolotapahtumatyyppien haku epäonnistui',
            Label: 'Poissaolon tapahtumatyyppi',
            MultipleSelected: 'poissaolon tapahtumatyyppiä',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { LeaveTypesOnly: true },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Contract/FileType/Identifier',
        ValuePickerId: 'FormView_Contract_FileType',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'ContractFileType',
        Text: {
            FetchError: 'Tiedostotyyppien haku epäonnistui',
            Label: 'Tiedostotyyppi',
            MultipleSelected: 'Tiedostotyyppiä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: 'Contract',
    },
    {
        ItemOptionLabelFields: ['DisplayId', 'Nimi'],
        DependsOn: [
            {
                ValuePickerId: 'FormView_ValuePicker_Asiakas',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
            {
                ValuePickerId: 'FormView_ValuePicker_Asiakas_With_Link',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
            {
                ValuePickerId: 'FormView_ValuePicker_Asiakas_With_Link_BaronaHybrid',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
            {
                ValuePickerId: 'FormView_ValuePicker_WorkUnit',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
            {
                ValuePickerId: 'IncludeEmployeeIdentifiersForEventIds',
                ParameterName: 'IncludeEmployeeIdentifiersForEventIds',
            },
        ],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Identifier',
        ValuePickerId: 'FormView_ValuePicker_Tyontekija',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'tyontekijaIdt',
        Text: {
            FetchError: 'Työntekijöiden haku epäonnistui',
            Label: 'Työntekijä',
            MultipleSelected: 'työntekijää',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: null,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        ItemOptionLabelFields: ['DisplayId', 'Nimi'],
        DependsOn: [
            {
                ValuePickerId: 'FormView_ValuePicker_Asiakas',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
            {
                ValuePickerId: 'FormView_ValuePicker_Asiakas_With_Link',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
            {
                ValuePickerId: 'FormView_ValuePicker_Asiakas_With_Link_BaronaHybrid',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
            {
                ValuePickerId: 'FormView_ValuePicker_WorkUnit',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
            {
                ValuePickerId: 'IncludeEmployeeIdentifiersForEventIds',
                ParameterName: 'IncludeEmployeeIdentifiersForEventIds',
            },
        ],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Identifier',
        ValuePickerId: 'FormView_ValuePicker_Active_Employees',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'tyontekijaIdt',
        Text: {
            FetchError: 'Työntekijöiden haku epäonnistui',
            Label: 'Työntekijä',
            MultipleSelected: 'työntekijää',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: null,
        ExtraRows: 'Omit',
        AdditionalDataFetchParameters: {
            Filters: { StatusIdt: [1] },
        },
        DefaultValues: null,
    },

    {
        ItemOptionLabelFields: ['DisplayId', 'Nimi'],
        DependsOn: [],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Identifier',
        ValuePickerId: 'FormView_ValuePicker_Tyontekija_No_Dependencies',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'tyontekijaIdt',
        Text: {
            FetchError: 'Työntekijöiden haku epäonnistui',
            Label: 'Työntekijä',
            MultipleSelected: 'työntekijää',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: null,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        ItemOptionLabelFields: ['DisplayId', 'Nimi'],
        DependsOn: [{ ValuePickerId: 'area_picker', ParameterName: 'alueIdt' }],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Identifier',
        ValuePickerId: 'FormView_ValuePicker_Tyontekija_Area_Dependency',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'tyontekijaIdt',
        Text: {
            FetchError: 'Työntekijöiden haku epäonnistui',
            Label: 'Työntekijä',
            MultipleSelected: 'työntekijää',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: null,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [
            {
                ValuePickerId: 'FormView_ValuePicker_Asiakas',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'All' },
            },
            {
                ValuePickerId: 'FormView_ValuePicker_Asiakas_With_Link',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
            {
                ValuePickerId: 'FormView_ValuePicker_Asiakas_With_Link_BaronaHybrid',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
            {
                ValuePickerId: 'FormView_ValuePicker_WorkUnit',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
            {
                ValuePickerId: 'FormView_ValuePicker_Tyontekija',
                ParameterName: 'tyontekijaIdt.IdList',
                AdditionalParams: { 'tyontekijaIdt.FilterType': 'All' },
            },
            {
                ValuePickerId: 'FormView_ValuePicker_Tyontekija_With_Link',
                ParameterName: 'tyontekijaIdt.IdList',
                AdditionalParams: { 'tyontekijaIdt.FilterType': 'All' },
            },
            {
                ValuePickerId: 'FormView_ValuePicker_Tyontekija_With_Link_BaronaHybrid',
                ParameterName: 'tyontekijaIdt.IdList',
                AdditionalParams: { 'tyontekijaIdt.FilterType': 'All' },
            },
        ],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Ammattinimike/Identifier',
        ValuePickerId: 'FormView_ValuePicker_Ammattinimike',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'ammattinimikeIdt',
        Text: {
            FetchError: 'Ammattinimikkeiden haku epäonnistui',
            Label: 'Ammattinimike',
            MultipleSelected: 'ammattinimikettä',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Ammattinimike/Identifier',
        ValuePickerId: 'FormView_ValuePicker_Ammattinimike_NoDependencies',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'ammattinimikeIdt',
        Text: {
            FetchError: 'Ammattinimikkeiden haku epäonnistui',
            Label: 'Ammattinimike',
            MultipleSelected: 'ammattinimikettä',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [
            {
                ValuePickerId: 'FormView_ValuePicker_Asiakas',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
            {
                ValuePickerId: 'FormView_ValuePicker_Asiakas_With_Link',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
            {
                ValuePickerId: 'FormView_ValuePicker_Asiakas_With_Link_BaronaHybrid',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
            {
                ValuePickerId: 'FormView_ValuePicker_WorkUnit',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
            {
                ValuePickerId: 'FormView_ValuePicker_Tyontekija',
                ParameterName: 'tyontekijaIdt.IdList',
                AdditionalParams: { 'tyontekijaIdt.FilterType': 'All' },
            },
            {
                ValuePickerId: 'FormView_ValuePicker_Tyontekija_With_Link',
                ParameterName: 'tyontekijaIdt.IdList',
                AdditionalParams: { 'tyontekijaIdt.FilterType': 'All' },
            },
            {
                ValuePickerId: 'FormView_ValuePicker_Tyontekija_With_Link_BaronaHybrid',
                ParameterName: 'tyontekijaIdt.IdList',
                AdditionalParams: { 'tyontekijaIdt.FilterType': 'All' },
            },
            {
                ValuePickerId: 'FormView_ValuePicker_Ammattinimike',
                ParameterName: 'ammattinimikeIdt.IdList',
                AdditionalParams: { 'ammattinimikeIdt.FilterType': 'All' },
            },
        ],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tes/Identifier',
        ValuePickerId: 'FormView_ValuePicker_Tes',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'tesIdt',
        Text: {
            FetchError: 'Tessien haku epäonnistui',
            Label: 'TES',
            MultipleSelected: 'tessiä',
        },
        TextSearchProps: ['Id', 'Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tes/Identifier',
        ValuePickerId: 'FormView_ValuePicker_Tes_No_Dependencies',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'tesIdt',
        Text: {
            FetchError: 'Tessien haku epäonnistui',
            Label: 'TES',
            MultipleSelected: 'tessiä',
        },
        TextSearchProps: ['Id', 'Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [
            {
                ValuePickerId: 'FormView_ValuePicker_WorkUnit',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
        ],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tes/Identifier',
        ValuePickerId: 'FormView_ValuePicker_Tes_WorkUnit',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'tesIdt',
        Text: {
            FetchError: 'Tessien haku epäonnistui',
            Label: 'TES',
            MultipleSelected: 'tessiä',
        },
        TextSearchProps: ['Id', 'Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { ForceWorkUnitSearch: true },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: 'Palkka/Palkkalajityyppi/Identifier',
        ValuePickerId: 'FormView_ValuePicker_Palkkalajityyppi',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'palkkalajityyppiIdt',
        Text: {
            FetchError: 'Palkkalajityyppien haku epäonnistui',
            Label: 'Palkkalajityyppi',
            MultipleSelected: 'palkkalajityyppi',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: 'Palkka/PayTypeCategory/Identifier',
        ValuePickerId: 'PayTypeCategory',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PayTypeCategoryId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittua',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: 'Palkka/Hinnanmaaritystyyppi/Identifier',
        ValuePickerId: 'FormView_ValuePicker_Hinnanmaaritystyyppi',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'hinnanmaaritystyyppiIdt',
        Text: {
            FetchError: 'Hinnanmääritystyyppien haku epäonnistui',
            Label: 'Hinnanmääritystyyppi',
            MultipleSelected: 'hinnanmääritystyyppiä',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tes/Identifier',
        ValuePickerId: 'FormView_ValuePicker_KaikkiTesit',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'tesIdt',
        Text: {
            FetchError: 'Tessien haku epäonnistui',
            Label: 'TES',
            MultipleSelected: 'tessiä',
        },
        TextSearchProps: ['Id', 'Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Selite'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Palkka/Palkkalaji/Identifier',
        ValuePickerId: 'FormView_ValuePicker_Palkkalaji',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: '',
        Text: {
            FetchError: 'Palkkalajien haku epäonnistui',
            Label: 'Palkkalaji',
            MultipleSelected: 'palkkalajia',
        },
        TextSearchProps: ['Id', 'Selite'],
        ItemValueLabelFields: ['Selite'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Selite'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Toteuma/Siirtotyyppi/Identifier',
        ValuePickerId: 'FormView_ValuePicker_Kasittelytapa',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: '',
        Text: {
            FetchError: 'Käsittelytapojen haku epäonnistui',
            Label: 'Käsittelytapa',
            MultipleSelected: 'käsittelytapaa',
        },
        TextSearchProps: ['Selite'],
        ItemValueLabelFields: ['Selite'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Selite'],
        EnableTextSearch: true,
        ValuePickerId: 'FormView_ValuePicker_KkPalkkalaji',
        OptionsEndpointUrl: 'Palkka/Palkkalaji/Identifier',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'kkPalkkalajiIdt',
        Text: {
            FetchError: 'Kuukausipalkkaisten palkkalajien haku epäonnistui',
            Label: 'Kuukausipalkkaisten palkkalaji',
            MultipleSelected: 'palkkalajia',
        },
        TextSearchProps: ['Selite'],
        ItemValueLabelFields: ['Selite'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },

    // Toteuma
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Toteuma/Toteumastatus/Identifier',
        ValuePickerId: 'Toteuma_valuePickers_toteumaStatus',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'toteumastatukset',
        Text: {
            FetchError: 'Toteumastatuksien haku epäonnistui',
            Label: 'Toteumastatukset',
            MultipleSelected: 'toteumastatusta',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },

    // Toteuma form
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Palkka/Palkkaperuste/Identifier',
        ValuePickerId: 'Toteuma_Form_ValuePicker_Palkkaperuste',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: '',
        Text: {
            FetchError: 'Palkkaperusteiden haku epäonnistui',
            Label: 'Palkkaperuste',
            MultipleSelected: 'palkkaperustetta',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Palkka/Laskutusperuste/Identifier',
        ValuePickerId: 'Toteuma_Form_ValuePicker_Laskutusperuste',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: '',
        Text: {
            FetchError: 'Laskutusperusteiden haku epäonnistui',
            Label: 'Laskutusperuste',
            MultipleSelected: 'laskutusperustetta',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    ...workforceSchedulingValuePickers,
    ...suunnittelujaksoValuePickers,
    // Used for dual list POC
    {
        ValuePickerId: 'DualList_TyontekijaIdentifierList',
        ValuePickerType: EValuePickerType.ConfigurableList,
        ListId: 'TyontekijaIdentifierList',
        DependsOn: [],
        DataRequestParameterName: 'TyontekijaIdt',
        UsePageTemplate: true,
        DefaultValues: null,
    },
    // CLA
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/register/Tyoaikamuoto/identifier',
        ValuePickerId: 'Cla_valuePickers_formOfWorkingHours',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'FormOfWorkingHours',
        Text: {
            FetchError: 'Työaikamuotojen haku epäonnistui',
            Label: 'Työaikamuoto',
            MultipleSelected: 'työaikamuotoa',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    // Other
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/register/Tyoaikamuoto/identifier',
        ValuePickerId: 'formOfWorkingHours',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'TyoaikamuotoIdt',
        Text: {
            FetchError: 'Työaikamuotojen haku epäonnistui',
            Label: 'Työaikamuodot',
            MultipleSelected: 'työaikamuotoa',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Register/FixedTermEmploymentReason/Identifier',
        ValuePickerId: 'FixedTermEmploymentReason_Picker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'FixedTermEmploymentReasonIds',
        Text: {
            FetchError: 'Määräaikaisuuden perusteiden haku epäonnistui',
            Label: 'Määräaikaisuuden peruste',
            MultipleSelected: 'määräaikaisuuden perustetta',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Selite'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Palkka/Palkkalaji/Identifier',
        ValuePickerId: 'salaryCategory',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PalkkalajiIdt',
        Text: {
            FetchError: 'Palkkalajien haku epäonnistui',
            Label: 'Palkkalajit',
            MultipleSelected: 'palkkalajia',
        },
        TextSearchProps: ['Id', 'Selite'],
        ItemValueLabelFields: ['Selite'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Selite'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Palkka/Palkkalaji/Identifier',
        ValuePickerId: 'SalaryCategoryFilter_V2',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'SalaryCategoryIds',
        Text: {
            FetchError: 'Palkkalajien haku epäonnistui',
            Label: 'Palkkalajit',
            MultipleSelected: 'palkkalajia',
        },
        TextSearchProps: ['Id', 'Selite'],
        ItemValueLabelFields: ['Selite'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Selite'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Palkka/Palkkalaji/Identifier',
        ValuePickerId: 'SalaryCategory_V2',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'SalaryCategoryId',
        Text: {
            FetchError: 'Palkkalajien haku epäonnistui',
            Label: 'Palkkalaji',
            MultipleSelected: 'palkkalajia',
        },
        TextSearchProps: ['Id', 'Selite'],
        ItemValueLabelFields: ['Selite'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Register/Identifier',
        ValuePickerId: 'Registers',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'RegisterId',
        Text: {
            FetchError: 'Rekisterien haku epäonnistui',
            Label: 'Rekisteri',
            MultipleSelected: 'Rekisteriä',
        },
        TextSearchProps: ['Id', 'Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/DynamicProperty/Identifier',
        ValuePickerId: 'DynamicPropertyEmployeeContract',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'ObjectTypeId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Työsopimuksen lisätietueet',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Id', 'Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { ObjectType: 3 },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/DynamicProperty/Identifier',
        ValuePickerId: 'DynamicPropertyWorkUnit',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'ObjectTypeId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Työkohteen lisätietueet',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Id', 'Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { ObjectType: 2 },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/DynamicProperty/ObjectType/Identifier',
        ValuePickerId: 'ObjectType',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'ObjectTypeId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Id', 'Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/BulkUpdate/Operation/Identifier',
        ValuePickerId: 'UpdateTypePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'UpdateType',
        Text: {
            FetchError: 'Muokkaustapojen haku epäonnistui',
            Label: 'Muokkaustapa',
            MultipleSelected: 'muokkaustavat',
        },
        TextSearchProps: ['Id', 'Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/BulkUpdate/TargetProperty/Identifier',
        ValuePickerId: 'WorkUnitTargetPropertyPicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'TargetProperties',
        Text: {
            FetchError: 'Muokkauskohteiden haku epäonnistui',
            Label: 'Muokkauskohde',
            MultipleSelected: 'muokkauskohdetta',
        },
        TextSearchProps: ['Id', 'Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/BulkUpdate/TargetProperty/Identifier',
        ValuePickerId: 'EmployeeTargetPropertyPicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'TargetProperties',
        Text: {
            FetchError: 'Muokkauskohteiden haku epäonnistui',
            Label: 'Muokkauskohde',
            MultipleSelected: 'muokkauskohdetta',
        },
        TextSearchProps: ['Id', 'Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/DynamicProperty/PropertyType/Identifier',
        ValuePickerId: 'PropertyType',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PropertyTypeId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Id', 'Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Selite'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Palkka/PaymentPriceTarget/Identifier',
        ValuePickerId: 'PaymentPriceTarget',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PaymentPriceTargetId',
        Text: {
            FetchError: 'Hinnoittelukohteiden haku epäonnistui',
            Label: 'Hinnoittelukohde',
            MultipleSelected: 'hinnoittelukohdetta',
        },
        TextSearchProps: ['Id', 'Selite'],
        ItemValueLabelFields: ['Selite'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: ['BaseWage'],
        AdditionalDataFetchParameters: {
            Filters: { IncludeBasePriceIdentifier: true },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Palkka/PaymentPriceCalculationRule/Identifier',
        ValuePickerId: 'PaymentPriceCalculationRule',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PaymentPriceCalculationRuleId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Id', 'Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Palkka/QuantityCalculationTypes/Identifier',
        ValuePickerId: 'PaymentPriceCalculationRuleQuantityCalculationType',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PaymentPriceCalculationRuleQuantityCalculationTypeId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Id', 'Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: 1,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Selite'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Palkka/PaymentPriceTarget/Identifier',
        ValuePickerId: 'PaymentPriceTarget_Filter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PaymentPriceTargetIds',
        Text: {
            FetchError: 'Hinnoittelukohteiden haku epäonnistui',
            Label: 'Hinnoittelukohde',
            MultipleSelected: 'hinnoittelukohdetta',
        },
        TextSearchProps: ['Id', 'Selite'],
        ItemValueLabelFields: ['Selite'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { IncludeBasePriceIdentifier: true },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Id', 'Selite'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Palkka/Palkkalaji/Identifier',
        ValuePickerId: 'PriceBaseSalaryCategory',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PriceBaseSalaryCategoryId',
        Text: {
            FetchError: 'Palkkalajien haku epäonnistui',
            Label: 'Palkkalaji',
            MultipleSelected: 'palkkalajia',
        },
        TextSearchProps: ['Id', 'Selite'],
        ItemValueLabelFields: ['Selite'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/VAT/VATClass/Identifier',
        ValuePickerId: 'VATClass',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'VATClassId',
        Text: {
            FetchError: 'ALV-luokkien haku epäonnistui',
            Label: 'ALV-luokka',
            MultipleSelected: 'ALV-luokkaa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/VAT/VATClass/Identifier',
        ValuePickerId: 'VATClass_Employee',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'VATClassId',
        Text: {
            FetchError: 'ALV-luokkien haku epäonnistui',
            Label: 'ALV-luokka',
            MultipleSelected: 'ALV-luokkaa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: '-1',
        AdditionalDataFetchParameters: {
            Filters: {
                IsEmployeeVATClassSelection: true,
            },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tenant/Identifier',
        ValuePickerId: 'Tenant',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'TenantIds',
        Text: {
            FetchError: 'Ympäristön haku epäonnistui',
            Label: 'Ympäristö',
            MultipleSelected: 'ympäristöä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/InvoicingCompany/Identifier',
        ValuePickerId: 'InvoicingCompany',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'InvoicingCompanyId',
        Text: {
            FetchError: 'Laskuttavien yritysten haku epäonnistui',
            Label: 'Laskuttava yritys',
            MultipleSelected: 'laskuttavaa yritystä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Company/Identifier',
        ValuePickerId: 'InvoicingCompanies_Filter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'InvoicingCompanyIds',
        Text: {
            FetchError: 'Laskuttavien yritysten haku epäonnistui',
            Label: 'Laskuttava yritys',
            MultipleSelected: 'laskuttavaa yritystä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/InvoicingCompany/Identifier',
        ValuePickerId: 'InvoicingCompanyFilter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'InvoicingCompanyIds',
        Text: {
            FetchError: 'Laskuttavien yritysten haku epäonnistui',
            Label: 'Laskuttava yritys',
            MultipleSelected: 'laskuttavaa yritystä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Toteuma/PayrollProduct/Identifier',
        ValuePickerId: 'PayrollProduct',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PayrollProductId',
        Text: {
            FetchError: 'Palkkatuotteiden haku epäonnistui',
            Label: 'Palkkatuote',
            MultipleSelected: 'palkkatuotetta',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Toteuma/CompensationReason/Identifier',
        ValuePickerId: 'CompensationReason',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'CompensationReasonId',
        Text: {
            FetchError: 'Hyvityksen syiden haku epäonnistui',
            Label: 'Hyvityksen syy',
            MultipleSelected: 'hyvityksen syytä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [
            {
                ValuePickerId: 'AdditionalServiceType_ValuePicker',
                ParameterName: 'AdditionalServiceTypes',
            },
        ],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/AdditionalService/QuantityCalculationType/Identifier',
        ValuePickerId: 'AdditionalServiceQuantityCalculationType',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'QuantityCalculationTypeId',
        Text: {
            FetchError: 'Tuottotapojen haku epäonnistui',
            Label: 'Tuottotapa',
            MultipleSelected: 'tuottotapaa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/AdditionalService/InfoFieldType/Identifier',
        ValuePickerId: 'AdditionalServiceInfoFieldType',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'AdditionalServiceInfoFieldTypeId',
        Text: {
            FetchError: 'Lisätietotyyppien haku epäonnistui',
            Label: 'Lisätietotyyppi',
            MultipleSelected: 'lisätietotyyppiä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/AdditionalService/InfoFieldType/Identifier',
        ValuePickerId: 'AdditionalServiceInfoFieldType',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'FieldTypeId',
        Text: {
            FetchError: 'Kenttätyyppien haku epäonnistui',
            Label: 'Kenttätyyppi',
            MultipleSelected: 'kenttätyyppiä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/AdditionalService/Identifier',
        ValuePickerId: 'AdditionalService_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'AdditionalServiceIds',
        Text: {
            FetchError: 'Lisäpalveluiden haku epäonnistui',
            Label: 'Lisäpalvelu',
            MultipleSelected: 'lisäpalvelua',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: {
                HasManualQuantityType: false,
            },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/AdditionalService/Identifier',
        ValuePickerId: 'AdditionalService_Employee_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'AdditionalServiceIds',
        Text: {
            FetchError: 'Lisäpalveluiden haku epäonnistui',
            Label: 'Lisäpalvelu',
            MultipleSelected: 'lisäpalvelua',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: {
                IsEmployeeSelectionAllowed: true,
            },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/AdditionalService/Identifier',
        ValuePickerId: 'AdditionalService_WorkUnit_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'AdditionalServiceIds',
        Text: {
            FetchError: 'Lisäpalveluiden haku epäonnistui',
            Label: 'Lisäpalvelu',
            MultipleSelected: 'lisäpalvelua',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: {
                IsWorkUnitSelectionAllowed: true,
            },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/AdditionalService/Type/Identifier',
        ValuePickerId: 'AdditionalServiceType_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'AdditionalServiceTypeIds',
        Text: {
            FetchError: 'Lisäpalvelutyyppien haku epäonnistui',
            Label: 'Lisäpalvelutyyppi',
            MultipleSelected: 'lisäpalvelutyyppiä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/VAT/VATClass/Identifier',
        ValuePickerId: 'VATClassFilter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'VATClassIds',
        Text: {
            FetchError: 'ALV-luokkien haku epäonnistui',
            Label: 'ALV-luokat',
            MultipleSelected: 'ALV-luokkaa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/VAT/VATClass/Identifier',
        ValuePickerId: 'VATClassPicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'VATClassIds',
        Text: {
            FetchError: 'ALV-luokkien haku epäonnistui',
            Label: 'ALV-luokat',
            MultipleSelected: 'ALV-luokkaa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [
            {
                ValuePickerId: 'VATClassId_HiddenValuePicker',
                ParameterName: 'VATClassIds',
            },
        ],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/VAT/VATRate/Identifier',
        ValuePickerId: 'VATRateFilter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'VATRateIds',
        Text: {
            FetchError: 'ALV-kantojen haku epäonnistui',
            Label: 'ALV-kannat',
            MultipleSelected: 'ALV-kantaa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Toteuma/PayrollProduct/Identifier',
        ValuePickerId: 'PayrollProductFilter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PayrollProductIds',
        Text: {
            FetchError: 'Palkkatuotteiden haku epäonnistui',
            Label: 'Palkkatuotteet',
            MultipleSelected: 'palkkatuotetta',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { IncludeNoneIdentifier: true },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Transfer/TransferBatch/Identifier',
        ValuePickerId: 'EventTransferBatch_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'TransferBatchIds',
        Text: {
            FetchError: 'Siirtoerien haku epäonnistui',
            Label: 'Siirtoerä',
            MultipleSelected: 'siirtoerää',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: {
                IsTransferred: false,
                TransferTypeIds: ['EventTransfer'],
                IncludeNoneIdentifier: true,
            },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Transfer/EventTransferTarget/Identifier',
        ValuePickerId: 'EventTransferTarget_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'TransferBatchIds',
        Text: {
            FetchError: 'Siirtokohteiden haku epäonnistui',
            Label: 'Siirtokohde',
            MultipleSelected: 'siirtokohdetta',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [
            {
                ValuePickerId: 'VATClassId_HiddenValuePicker',
                ParameterName: 'VATClassIds',
            },
        ],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/VAT/VATRate/Identifier',
        ValuePickerId: 'VATRatePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'VATRateIds',
        Text: {
            FetchError: 'ALV-kantojen haku epäonnistui',
            Label: 'ALV-kannat',
            MultipleSelected: 'ALV-kantaa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Transfer/TransferTarget/Identifier',
        ValuePickerId: 'TransferTarget_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'TransferBatchIds',
        Text: {
            FetchError: 'Siirtokohteiden haku epäonnistui',
            Label: 'Siirtokohde',
            MultipleSelected: 'siirtokohdetta',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Transfer/TransferTarget/Identifier',
        ValuePickerId: 'TransferTarget_Manual_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'TransferBatchIds',
        Text: {
            FetchError: 'Siirtokohteiden haku epäonnistui',
            Label: 'Siirtokohde',
            MultipleSelected: 'siirtokohdetta',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { HasAutomaticTrigger: false },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Toteuma/CompensationReason/Identifier',
        ValuePickerId: 'CompensationReasonFilter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'CompensationReasondIds',
        Text: {
            FetchError: 'Hyvityksen syiden haku epäonnistui',
            Label: 'Hyvityksen syyt',
            MultipleSelected: 'hyvityksen syytä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Toteuma/PaymentBatch/Identifier',
        ValuePickerId: 'PaymentBatchFilter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PaymentBatchIds',
        Text: {
            FetchError: 'Palkkaerien haku epäonnistui',
            Label: 'Palkkaerät',
            MultipleSelected: 'palkkaerää',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: true,
        Limit: 200,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Toteuma/InvoiceBatch/Identifier',
        ValuePickerId: 'InvoiceBatchFilter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'InvoiceBatchIds',
        Text: {
            FetchError: 'Laskutuerien haku epäonnistui',
            Label: 'Laskutuserät',
            MultipleSelected: 'laskutuserää',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: true,
        Limit: 200,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Toteuma/Invoice/Identifier',
        ValuePickerId: 'InvoiceFilter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'InvoiceIds',
        Text: {
            FetchError: 'Laskujen haku epäonnistui',
            Label: 'Laskut',
            MultipleSelected: 'laskua',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: true,
        Limit: 200,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Selite'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Toteuma/Siirtotyyppi/Identifier',
        ValuePickerId: 'transactionType',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'Siirtotyypit',
        Text: {
            FetchError: 'Käsittelytapojen haku epäonnistui',
            Label: 'Käsittelytavat',
            MultipleSelected: 'käsittelytapaa',
        },
        TextSearchProps: ['Selite'],
        ItemValueLabelFields: ['Selite'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Selite'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Toteuma/Kirjaustapa/Identifier',
        ValuePickerId: 'transactionTransferType',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'Kirjaustavat',
        Text: {
            FetchError: 'Siirtotyyppien haku epäonnistui',
            Label: 'Siirtotyypit',
            MultipleSelected: 'siirtotyyppiä',
        },
        TextSearchProps: ['Selite'],
        ItemValueLabelFields: ['Selite'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/register/Kalleusluokka/identifier',
        ValuePickerId: 'kalleusluokka',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'KalleusluokkaIdt',
        Text: {
            FetchError: 'Kalleusluokien haku epäonnistui',
            Label: 'Kalleusluokat',
            MultipleSelected: 'kalleusluokkaa',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/register/Kalleusluokka/identifier',
        ValuePickerId: 'kalleusluokka_form_picker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'KalleusluokkaId',
        Text: {
            FetchError: 'Kalleusluokien haku epäonnistui',
            Label: 'Kalleusluokka',
            MultipleSelected: 'kalleusluokkaa',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['DisplayName'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Pooli/Identifier',
        ValuePickerId: 'pool',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PoolIds',
        Text: {
            FetchError: 'Poolien haku epäonnistui',
            Label: 'Poolit',
            MultipleSelected: 'poolia',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['DisplayName'],
        UseDynamicApiSearch: true,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Pooli/Identifier',
        ValuePickerId: 'pool_nonworkunitspecific',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PoolIds',
        Text: {
            FetchError: 'Poolien haku epäonnistui',
            Label: 'Poolit',
            MultipleSelected: 'poolia',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: true,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { IsWorkUnitSpecific: false },
        },
    },
    {
        DependsOn: [{ ValuePickerId: 'FormView_ValuePicker_WorkUnit_No_Dependencies', ParameterName: 'WorkUnitIds' }],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Pooli/Identifier',
        ValuePickerId: 'pool_workunitspecific',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PoolIds',
        Text: {
            FetchError: 'Poolien haku epäonnistui',
            Label: 'Poolit',
            MultipleSelected: 'poolia',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: true,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { IsWorkUnitSpecific: true },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Pooli/Identifier',
        ValuePickerId: 'pool_allpools',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PoolIds',
        Text: {
            FetchError: 'Poolien haku epäonnistui',
            Label: 'Poolit',
            MultipleSelected: 'poolia',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: true,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [{ ValuePickerId: 'WorkUnitId_HiddenValuePicker_FilterFilter', ParameterName: 'WorkUnitIds' }],
        ItemOptionLabelFields: ['DisplayName'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Pooli/Identifier',
        ValuePickerId: 'pool_filter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PoolIdList',
        Text: {
            FetchError: 'Poolien haku epäonnistui',
            Label: 'Poolit',
            MultipleSelected: 'poolia',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['DisplayName'],
        UseDynamicApiSearch: true,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Pooli/PoolType/Identifier',
        ValuePickerId: 'PoolType_ValuePicker',
        ValuePickerType: EValuePickerType.RadioButtonListValuePicker,
        DataRequestParameterName: 'PoolTypeId',
        Text: {
            FetchError: 'Poolityyppien haku epäonnistui',
            Label: 'Poolityyppi',
            MultipleSelected: 'poolityyppiä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [{ ValuePickerId: 'PoolType_ValuePicker', ParameterName: 'PoolTypeIds' }],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Pooli/PoolVisibilityType/Identifier',
        ValuePickerId: 'PoolVisibilityType_ValuePicker',
        ValuePickerType: EValuePickerType.RadioButtonListValuePicker,
        DataRequestParameterName: 'PoolVisibilityTypeId',
        Text: {
            FetchError: 'Poolityyppien haku epäonnistui',
            Label: 'Poolityyppi',
            MultipleSelected: 'poolityyppiä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/v2/Tapahtuma/Varaavuustyyppi/Identifier',
        ValuePickerId: 'varaavuustyyppi',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'VaraavuustyyppiId',
        Text: {
            FetchError: 'Varaavuustyyppien haku epäonnistui',
            Label: 'Varaavuustyyppi',
            MultipleSelected: 'Varaavuustyyppiä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/v2/Tapahtuma/Tyoaikatyyppi/Identifier',
        ValuePickerId: 'tyoaikatyyppi',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'TyoaikatyyppiId',
        Text: {
            FetchError: 'Tyoaikatyyppien haku epäonnistui',
            Label: 'Työaikatyyppi',
            MultipleSelected: 'Työaikatyyppiä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/v2/Tapahtuma/OrderCategory/Identifier',
        ValuePickerId: 'OrderCategory_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'OrderCategoryId',
        Text: {
            FetchError: 'Tilauskategorioiden haku epäonnistui',
            Label: 'Tilauskategoria',
            MultipleSelected: 'tilauskategoriaa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [
            { ValuePickerId: 'WorkforceScheduling_PlanningPeriods', ParameterName: 'PlanningPeriodVersionIds' },
        ],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/v2/Tapahtuma/EventTemplateGroup/Identifier',
        ValuePickerId: 'EventTemplateGroup',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'EventTemplateGroupId',
        Text: {
            FetchError: 'Profiilien haku epäonnistui',
            Label: 'Profiili',
            MultipleSelected: 'Profiilia',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Product/Identifier',
        ValuePickerId: 'Product_Filter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'ProductIds',
        Text: {
            FetchError: 'Tuotteiden haku epäonnistui',
            Label: 'Tuote',
            MultipleSelected: 'Tuotetta',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Product/Identifier',
        ValuePickerId: 'Product_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'ProductIds',
        Text: {
            FetchError: 'Tuotteiden haku epäonnistui',
            Label: 'Tuote',
            MultipleSelected: 'Tuotetta',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/OrderType/Search',
        ValuePickerId: 'OrderType_Filter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'OrderTypeIds',
        Text: {
            FetchError: 'Tilaustyyppien haku epäonnistui',
            Label: 'Tilaustyyppi',
            MultipleSelected: 'Tilaustyyppiä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/OrderType/Search',
        ValuePickerId: 'OrderType_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'OrderTypeIds',
        Text: {
            FetchError: 'Tilaustyyppien haku epäonnistui',
            Label: 'Tilaustyyppi',
            MultipleSelected: 'Tilaustyyppiä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/InvoicePrice/InvoiceStatus/Identifier',
        ValuePickerId: 'InvoiceStatus',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: '',
        Text: {
            FetchError: 'Laskutusstatusten haku epäonnistui',
            Label: 'Laskutusstatus',
            MultipleSelected: 'laskutusstatusta',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Customer/Identifier',
        ValuePickerId: 'Customer_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'CustomerId',
        Text: {
            FetchError: 'Asiakkaiden haku epäonnistui',
            Label: 'Asiakas',
            MultipleSelected: 'asiakasta',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Customer/Identifier',
        ValuePickerId: 'Customer_Filter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'CustomerIds',
        Text: {
            FetchError: 'Asiakkaiden haku epäonnistui',
            Label: 'Asiakkaat',
            MultipleSelected: 'asiakasta',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/InvoicePrice/InvoicePriceType/Identifier',
        ValuePickerId: 'InvoicePriceType_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'InvoicePriceTypeId',
        Text: {
            FetchError: 'Laskutushintatyyppien haku epäonnistui',
            Label: 'Laskutushintatyyppi',
            MultipleSelected: 'laskutushintatyyppiä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: 1,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/CustomerSelectionType/Identifier',
        ValuePickerId: 'WorkUnitCustomerSelectionType_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'WorkUnitCustomerSelectionTypeId',
        Text: {
            FetchError: 'Asiakasvalintatyypin haku epäonnistui',
            Label: 'Asiakasvalintatyyppi',
            MultipleSelected: '',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: 1,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Palkka/PaymentStatus/Identifier',
        ValuePickerId: 'PaymentStatus_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PaymentStatus',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Palkkastatus',
            MultipleSelected: 'palkkastatusta',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [{ ValuePickerId: 'PaymentPriceTarget', ParameterName: 'PaymentPriceTargetIds' }],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Palkka/PaymentPriceType/Identifier',
        ValuePickerId: 'PaymentPriceType_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PaymentPriceTypeId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Palkkahintatyyppi',
            MultipleSelected: 'palkkahintatyyppiä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: 1,
    },
    {
        DependsOn: [],
        ValuePickerId: 'HiddenValuePicker',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: '',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ValuePickerId: 'VATClassId_HiddenValuePicker',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: '',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ValuePickerId: 'Hidden_AdditionalServiceIds',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: 'AdditionalServiceIds',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ValuePickerId: 'Hidden_ContractId',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: 'ContractId',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ValuePickerId: 'Hidden_ContractId2',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: 'ContractId',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ValuePickerId: 'MainEventsOnly',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: 'MainEventsOnly',
        DefaultValues: true,
    },
    {
        DependsOn: [],
        ValuePickerId: 'IsStaffingSearch',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: 'IsStaffingSearch',
        DefaultValues: true,
    },
    {
        DependsOn: [],
        ValuePickerId: 'ParentEvent',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: 'ParentId',
        TrueValueSideEffects: [
            {
                key: 'NonSubEventsOnly',
                value: 'hide',
            },
        ],
        FalseValueSideEffects: [
            {
                key: 'SubEventsOnly',
                value: 'show',
            },
        ],
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ValuePickerId: 'ParentEventFilter',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: 'ParentId',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ValuePickerId: 'Events',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: 'EventIds',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ValuePickerId: 'IncludeEmployeeIdentifiersForEventIds',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: 'IncludeEmployeeIdentifiersForEventIds',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ValuePickerId: 'WorkTimeRule',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: 'WorkTimeRuleIds',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ValuePickerId: 'WorkTimeRuleFunction',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: 'WorkTimeRuleFunctionIds',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/LivingCostClass/Identifier',
        ValuePickerId: 'LivingCostClass_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'LivingCostClassId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Kalleusluokka',
            MultipleSelected: 'kalleusluokkaa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/CostCode/Identifier',
        ValuePickerId: 'CostCode_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'CostCodeId',
        Text: {
            FetchError: 'Kustannuspaikkojen haku epäonnistui',
            Label: 'Kustannuspaikka',
            MultipleSelected: 'kustannuspaikkaa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [{ ValuePickerId: 'Hidden_ContractId', ParameterName: 'EmployeeContractIds' }],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Contract/FileTemplate/Identifier',
        ValuePickerId: 'ContractFileTemplate_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'ContractFileTemplateId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Sopimustiedostopohja',
            MultipleSelected: 'sopimustiedostopohjaa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { FileTypes: ['Contract'] },
        },
    },
    {
        DependsOn: [
            { ValuePickerId: 'Hidden_ContractId2', ParameterName: 'EmployeeContractIds' },
            { ValuePickerId: 'FormView_Contract_FileType', ParameterName: 'ContractFileType' },
        ],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Contract/FileTemplate/Identifier',
        ValuePickerId: 'ContractFileTemplate_AllTypes_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'ContractFileTemplateId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Sopimustiedostopohja',
            MultipleSelected: 'sopimustiedostopohjaa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [
            { ValuePickerId: 'Hidden_ContractId2', ParameterName: 'EmployeeContractIds' },
            { ValuePickerId: 'FormView_Contract_FileType', ParameterName: 'ContractFileType' },
        ],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Contract/FileTemplate/Identifier',
        ValuePickerId: 'ContractFileTemplate_AllTypes_RestrictWorkHistoryTrue_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'ContractFileTemplateId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Sopimustiedostopohja',
            MultipleSelected: 'sopimustiedostopohjaa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: {
                RestrictWorkHistoryToContract: true,
            },
        },
    },
    {
        DependsOn: [
            { ValuePickerId: 'FormView_ValuePicker_Tes', ParameterName: 'claIds' },
            { ValuePickerId: 'tes', ParameterName: 'claIds' },
            { ValuePickerId: 'FormView_ValuePicker_Tes_No_Dependencies', ParameterName: 'claIds' },
        ],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Ammattinimike/FormOfWorkingHours/Identifier',
        ValuePickerId: 'FormOfWorkingHours_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'FormOfWorkingHoursId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Työaikamuoto',
            MultipleSelected: 'työaikamuotoa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [{ ValuePickerId: 'FormView_ValuePicker_WorkUnit', ParameterName: 'WorkUnitIds' }],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Ammattinimike/FormOfWorkingHours/Identifier',
        ValuePickerId: 'FormOfWorkingHours_FromWorkUnit_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'FormOfWorkingHoursId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Työaikamuoto',
            MultipleSelected: 'työaikamuotoa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [{ ValuePickerId: 'tes', ParameterName: 'claIds' }],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Ammattinimike/FormOfWorkingHours/Identifier',
        ValuePickerId: 'FormOfWorkingHours_Filter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'FormOfWorkingHoursIds',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Työaikamuoto',
            MultipleSelected: 'työaikamuotoa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        AdditionalDataFetchParameters: {
            Filters: { CustomFieldType: 'KyllaEi' },
        },
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/CustomField/Identifier',
        ValuePickerId: 'WorkUnitCustomField_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'WorkUnitCustomFieldId',
        Text: {
            FetchError: 'Lisätietokenttien haku epäonnistui',
            Label: 'Vaadittava lisätietokenttä',
            MultipleSelected: 'vaadittua lisätietokenttää',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/BackgroundTasks/BatchType/Identifier',
        ValuePickerId: 'BatchType_EventProcessing_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'BatchTypeId',
        Text: {
            FetchError: 'Tyyppien haku epäonnistui',
            Label: 'Tyyppi',
            MultipleSelected: 'tyyppiä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { BatchTypeGroupIds: [2] },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/BackgroundTasks/EventBatchProcessingRule/Search',
        ValuePickerId: 'EventBatchProcessingRules_SHOW_LOG',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'BatchIds',
        Text: {
            FetchError: '',
            Label: '',
            MultipleSelected: '',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 400,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/EventLocationSelectionType/Identifier',
        ValuePickerId: 'EventLocationSelectionType_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'EventLocationSelectionTypeId',
        Text: {
            FetchError: 'Tapahtumien sijantien haku epäonnistui',
            Label: 'Tapahtuman sijainti',
            MultipleSelected: '',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Skill/Identifier',
        ValuePickerId: 'Skill_Filter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'SkillIds',
        Text: {
            FetchError: 'Erikoistaitojen haku epäonnistui',
            Label: 'Erikoistaidot',
            MultipleSelected: 'erikoistaitoa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Skill/Identifier',
        ValuePickerId: 'Skill_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'SkillIds',
        Text: {
            FetchError: 'Erikoistaitojen haku epäonnistui',
            Label: 'Erikoistaidot',
            MultipleSelected: 'erikoistaitoa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Skill/Type/Identifier',
        ValuePickerId: 'SkillType_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'SkillTypeIds',
        Text: {
            FetchError: 'Erikoistaitojen tyyppien haku epäonnistui',
            Label: 'Erikoistaitotyyppi',
            MultipleSelected: 'erikoistaitotyyppiä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/Status/Identifier',
        ValuePickerId: 'WorkUnit_Status_Filter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'StatusIdt',
        Text: {
            FetchError: 'Statusten haku epäonnistui',
            Label: 'Status',
            MultipleSelected: 'Statukset',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: [1],
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/Status/Identifier',
        ValuePickerId: 'WorkUnit_Status_Picker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'Status',
        Text: {
            FetchError: 'Statusten haku epäonnistui',
            Label: 'Status',
            MultipleSelected: 'Statukset',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Status/Identifier',
        ValuePickerId: 'Employee_Status_Filter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'StatusIdt',
        Text: {
            FetchError: 'Statusten haku epäonnistui',
            Label: 'Status',
            MultipleSelected: 'Statukset',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: [1],
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Status/Identifier',
        ValuePickerId: 'Employee_Status_Picker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'Status',
        Text: {
            FetchError: 'Statusten haku epäonnistui',
            Label: 'Status',
            MultipleSelected: 'Statukset',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ValuePickerId: 'ChangeLog_Object_Type_Filter',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: 'ObjectType',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ValuePickerId: 'ChangeLog_Object_Id_Filter',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: 'ObjectId',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Skill/Identifier',
        ValuePickerId: 'DoesntHaveSkill_Filter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'HasNoneOfSkillIds',
        Text: {
            FetchError: 'Erikoistaitojen haku epäonnistui',
            Label: 'Puuttuvat erikoistaidot',
            MultipleSelected: 'erikoistaitoa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Tyosuhde/ContractTerminationReason/Identifier',
        ValuePickerId: 'ContractTerminationReason_picker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'ContractTerminationReasonId',
        Text: {
            FetchError: 'Sopimuksen päättymisen syiden haku epäonnistui',
            Label: 'Sopimuksen päättymisen syy',
            MultipleSelected: 'sopimuksen päättymisen syytä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Contract/AnnualLeaveAccrualType/Identifier',
        ValuePickerId: 'AnnualLeaveAccrualType_picker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'AnnualLeaveAccrualTypeId',
        Text: {
            FetchError: 'Lomankerrrytystapojen haku epäonnistui',
            Label: 'Lomankerrytystapa',
            MultipleSelected: 'Lomankerrytystavat',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: 'AnnualLeave/AnnualLeaveBonusSchedule/Identifier',
        ValuePickerId: 'AnnualLeaveBonusSchedule',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'AnnualLeaveBonusScheduleId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittua',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: 'AnnualLeave/AnnualLeaveBonusSchedule/Identifier',
        ValuePickerId: 'TenantAnnualLeaveBonusSchedule',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'TenantAnnualLeaveBonusScheduleId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittua',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: {
                GetDefaultItem: true,
            },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: 'AnnualLeave/AnnualLeaveBonusCalculationType/Identifier',
        ValuePickerId: 'AnnualLeaveBonusCalculationType',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'AnnualLeaveBonusCalculationTypeId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittua',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },

    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: 'AnnualLeave/AnnualLeaveBonusCalculationType/Identifier',
        ValuePickerId: 'TenantAnnualLeaveBonusCalculationType',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'TenantAnnualLeaveBonusCalculationTypeId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittua',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: {
                GetDefaultItem: true,
            },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: 'AnnualLeave/AnnualLeavePayType/Identifier',
        ValuePickerId: 'AnnualLeavePayType_Salary',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'AnnualLeavePayTypeId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittua',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: {
                MonthlyPayTypesOnly: true,
                SideEffectPrefix: 'Salary_',
            },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: 'AnnualLeave/AnnualLeavePayType/Identifier',
        ValuePickerId: 'AnnualLeavePayType_Wage14Days',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'AnnualLeavePayTypeId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittua',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: {
                HourlyPayTypesOnly: true,
                SideEffectPrefix: 'Wage14Days_',
            },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: 'AnnualLeave/AnnualLeavePayType/Identifier',
        ValuePickerId: 'AnnualLeavePayType_Wage35Hours',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'AnnualLeavePayTypeId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittua',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: {
                HourlyPayTypesOnly: true,
                SideEffectPrefix: 'Wage35Hours_',
            },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: 'AnnualLeave/AnnualLeavePayType/Identifier',
        ValuePickerId: 'AnnualLeavePayType_Mixed',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'AnnualLeavePayTypeId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittua',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: {
                HourlyPayTypesOnly: true,
                SideEffectPrefix: 'Mixed_',
            },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: 'AnnualLeave/DailyPayCoefficientTable/Identifier',
        ValuePickerId: 'DailyPayCoefficientTable',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'DailyPayCoefficientTableId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittua',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: 'AnnualLeave/HourlyPayCoefficientTable/Identifier',
        ValuePickerId: 'HourlyPayCoefficientTable',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'HourlyPayCoefficientTableId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittua',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/Tyontekija/ExternalType/Identifier',
        ValuePickerId: 'EmployeeExternalTypeFilter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'EmployeeExternalTypeIDs',
        Text: {
            FetchError: 'Ulkoisien työntekijöiden suodatusvalintojen haku epäonnistui',
            Label: 'Ulkoiset työntekijät',
            MultipleSelected: 'ulkoista työntekijää',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/Period/Type/Identifier',
        ValuePickerId: 'periodType_picker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PeriodTypeId',
        Text: {
            FetchError: 'Jaksotyyppien haku epäonnistui',
            Label: 'Jaksotyyppi',
            MultipleSelected: 'jaksotyyppiä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/Company/Identifier',
        ValuePickerId: 'company_picker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'CompanyId',
        Text: {
            FetchError: 'Yritysten haku epäonnistui',
            Label: 'Yritys',
            MultipleSelected: 'yritystä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/WorkTimeRule/RuleType/Identifier',
        ValuePickerId: 'RuleType',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'RuleTypeId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Sääntötyyppi',
            MultipleSelected: 'sääntötyyppiä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: '0',
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/WorkTimeRule/WorkTimeRuleEventFilterType/Identifier',
        ValuePickerId: 'WorkTimeRuleEventFilterType',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'WorkTimeRuleEventFilterTypeId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Tapahtumatyyppien suodatustyyppi',
            MultipleSelected: 'tapahtumatyyppien suodatustyyppiä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: 1,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/WorkTimeRule/BalanceAccumulationType/Identifier',
        ValuePickerId: 'BalanceAccumulationType',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'BalanceAccumulationTypeId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Työaikasaldon huomiointitapa',
            MultipleSelected: 'työaikasaldon huomiointitapaa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/WorkTimeRule/UnitOfTime/Identifier',
        ValuePickerId: 'UnitOfTime',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'UnitOfTimeId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Aikaluokka',
            MultipleSelected: 'aikaluokkaa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/WorkTimeRule/WorkTimeLimitType/Identifier',
        ValuePickerId: 'WorkTimeLimitType',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'WorkTimeLimitTypeId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Rajoitetyyppi',
            MultipleSelected: 'rajoitetyyppiä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: '0',
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/WorkTimeRule/WorkTimeLimitOverrideType/Identifier',
        ValuePickerId: 'WorkTimeLimitOverrideType',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'WorkTimeLimitOverrideTypeId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Rajoitusarvon yliajotyyppi',
            MultipleSelected: 'rajoitusarvon yliajotyyppiä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/WorkTimeRule/FunctionApplicationType/Identifier',
        ValuePickerId: 'FunctionApplicationType',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'FunctionApplicationTypeId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Sovellutustapa',
            MultipleSelected: 'sovellutustapaa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/v2/Tapahtuma/PoolSelectionType/Identifier',
        ValuePickerId: 'PoolSelectionType',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PoolSelectionTypeId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Poolisuodatusehto',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { DefaultConstraintSearch: true },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/v2/Tapahtuma/PoolSelectionType/Identifier',
        ValuePickerId: 'PoolSelectionType_WorkUnit_Reservation',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PoolSelectionTypeId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Poolisuodatusehto',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { WorkUnitSearch: true, SideEffectPostfix: 'Reservation' },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/v2/Tapahtuma/PoolSelectionType/Identifier',
        ValuePickerId: 'PoolSelectionType_WorkUnit_Request',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PoolSelectionTypeId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Poolisuodatusehto',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { WorkUnitSearch: true, SideEffectPostfix: 'Request' },
        },
    },

    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/v2/Tapahtuma/EventReservationStatus/Identifier',
        ValuePickerId: 'EventReservationStatus_filter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'EventReservationStatuses',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Status',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/Common/Operator/Identifier',
        ValuePickerId: 'StartOperator',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'OperatorId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Operaattori',
            MultipleSelected: 'operaattoria',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: 6,
        AdditionalDataFetchParameters: {
            Filters: { StartOperators: true },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/Common/Operator/Identifier',
        ValuePickerId: 'EndOperator',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'OperatorId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Operaattori',
            MultipleSelected: 'operaattoria',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: 3,
        AdditionalDataFetchParameters: {
            Filters: { EndOperators: true },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/CalendarEvent/CalendarEventClass/Identifier',
        ValuePickerId: 'CalendarEventClass',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'CalendarEventClassId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Kalenteritapahtumaluokka',
            MultipleSelected: 'kalenteritapahtumaluokkaa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/CalendarEvent/CalendarEventType/Identifier',
        ValuePickerId: 'CalendarEventType',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'CalendarEventTypeId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Kalenteritapahtumaselite',
            MultipleSelected: 'kalenteritapahtumaselitettä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/Common/Month/Identifier',
        ValuePickerId: 'Month',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'Month',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Kuukausi',
            MultipleSelected: 'kuukautta',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [{ ValuePickerId: 'FormView_ValuePicker_Tes', ParameterName: 'CollectiveAgreementIds' }],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/WorkTimeRule/Identifier',
        ValuePickerId: 'WorkTimeRule_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'WorkTimeRuleIds',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Työaikasääntö',
            MultipleSelected: 'työaikasääntöä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/CollectiveAgreement/PublicHolidayReductionCondition/Identifier',
        ValuePickerId: 'PublicHolidayReductionCondition',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PublicHolidayReductionConditionIds',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [
            { ValuePickerId: 'FormView_ValuePicker_WorkUnit', ParameterName: 'WorkUnitIds' },
            { ValuePickerId: 'FormView_ValuePicker_Tes_No_Dependencies', ParameterName: 'CollectiveAgreementIds' },
        ],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/CollectiveAgreement/PayScale/Identifier',
        ValuePickerId: 'PayScale',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PayScaleIds',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Palkkaluokka',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [
            { ValuePickerId: 'FormView_ValuePicker_Tes_No_Dependencies', ParameterName: 'CollectiveAgreementIds' },
        ],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/CollectiveAgreement/ExperienceLevel/Identifier',
        ValuePickerId: 'ExperienceLevel',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'ExperienceLevelIds',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Kokemustaso',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [
            { ValuePickerId: 'FormView_ValuePicker_Tes_No_Dependencies', ParameterName: 'CollectiveAgreementIds' },
        ],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/CollectiveAgreement/ExperienceLevelCategory/Identifier',
        ValuePickerId: 'ExperienceLevelCategory',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'ExperienceLevelCategoryIds',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Kokemustasokategoria',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: 1,
    },
    {
        DependsOn: [
            { ValuePickerId: 'FormView_ValuePicker_Tes_No_Dependencies', ParameterName: 'CollectiveAgreementIds' },
            { ValuePickerId: 'PayScale', ParameterName: 'PayScaleIds' },
        ],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/CollectiveAgreement/PayScaleExperienceLevel/Identifier',
        ValuePickerId: 'ExperienceLevel_PayGradeSelector',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'ExperienceLevelIds',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Kokemustaso',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: {
                IsPayGradeSelectorSearch: true,
            },
        },
    },
    {
        DependsOn: [
            { ValuePickerId: 'FormView_ValuePicker_Tes_No_Dependencies', ParameterName: 'CollectiveAgreementIds' },
        ],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/CollectiveAgreement/FixedTime/Identifier',
        ValuePickerId: 'FixedTime',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'CollectiveAgreementFixedTimeIds',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/CollectiveAgreement/PartialSalaryWorkDayCalculationType/Identifier',
        ValuePickerId: 'PartialSalaryWorkDayCalculationType',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PartialSalaryWorkDayCalculationTypeIds',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/CollectiveAgreement/PartialSalaryCalculationType/Identifier',
        ValuePickerId: 'PartialSalaryCalculationType',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PartialSalaryCalculationTypeIds',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: 'v2/Tapahtuma/EventTypeSalaryType/Identifier',
        ValuePickerId: 'EventTypeSalaryType',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'EventTypeSalaryTypeIds',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: 1,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/CollectiveAgreement/PublicHolidayReductionAmountCalculationType/Identifier',
        ValuePickerId: 'PublicHolidayReductionAmountCalculationType',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PublicHolidayReductionAmountCalculationTypeIds',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/Asiakas/EventConfirmationType/Identifier',
        ValuePickerId: 'EventConfirmationType_Picker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'EventConfirmationTypeId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: 1,
    },
    {
        DependsOn: [
            { ValuePickerId: 'WorkUnitId_HiddenValuePicker', ParameterName: 'WorkUnitIds' },
            { ValuePickerId: 'area_picker', ParameterName: 'alueIdt' },
        ],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/Asiakas/EventConfirmationContactPerson/Identifier',
        ValuePickerId: 'EventConfirmationContactPerson_Picker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'EventConfirmationContactPersonLoginIds',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [{ ValuePickerId: 'WorkUnitId_HiddenValuePicker', ParameterName: 'WorkUnitIds' }],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/Asiakas/EventConfirmationContactPersonContactInfo/Identifier',
        ValuePickerId: 'EventConfirmationContactPersonContactInfo_Picker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'EventConfirmationContactPersonContactInfoIds',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Kaikki yhteyshenkilöt',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ValuePickerId: 'WorkUnitId_HiddenValuePicker',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: 'WorkUnitIds',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ValuePickerId: 'WorkUnitId_HiddenValuePicker_FilterFilter',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: null,
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ValuePickerId: 'EmployeeConversation_HiddenValuePicker',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: 'Types',
        DefaultValues: [1],
    },
    {
        DependsOn: [],
        ValuePickerId: 'EmployeeBulletin_HiddenValuePicker',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: 'Types',
        DefaultValues: [2],
    },
    {
        DependsOn: [],
        ValuePickerId: 'Comment_HiddenValuePicker',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: 'Types',
        DefaultValues: [3],
    },
    {
        DependsOn: [],
        ValuePickerId: 'CommentOnlyNewestThread_HiddenValuePicker',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: 'ShowOnlyNewestThreadOfEvent',
        DefaultValues: true,
    },
    {
        DependsOn: [],
        ValuePickerId: 'GroupSimilarItems_HiddenValuePicker',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: 'GroupSimilarItems',
        DefaultValues: true,
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'RestrictWorkHistoryToContract',
        DefaultValues: false,
        ValuePickerId: 'RestrictWorkHistoryToContract',
        Label: 'RestrictWorkHistoryToContract',
        TrueValueSideEffects: [
            {
                key: 'FileTemplateRestrictWorkHistoryFalse',
                value: 'hide',
            },
            {
                key: 'FileTemplateRestrictWorkHistoryTrue',
                value: 'show',
            },
        ],
        FalseValueSideEffects: [
            {
                key: 'FileTemplateRestrictWorkHistoryFalse',
                value: 'show',
            },
            {
                key: 'FileTemplateRestrictWorkHistoryTrue',
                value: 'hide',
            },
        ],
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'ShowOnlyUnReadThreads',
        DefaultValues: false,
        ValuePickerId: 'ShowOnlyUnReadThreads',
        Label: 'Näytä vain lukemattomat',
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'EnforceNonLimitedSearch',
        DefaultValues: false,
        ValuePickerId: 'EnforceNonLimitedSearch',
        Label: 'Hae yli 250',
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'HasUnreadMessages',
        DefaultValues: false,
        ValuePickerId: 'HasUnreadMessages',
        Label: 'Lukemattomia viestejä',
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'ShowOnlyOwnThreads',
        DefaultValues: false,
        ValuePickerId: 'ShowOnlyOwnThreads',
        Label: 'Näytä vain omat viestiketjut',
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'ShowOnlyOwnThreads',
        DefaultValues: false,
        ValuePickerId: 'ShowOnlyOwnThreads',
        Label: 'Näytä vain omat viestiketjut',
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'GroupByMonth',
        DefaultValues: false,
        ValuePickerId: 'GroupByMonth',
        Label: 'Ryhmittele kuukausittain',
    },
    {
        DependsOn: [
            { ValuePickerId: 'FormView_ValuePicker_WorkUnit', ParameterName: 'WorkUnitIds' },
            { ValuePickerId: 'area_picker', ParameterName: 'AlueIdt' },
            { ValuePickerId: 'WorkUnitGroup_ValuePicker', ParameterName: 'WorkUnitGroupIds' },
        ],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/Asiakas/EventConfirmationWorkUnit/Identifier',
        ValuePickerId: 'EventConfirmationWorkUnit_Picker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'EventConfirmationWorkUnitIds',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'PublishedEventsOnly',
        DefaultValues: false,
        ValuePickerId: 'ShowOnlyPublishedEventsFilter',
        Label: 'Näytä vain julkaistut',
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'ShowOnlyEventsWithEmployee',
        DefaultValues: false,
        ValuePickerId: 'ShowOnlyEventsWithEmployeeFilter',
        Label: 'Näytä vain täytetyt',
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'ShowOnlyEventsWithoutEmployee',
        DefaultValues: false,
        ValuePickerId: 'ShowOnlyEventsWithoutEmployee',
        Label: 'Näytä vain täyttämättömät',
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'ShowAlsoEventsWithConfirmation',
        DefaultValues: false,
        ValuePickerId: 'ShowAlsoEventsWithConfirmationFilter',
        Label: 'Näytä myös vahvistetut',
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'GroupSimilarEvents',
        DefaultValues: false,
        ValuePickerId: 'GroupSimilarEvents',
        Label: 'Ryhmittele tapahtumat',
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'IncludeDeleted',
        DefaultValues: false,
        ValuePickerId: 'IncludeDeleted',
        Label: 'Näytä myös poistetut',
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'IsProcessable',
        DefaultValues: false,
        ValuePickerId: 'IsEventTypeProcessable',
        Label: 'Käsiteltävä tapahtumatyyppi',
        TrueValueSideEffects: [
            {
                key: 'IsProcessable',
                value: 'show',
            },
        ],
        FalseValueSideEffects: [
            {
                key: 'IsProcessable',
                value: 'hide',
            },
        ],
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/Asiakas/InvoicingType/Identifier',
        ValuePickerId: 'InvoicingType_Picker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'InvoicingTypeId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: 1,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/Asiakas/invoiceChannel/Identifier',
        ValuePickerId: 'InvoiceChannel_Picker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'InvoiceChannelId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: '',
            MultipleSelected: 'valittu',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: 1,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Company/Identifier',
        ValuePickerId: 'PayerCompanies_Filter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PayerCompanyIds',
        Text: {
            FetchError: 'Palkanmaksavien yritysten haku epäonnistui',
            Label: 'Palkanmaksava yritys',
            MultipleSelected: 'palkanmaksavaa yritystä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Tyosuhde/InvoicingGroup/Identifier',
        ValuePickerId: 'InvoicingGroup_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'InvoicingGroupId',
        Text: {
            FetchError: 'Laskutusryhmien haku epäonnistui',
            Label: 'Laskutusryhmä',
            MultipleSelected: 'laskutusryhmää',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Tyosuhde/InvoicingGroup/Identifier',
        ValuePickerId: 'InvoicingGroup_Filter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'InvoicingGroupIds',
        Text: {
            FetchError: 'Laskutusryhmien haku epäonnistui',
            Label: 'Laskutusryhmä',
            MultipleSelected: 'laskutusryhmää',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/Identifier',
        ValuePickerId: 'attachment_WorkUnit',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'asiakasIdt',
        Text: {
            FetchError: 'Työkohteiden haku epäonnistui',
            Label: 'Työkohteet',
            MultipleSelected: 'työkohdetta',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 400,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Customer/Identifier',
        ValuePickerId: 'attachment_Customer',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'CustomerIds',
        Text: {
            FetchError: 'Asiakkaiden haku epäonnistui',
            Label: 'Asiakkaat',
            MultipleSelected: 'asiakasta',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 400,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/FileCategory/Identifier',
        ValuePickerId: 'FileCategory_WorkUnit_ValuePicker',
        ValuePickerType: EValuePickerType.RadioButtonListValuePicker,
        DataRequestParameterName: 'FileCatagory',
        Text: {
            FetchError: 'Tiedostokategorioiden haku epäonnistui',
            Label: 'Tiedostokategoria',
            MultipleSelected: 'Tiedostokategoriaa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: 5,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Kayttaja/AuthorizationTargetType/Identifier',
        ValuePickerId: 'AuthorizationTargetType_ValuePicker',
        ValuePickerType: EValuePickerType.RadioButtonListValuePicker,
        DataRequestParameterName: 'AuthorizationTargetTypeId',
        Text: {
            FetchError: 'Käyttöoikeuskohdetyyppien haku epäonnistui',
            Label: 'Käyttöoikeuskohdetyyppi',
            MultipleSelected: 'Käyttöoikeuskohdetyyppejä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
        valuePickerComponentProperties: {
            IsInline: true,
        },
    },
    {
        DependsOn: [
            {
                ValuePickerId: 'IsExternalUser_TogglePicker',
                ParameterName: 'IsExternalUser',
            },
        ],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Kayttaja/AuthorizationTargetType/Identifier',
        ValuePickerId: 'AuthorizationTargetType_ValuePicker_BaronaHybrid',
        ValuePickerType: EValuePickerType.RadioButtonListValuePicker,
        DataRequestParameterName: 'AuthorizationTargetTypeId',
        Text: {
            FetchError: 'Käyttöoikeuskohdetyyppien haku epäonnistui',
            Label: 'Käyttöoikeuskohdetyyppi',
            MultipleSelected: 'Käyttöoikeuskohdetyyppejä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: true,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
        valuePickerComponentProperties: {
            IsInline: true,
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Poistosyy/Identifier',
        ValuePickerId: 'ReasonForCancelling_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'cancellationReasonIds',
        Text: {
            FetchError: 'Vuoron peruutuksen syiden haku epäonnistui',
            Label: 'Peruutuksen syy',
            MultipleSelected: 'Peruutuksen syytä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [
            {
                ValuePickerId: 'ReasonForCancelling_ValuePicker',
                ParameterName: 'cancellationReasonIds',
            },
        ],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/v2/Tapahtuma/Tyyppi/Identifier',
        ValuePickerId: 'FormView_Tapahtumatyyppi_CancellationReason',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'tapahtumatyyppiIdt',
        Text: {
            FetchError: 'Poissaolotapahtumatyyppien haku epäonnistui',
            Label: 'Poissaolon tapahtumatyyppi',
            MultipleSelected: 'poissaolon tapahtumatyyppiä',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: {
                NoCache: true,
                LeaveTypesOnly: true,
            },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: false,
        OptionsEndpointUrl: '/Messaging/MessageTemplateType/Identifier',
        ValuePickerId: 'MessageTemplateType_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'MessageTemplateTypeId',
        Text: {
            FetchError: 'Haku epäonnistui',
            Label: 'Viestipohja',
            MultipleSelected: 'viestipohjaa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: 1,
    },
    {
        ValuePickerType: EValuePickerType.SubPricePicker,
        DependsOn: [],
        ValuePickerId: 'SubPriceInput',
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/v2/Tapahtuma/EventTypeTag/Identifier',
        ValuePickerId: 'EventTypeTag_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'EventTypeTagIds',
        Text: {
            FetchError: 'Tapahtumatyyppimerkintöjen haku epäonnistui',
            Label: 'Tapahtumatyyppimerkintä',
            MultipleSelected: 'tapahtumatyyppimerkintää',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'IncludeProcessed',
        DefaultValues: false,
        ValuePickerId: 'IncludeProcessed',
        Label: 'Näytä myös käsitellyt',
    },
    {
        ValuePickerId: 'suitable_employee_jobtitle_filter',
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Ammattinimike/Identifier',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'OverridingJobTitleIds',
        Text: {
            FetchError: 'Ammattinimikkeiden haku epäonnistui',
            Label: 'Laajenna hakua ammattinimikkeille',
            MultipleSelected: 'ammattinimikettä',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Team/Identifier',
        ValuePickerId: 'TeamPicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'TeamIds',
        Text: {
            FetchError: 'Tiimien haku epäonnistui',
            Label: 'Tiimit',
            MultipleSelected: 'tiimiä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Team/Identifier',
        ValuePickerId: 'Team_Filter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'TeamIds',
        Text: {
            FetchError: 'Tiimien haku epäonnistui',
            Label: 'Tiimit',
            MultipleSelected: 'tiimiä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Kayttaja/Supervisor/Identifier',
        ValuePickerId: 'TeamSupervisorPicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'SupervisorId',
        Text: {
            FetchError: 'Esihenkilöiden haku epäonnistui',
            Label: 'Esihenkilö',
            MultipleSelected: 'esihenkilöä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: true,
        Limit: 400,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/CostCode/Identifier',
        ValuePickerId: 'CostCode_filter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'CostCodeIds',
        Text: {
            FetchError: 'Kustannuspaikkojen haku epäonnistui',
            Label: 'Kustannuspaikat',
            MultipleSelected: 'kustannuspaikkaa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/WorkPreference/Type/Identifier',
        ValuePickerId: 'WorkPreferenceType_ValuePicker',
        ValuePickerType: EValuePickerType.RadioButtonListValuePicker,
        DataRequestParameterName: '',
        Text: {
            FetchError: 'Sulkulistan tyypin haku epäonnistui',
            Label: '',
            MultipleSelected: '',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: 1,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/WorkUnitGroup/Identifier',
        ValuePickerId: 'WorkUnitGroup_ValuePicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'ParentId',
        Text: {
            FetchError: 'Organisaatioiden haku epäonnistui',
            Label: 'Organisaatio',
            MultipleSelected: 'Organisaatiota',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: true,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Id', 'Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/WorkUnitGroup/Identifier',
        ValuePickerId: 'WorkUnitGroup_Filter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'WorkUnitGroupIds',
        Text: {
            FetchError: 'Organisaatioiden haku epäonnistui',
            Label: 'Organisaatio',
            MultipleSelected: 'Organisaatiota',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: true,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/WorkUnitGroup/Identifier',
        ValuePickerId: 'WorkUnitGroupTopLevel_Filter',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'WorkUnitGroupIds',
        Text: {
            FetchError: 'Organisaatioiden haku epäonnistui',
            Label: 'Päätason organisaatiot',
            MultipleSelected: 'Organisaatiota',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: true,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            Filters: { GetOnlyTopLevelItems: true },
        },
    },
    {
        DependsOn: [],
        ValuePickerId: 'IsExternalUser_HiddenValuePicker',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: 'IsExternalUser',
        DefaultValues: true,
    },
    {
        DependsOn: [],
        ValuePickerId: 'AuthorizationTargetType_HiddenValuePicker',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: 'AuthorizationTargetTypeId',
        DefaultValues: 2,
    },
    {
        DependsOn: [],
        ValuePickerId: 'ShowOnlyExternalUsers_HiddenValuePicker',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: 'OnlyExternalUsers',
        DefaultValues: true,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/FileSubCategory/Identifier',
        ValuePickerId: 'WorkUnit_FileSubCategory_ValuePicker',
        ValuePickerType: EValuePickerType.RadioButtonListValuePicker,
        DataRequestParameterName: 'FileSubCategory',
        Text: {
            FetchError: 'Tiedostoalikategorioiden haku epäonnistui',
            Label: '',
            MultipleSelected: '',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: 0,
        valuePickerComponentProperties: {
            hasTopDivider: true,
        },
    },
    {
        ValuePickerType: EValuePickerType.CheckboxPicker,
        DependsOn: [],
        DataRequestParameterName: 'RequestReadReceipt',
        DefaultValues: false,
        ValuePickerId: 'RequestReadReceipt_CheckboxPicker',
        TrueValueSideEffects: [
            {
                key: 'UpdateEmployeeSkill',
                value: 'show',
            },
        ],
        FalseValueSideEffects: [
            {
                key: 'UpdateEmployeeSkill',
                value: 'hide',
            },
            {
                key: 'SkillId',
                value: 'hide',
            },
            {
                key: 'SkillValidThruDate',
                value: 'hide',
            },
        ],
    },
    {
        DependsOn: [],
        ValuePickerId: 'fileIds_hidden',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: 'FileId',
        DefaultValues: null,
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'ShowOnlyPendingReadReceipts',
        DefaultValues: false,
        ValuePickerId: 'showOnlyPendingReadReceipts_toggle',
        Label: 'Näytä vain kuittaamattomat',
    },
    {
        DependsOn: [],
        ValuePickerId: 'Hidden_WorkUnitGroupId_Filter',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: 'SuitableForWorkUnitGroupIds',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/FileCategory/Identifier',
        ValuePickerId: 'employee_fileCategory_valuePicker',
        ValuePickerType: EValuePickerType.RadioButtonListValuePicker,
        DataRequestParameterName: 'FileCategory',
        Text: {
            FetchError: 'Tiedostokategorioiden haku epäonnistui',
            Label: '',
            MultipleSelected: '',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: 0,
        valuePickerComponentProperties: {
            IsInline: true,
        },
    },
    {
        ValuePickerType: EValuePickerType.CheckboxPicker,
        DependsOn: [],
        DataRequestParameterName: 'IsVisibleForEmployee',
        DefaultValues: false,
        ValuePickerId: 'IsVisibleForEmployee_checkboxPicker',
        TrueValueSideEffects: [
            {
                key: 'RequestReadReceipt',
                value: 'show',
            },
        ],
        FalseValueSideEffects: [
            {
                key: 'RequestReadReceipt',
                value: 'hide',
            },
            {
                key: 'UpdateEmployeeSkill',
                value: 'hide',
            },
            {
                key: 'SkillId',
                value: 'hide',
            },
            {
                key: 'SkillValidThruDate',
                value: 'hide',
            },
        ],
    },
    {
        DependsOn: [],
        ValuePickerId: 'fileCategory_hidden',
        ValuePickerType: EValuePickerType.Hidden,
        DataRequestParameterName: 'FileCategory',
        DefaultValues: null,
    },
    {
        ValuePickerType: EValuePickerType.CheckboxPicker,
        DependsOn: [],
        DataRequestParameterName: 'UpdateEmployeeSkill',
        DefaultValues: false,
        ValuePickerId: 'readReceiptUpdatesSkill_checkboxPicker',
        TrueValueSideEffects: [
            {
                key: 'SkillId',
                value: 'required',
            },
            {
                key: 'SkillValidThruDate',
                value: 'required',
            },
        ],
        FalseValueSideEffects: [
            {
                key: 'SkillId',
                value: 'hide',
            },
            {
                key: 'SkillValidThruDate',
                value: 'hide',
            },
        ],
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: 'v2/Tapahtuma/TimePeriod/Identifier',
        ValuePickerId: 'TimePeriodPicker',
        ValuePickerType: EValuePickerType.RadioButtonListValuePicker,
        DataRequestParameterName: 'TimePeriod',
        Text: {
            FetchError: 'Aikavälien haku epäonnistui',
            Label: 'Aikaväli',
            MultipleSelected: 'Aikavälejä',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: 2,
        valuePickerComponentProperties: {
            IsInline: true,
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['DisplayId', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Identifier',
        ValuePickerId: 'EmployeeWorkTimeBalanceDetails_Employee',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'EmployeeIds',
        Text: {
            FetchError: 'Työntekijöiden haku epäonnistui',
            Label: 'Työntekijät',
            MultipleSelected: 'työntekijää',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 400,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ValuePickerId: 'DateRangeStartOfPicker',
        ValuePickerType: EValuePickerType.DateRange,
        DataRequestParameterName: 'DateRange',
        DefaultValues: {
            StartFromCurrentDay: -1,
            StartFromCurrentDayUnit: 'month',
            EndFromCurrentDay: 0,
            EndFromCurrentDayUnit: 'month',
            StartOfUnit: 'month',
        },
    },
    {
        ValuePickerType: EValuePickerType.Toggle,
        DependsOn: [],
        DataRequestParameterName: 'IsManualOfferingByDefault',
        DefaultValues: false,
        ValuePickerId: 'IsManualOfferingByDefault',
        Label: 'Manuaalinen tarjoaminen oletuksena',
    },
    {
        DependsOn: [],
        ValuePickerType: EValuePickerType.DateRange,
        ValuePickerId: 'EmployeeContractDateRange',
        DataRequestParameterName: 'DateRange',
        DefaultValues: {
            StartFromCurrentDay: -1,
            StartFromCurrentDayUnit: 'month',
            EndFromCurrentDay: 0,
            EndFromCurrentDayUnit: 'month',
            StartOfUnit: 'month',
        },
    },
    {
        DependsOn: [],
        ValuePickerType: EValuePickerType.Toggle,
        ValuePickerId: 'ShowOnlyCurrentlyValidContracts',
        DataRequestParameterName: 'IsOnlyCurrentlyValidEnabled',
        DefaultValues: true,
        Label: 'Näytä vain voimassa olevat',
    },
    {
        DependsOn: [],
        ValuePickerId: 'EmployeeExternalLinkPicker',
        ValuePickerType: EValuePickerType.LinkPicker,
        DataRequestParameterName: 'ExternalLink',
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Skill/WorkUnitTargetType/Identifier',
        ValuePickerId: 'WorkUnitTargetTypePicker',
        ValuePickerType: EValuePickerType.RadioButtonListValuePicker,
        DataRequestParameterName: 'WorkUnitTargetTypeId',
        Text: {
            FetchError: 'Työkohdekohdistusten haku epäonnistui',
            Label: 'Työkohdekohdistus',
            MultipleSelected: 'Työkohdekohdistusta',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
        valuePickerComponentProperties: {
            IsInline: true,
        },
    },
    {
        DependsOn: [
            {
                ValuePickerId: 'WorkforceScheduling_Event_Form_WorkUnit',
                ParameterName: 'WorkUnitIds',
            },
        ],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Skill/WorkUnit/Identifier',
        ValuePickerId: 'SkillWorkUnitPicker',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'SkillIds',
        Text: {
            FetchError: 'Erikoistaitojen haku epäonnistui',
            Label: 'Erikoistaidot',
            MultipleSelected: 'erikoistaitoa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    ...tyontekijaAlueValuePickers,
    ...maintenanceViewValuePickerConfigurations,
]

export default valuePickerConfigurations
