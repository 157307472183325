import IEvent, { EEventTypeCategory } from '../../event/Types/IEvent'
import { PlanierTheme } from '../../theme'

interface IDayState {
    hasAnnualLeave: boolean
    hasEventGroup: boolean
    hasNotReservingGroup: boolean
    hasOtherNotWorktime: boolean
    hasOtherWorkTime: boolean
    hasWorktimePreference: boolean
    hasReservingGroup: boolean
    hasSickLeave: boolean
    hasTimeoffPreference: boolean
    hasWorkShiftEvent: boolean
}

export const parseDayState = (events: IEvent[]): IDayState => {
    const hasWorkShiftEvent = events.findIndex((event) => event.EventTypeCategory?.Id === EEventTypeCategory.Shift) > -1
    const hasWorktimePreference =
        events.findIndex((event) => event.EventTypeCategory?.Id === EEventTypeCategory.WorktimePreference) > -1
    const hasTimeoffPreference =
        events.findIndex((event) => event.EventTypeCategory?.Id === EEventTypeCategory.OfftimePreference) > -1
    const hasReservingGroup =
        events.findIndex((event) => event.EventTypeCategory?.Id === EEventTypeCategory.ReservingEventGroup) > -1
    const hasNotReservingGroup =
        events.findIndex((event) => event.EventTypeCategory?.Id === EEventTypeCategory.NonReservingEventGroup) > -1
    const hasEventGroup =
        events.findIndex((event) => event.EventTypeCategory?.Id === EEventTypeCategory.EventGroup) > -1
    const hasOtherWorkTime =
        events.findIndex((event) => event.EventTypeCategory?.Id === EEventTypeCategory.OtherWorktime) > -1
    const hasOtherNotWorktime =
        events.findIndex((event) => event.EventTypeCategory?.Id === EEventTypeCategory.OtherNotWorktime) > -1
    const hasAnnualLeave =
        events.findIndex((event) => event.EventTypeCategory?.Id === EEventTypeCategory.AnnualLeave) > -1
    const hasSickLeave = events.findIndex((event) => event.EventTypeCategory?.Id === EEventTypeCategory.SickLeave) > -1

    return {
        hasAnnualLeave,
        hasEventGroup,
        hasNotReservingGroup,
        hasOtherNotWorktime,
        hasOtherWorkTime,
        hasWorktimePreference,
        hasReservingGroup,
        hasSickLeave,
        hasTimeoffPreference,
        hasWorkShiftEvent,
    }
}

const getDotPropsMap = ({ colors }: PlanierTheme) => ({
    [EEventTypeCategory.Shift]: {
        color: colors.primaryTeal60,
        fillStyle: 'full',
    },
    [EEventTypeCategory.OfftimePreference]: {
        color: colors.semanticWarning100,
        fillStyle: 'cross',
    },
    [EEventTypeCategory.WorktimePreference]: {
        color: colors.semanticSuccess100,
        fillStyle: 'hollow',
    },
    [EEventTypeCategory.ReservingEventGroup]: {
        color: colors.neutralsGrey60,
        fillStyle: 'hollow',
    },
    [EEventTypeCategory.NonReservingEventGroup]: {
        color: colors.neutralsGrey60,
        fillStyle: 'hollow',
    },
    [EEventTypeCategory.EventGroup]: {
        color: colors.neutralsGrey60,
        fillStyle: 'hollow',
    },
    [EEventTypeCategory.OtherNotWorktime]: {
        color: '#a49ada',
        fillStyle: 'hollow',
    },
    [EEventTypeCategory.OtherWorktime]: {
        color: '#a49ada',
        fillStyle: 'full',
    },
    [EEventTypeCategory.AnnualLeave]: {
        color: colors.secondaryYellow100,
        fillStyle: 'full',
    },
    [EEventTypeCategory.SickLeave]: {
        color: colors.secondaryPink100,
        fillStyle: 'full',
    },
})

export const dotProps = (eventType: EEventTypeCategory, theme: PlanierTheme): Record<string, string> => {
    return (
        getDotPropsMap(theme)[eventType] || {
            color: theme.colors.primaryCyan11,
            fillStyle: 'full',
        }
    )
}

export const getEventColor = (category: EEventTypeCategory, theme: PlanierTheme): string => {
    const { colors } = theme

    switch (category) {
        case EEventTypeCategory.Shift:
            return colors.primaryTeal30
        case EEventTypeCategory.AnnualLeave:
            return colors.secondaryYellow30
        case EEventTypeCategory.SickLeave:
            return colors.secondaryPink60
        case EEventTypeCategory.OtherWorktime:
            return '#d2cced'
        case EEventTypeCategory.OtherNotWorktime:
            return colors.primaryChampion11

        default:
            return colors.primaryChampion11
    }
}

export const dayColorLogic = (dayState: IDayState): EEventTypeCategory => {
    const eventMapping: Record<string, EEventTypeCategory> = {
        hasWorkShiftEvent: EEventTypeCategory.Shift,
        hasAnnualLeave: EEventTypeCategory.AnnualLeave,
        hasSickLeave: EEventTypeCategory.SickLeave,
        hasOtherWorkTime: EEventTypeCategory.OtherWorktime,
        hasOtherNotWorktime: EEventTypeCategory.OtherNotWorktime,
    }

    for (const condition in eventMapping) {
        if ((dayState as any)[condition]) {
            return eventMapping[condition]
        }
    }

    return EEventTypeCategory.OtherNotWorktime
}
