import AsiakasYksikko from 'components/atoms/AsiakasYksikko'
import AsiakasYksikkoId from 'components/atoms/AsiakasYksikkoId'
import { ChangeOperatorElement } from '../muutosloki-components'
import {
    CheckIcon,
    Date,
    Icon,
    LockIcon,
    PoolTypeIcon,
    StatusIcon,
    SystemInternalLink,
    UserBadge,
} from '../generic-components'
import Tehtava from 'components/molecules/Tehtava'
import Tes from 'components/atoms/Tes'
import TimeRange from 'components/molecules/TimeRange'
import Tyontekija from 'components/molecules/Tyontekija'
import TyontekijaId from 'components/molecules/TyontekijaId'
import WeekDay from 'components/atoms/WeekDay'
import {
    DurationInHours,
    HyvaksyntaStatus,
    LunchTimeTaken,
    MonetaryAmount,
    ProjectNumber,
    Tapahtumatyyppi,
    Tuntipalkka,
    TyoaikatapahtumaLisatiedot,
} from '../tapahtuma'
import {
    EmployeeLink,
    EmployeeLinkLegacy,
    ListElementBoolean,
    ListElementColor,
    ListElementDateRange,
    ListElementDateTime,
    ListElementOfferResponseStatus,
    ListElementReservationStatus,
    ListElementTagList,
    ListElementText,
    ListElementTime,
    ListElementWarning,
    UserNameWithBadge,
    WorkunitLink,
    WorkunitLinkLegacy,
} from '../configurable-list-components'

/**
 * Defines the matching components for the configurable list's
 * column configuration's ViewComponentId field.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getListComponents = () => ({
    AsiakasYksikko,
    AsiakasYksikkoId,
    ChangeOperatorElement,
    Date,
    DurationInHours,
    HyvaksyntaStatus,
    Icon,
    ListElementBoolean,
    ListElementDateRange,
    ListElementTagList,
    ListElementText,
    ListElementDateTime,
    ListElementTime,
    ListElementColor,
    ListElementWarning,
    ListElementReservationStatus,
    ListElementOfferResponseStatus,
    LunchTimeTaken,
    MonetaryAmount,
    ProjectNumber,
    SystemInternalLink,
    Tapahtumatyyppi,
    Tehtava,
    Tes,
    TimeRange,
    Tuntipalkka,
    TyoaikatapahtumaLisatiedot,
    Tyontekija,
    TyontekijaId,
    WeekDay,
    CheckIcon,
    PoolTypeIcon,
    StatusIcon,
    UserBadge,
    EmployeeLink,
    EmployeeLinkLegacy,
    WorkunitLink,
    WorkunitLinkLegacy,
    LockIcon,
    UserNameWithBadge,
})
