import * as React from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import styled from '@emotion/styled'

import {
    ConfigurableListTemplate,
    IConfigurableListModalProps as IclModalProps,
    selectSelectedItemIds,
} from '../../configurable-list'
import { closeModalAction, ModalBase, selectIsModalOpen, selectModalProps } from '../../modal'
import { IDataSourceItem, TDataSourceItemId } from '../../data-source-types'
import { LoadingButton } from '../../generic-components'
import { Translation } from '../../localization'
import { ISummaryLabel } from '../../generic-components/SummaryLabel/SummaryLabels'
import { isPlainObject } from 'lodash-es'

const defaultSelectedItemIds: ReadonlySet<TDataSourceItemId> = new Set()

interface IOwnProps {
    modalId: string
    listId: string
    onListRowSelect?: (item: IDataSourceItem) => void
}

interface IStateProps {
    modalOpen: boolean
    modalProps: Record<string, unknown> | undefined
    selectedItemIds: ReadonlySet<TDataSourceItemId>
}

interface IDispatchProps {
    closeModal: typeof closeModalAction
}

interface IConfigurableListModalProps extends IStateProps, IDispatchProps, IOwnProps {
    summaryLabels: ISummaryLabel[]
}

const getConfigurableListModalProps = (modalProps: Partial<IclModalProps> | undefined): IclModalProps => {
    if (!isPlainObject(modalProps) || typeof (modalProps as IclModalProps).title !== 'string') {
        return { title: '', listId: '' }
    }

    return modalProps as IclModalProps
}

type Diff<T, U> = T extends U ? never : T
type TSaveButton = Diff<IclModalProps['saveButton'], undefined>
const getSaveButtonLabel = (saveButton: TSaveButton) => {
    const { label } = saveButton

    if (!label) {
        return Translation.translateKey('configurable-list-modal.SaveButton.Label.Default')
    }
    return Translation.has(label) ? Translation.translateKey(label) : label
}

const classNameContentContainer = 'pln-ConfigurableListModal-Modal-ContentContainer'

const StyledModalBase = styled(ModalBase)`
    .${classNameContentContainer} {
        padding: 0;
        /* Replace Grid with a normal div in ValuePickerGroup to get rid of overflow. */
        overflow-x: hidden;
    }
`

const ConfigurableListModal: React.FunctionComponent<IConfigurableListModalProps> = ({
    closeModal,
    listId,
    modalId,
    modalOpen,
    modalProps,
    onListRowSelect,
    selectedItemIds,
    summaryLabels,
}) => {
    const [isLoading, setIsLoading] = useState(false)

    const handleModalClose = () => {
        closeModal(modalId)
    }

    const handleListRowSelect = (item: IDataSourceItem) => {
        const props = getConfigurableListModalProps(modalProps)
        if (onListRowSelect) {
            onListRowSelect(item)
        } else {
            if (props && props.onListRowSelect) {
                props.onListRowSelect(item)
            }
        }
        if (!props.keepModalOpenOnListRowSelect) {
            closeModal(modalId)
        }
    }

    if (!modalOpen) {
        return null
    }

    const handleSaveButtonClick = async (button) => {
        if (!saveButton) {
            throw new Error('saveButton unexpectedly false!')
        }

        setIsLoading(true)

        await button.onClick(selectedItemIds)
        setIsLoading(false)

        handleModalClose()
    }

    const configurableListModalProps = getConfigurableListModalProps(modalProps)
    const {
        additionalDataSourceDataRequestFiltersParameters,
        hiddenValuePickerIds,
        title,
        initialValuePickersValues,
        saveButton,
        shouldEmptyListDataWhenClosingModal,
    } = configurableListModalProps

    const extraButtons =
        saveButton && Array.isArray(saveButton) ? (
            <>
                {saveButton.map((x) => (
                    <LoadingButton
                        key={x.label}
                        variant={x.variant}
                        loading={isLoading}
                        onClick={() => handleSaveButtonClick(x)}
                    >
                        {getSaveButtonLabel(x)}
                    </LoadingButton>
                ))}
            </>
        ) : saveButton ? (
            <LoadingButton loading={isLoading} onClick={() => handleSaveButtonClick(saveButton)}>
                {getSaveButtonLabel(saveButton)}
            </LoadingButton>
        ) : undefined

    const cancelButtonText = 'button-sulje'

    return (
        <StyledModalBase
            actionButtons={extraButtons}
            classNameContentContainer={classNameContentContainer}
            handleClose={handleModalClose}
            modalId={modalId}
            muiDialogProps={{
                onBackdropClick: handleModalClose,
                maxWidth: 'xl',
                fullWidth: true,
            }}
            title={title}
            cancelButtonText={cancelButtonText}
        >
            <ConfigurableListTemplate
                additionalDataSourceDataRequestFiltersParameters={additionalDataSourceDataRequestFiltersParameters}
                initialValuePickersValues={initialValuePickersValues}
                isValuePickerGroupFullWidth={false}
                listId={listId}
                omitFilterIds={hiddenValuePickerIds ?? []}
                onRowSelect={handleListRowSelect}
                valuePickersVisibilityTogglable={true}
                shouldEmptyDataWhenUnmounting={shouldEmptyListDataWhenClosingModal}
                resetValuePickerValues={true}
                summaryLabels={summaryLabels}
                listIsVirtualized
            />
        </StyledModalBase>
    )
}

const mapStateToProps = (state: RootState, { modalId, listId }: IOwnProps): IStateProps => {
    const modalProps = selectModalProps(state, modalId)
    return {
        modalOpen: selectIsModalOpen(state, modalId),
        modalProps,
        selectedItemIds: (modalProps as IclModalProps)?.saveButton
            ? selectSelectedItemIds(state, listId)
            : defaultSelectedItemIds,
    }
}

const mapDispatchToProps = {
    closeModal: closeModalAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurableListModal)
