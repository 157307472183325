import * as React from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'

import { TAllValues, TNewlySelected, TSelectedValues } from '../../Interfaces'
import Styles from 'constants/Styles'
import LoadingIndicatorInline from 'components/molecules/LoadingIndicatorInline'
import { Translation } from '../../../../localization'
import { PADDING } from '../../Constants'
import { getTestId } from '../../../../test/automationTestUtils'

interface IValueFieldProps<T> {
    defaultText: string
    disabled: boolean
    isLoading: boolean
    itemIdField: keyof T
    itemValueLabelFields: (keyof T)[]
    items: T[]
    onClick: (e: React.MouseEvent) => void
    selectedNewItems: TNewlySelected<T>
    selectedValueItems: TSelectedValues<T>
    textForMultipleSelected: string
    translate: boolean
    values: TAllValues<T>
    valueTextOverride?: string
    typeName: undefined | string
    multiselect: boolean
    valueItems: ReadonlyMap<T[keyof T], T>
}

const Container = styled.button`
    border-bottom: 1px solid ${Styles.supplementaryColor.concreteGrey};
    display: flex;
    width: 100%;
    padding-left: ${PADDING}px;
    padding-right: ${PADDING}px;
    cursor: ${({ disabled }: { disabled: boolean }) => (disabled ? 'not-allowed' : 'pointer')};
    text-align: start;
`

const Arrow = styled.span`
    border-color: ${Styles.supplementaryColor.opaqueDarkGrey} transparent transparent;
    border-style: solid;
    border-width: 5px 5px 2.5px;
    height: 0;
    width: 0;
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    text-align: center;
    margin: auto 2px;
`

interface IValueText {
    value: string
    isDefaultTextShown?: boolean
}

interface IValueTextProps extends React.ComponentProps<typeof Typography>, Pick<IValueText, 'isDefaultTextShown'> {}

const ValueText = styled<React.FC<IValueTextProps>>(({ isDefaultTextShown, ...props }) => <Typography {...props} />)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    margin: 0 0 2px;

    ${({ isDefaultTextShown }) =>
        !isDefaultTextShown &&
        `
        color: ${Styles.mainColor.black};
    `}
`

// ensure the loading indicator doesn't push other elements down
const StyledLoadingIndicatorInline = styled(LoadingIndicatorInline)`
    height: 0;
`

export default class ValueFieldDefault<T> extends React.Component<IValueFieldProps<T>> {
    private getValue(): IValueText {
        const {
            defaultText,
            itemIdField,
            itemValueLabelFields,
            items,
            multiselect,
            selectedNewItems,
            selectedValueItems,
            textForMultipleSelected,
            translate,
            typeName,
            valueItems,
            valueTextOverride,
        } = this.props

        if (valueTextOverride) {
            return {
                value: translate ? Translation.translateKey(valueTextOverride) : valueTextOverride,
                isDefaultTextShown: true,
            }
        }

        const totalCount = selectedNewItems.size + selectedValueItems.size

        if (totalCount === 0) {
            return { value: translate ? Translation.translateKey(defaultText) : defaultText, isDefaultTextShown: true }
        }

        if (totalCount === 1) {
            const selectedValueItemId = selectedValueItems.values().next().value
            const onlyItemId =
                selectedValueItemId !== null && selectedValueItemId !== undefined
                    ? selectedValueItemId
                    : selectedNewItems.values().next().value

            // value items contain only items in the values props, not the currently selected values contains in the state
            // so if valueItems doesn't contain the currently selected item, it's still very possible to find it from the item list
            const item = valueItems.get(onlyItemId) || items.find((listItem) => listItem[itemIdField] === onlyItemId)

            // there might be situations in which we don't have the item that is currently selected
            if (item) {
                return { value: itemValueLabelFields.map((labelField) => item[labelField]).join(' ') }
            } else {
                return {
                    value: translate ? Translation.translateKey(defaultText) : defaultText,
                    isDefaultTextShown: true,
                }
            }
        }

        if (!multiselect && typeName && totalCount > 1) {
            const translatedTypeName = Translation.translateKey(typeName)

            return {
                value: Translation.translateKey('Dropdown.Value.MultipleValuesWithTypeName', { translatedTypeName }),
            }
        }

        const multipleSelectedText = translate
            ? Translation.translateKey(textForMultipleSelected)
            : textForMultipleSelected
        return { value: `${totalCount} ${Translation.translateKey('valittua-text')} ${multipleSelectedText}` }
    }

    render(): React.ReactNode {
        const { disabled, isLoading, onClick } = this.props
        const { value, isDefaultTextShown = false } = this.getValue()

        return (
            <Container
                data-testid={getTestId(['VALUE_PICKER', 'DROPDOWN'], this.props.defaultText)}
                disabled={disabled}
                onClick={onClick}
            >
                <ValueText isDefaultTextShown={isDefaultTextShown} variant="subtitle1">
                    {value}
                </ValueText>
                {isLoading ? <StyledLoadingIndicatorInline isLoading size={16} /> : <Arrow />}
            </Container>
        )
    }
}
